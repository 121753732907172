<template>
    <div class="kzevb-prices">

        <div style="margin-top: 16px">
            <div class="success-box"
                 v-if="successBox.show"
                 v-html="successBox.text">
            </div>
            <div class="error-box"
                 v-if="errorBox.show"
                 v-html="errorBox.text">
            </div>
        </div>

        <table>
            <tr>
                <td>Current provider</td>
                <td>
                  <select v-model="currentProvider">
                      <option value="fake">Fake</option>
                      <option value="vbs_berlin">VBS Berlin</option>
                  </select>
                </td>
            </tr>
        </table>

        <div>
            <button @click="save">Save</button>
        </div>
    </div>
</template>

<script>

import auth_required from "@/mixins/auth_required";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import success_error_states from "@/mixins/success_error_states";
import {handle} from "@/decorators";

export default {
    name: "prices",
    mixins: [auth_required, success_error_states],
    data: () => ({
        currentProvider: null,
    }),
    computed: {
    },
    methods: {
        @handle("Current provider successful updated")
        async save() {
            await this.$services.kzevb.setCurrentProvider(this.currentProvider)
        }
    },
    async mounted() {
        this.currentProvider = await this.$services.kzevb.getCurrentProvider();
        this.currentProvider = this.currentProvider.provider_name;
    },
}

</script>

<style lang="stylus" scoped>
    .kzevb-prices
        padding 10px

        &__result
            margin-top 15px

    button
        height 34px
        width 60px
        font-weight bold
</style>
