<template>
  <div class="info-text">

    <div class="info-text__title">Text info</div>

    <div style="margin-top: 16px">
      <div class="success-box"
           v-if="successBox.show"
           v-html="successBox.text">
      </div>
      <div class="error-box"
           v-if="errorBox.show"
           v-html="errorBox.text">
      </div>
    </div>

    <div class="info-text__row">
      Select a language
      <select v-model="lang" class="info-text__select">
        <option value="de">Deutsch</option>
        <option value="en">Englisch</option>
        <option value="pl">Polnisch</option>
      </select>
      <VueEditor v-if="lang === 'de'" v-model="kkv_info_de" :editor-toolbar="toolbar"></VueEditor>
      <VueEditor v-else-if="lang === 'en'" v-model="kkv_info_en" :editor-toolbar="toolbar"></VueEditor>
      <VueEditor v-else-if="lang === 'pl'" v-model="kkv_info_pl" :editor-toolbar="toolbar"></VueEditor>
      <button v-if="lang === 'de'" @click.prevent="updateInfo('kkv_info_de')" class="info-text__button">Save Deutsch</button>
      <button v-else-if="lang === 'en'" @click.prevent="updateInfo('kkv_info_en')" class="info-text__button">Save Englisch</button>
      <button v-else-if="lang === 'pl'" @click.prevent="updateInfo('kkv_info_pl')" class="info-text__button">Save Polnisch</button>
    </div>

  </div>
</template>

<script>
import {VueEditor} from "vue2-editor";
import {handle} from "@/decorators";
import auth_required from "@/mixins/auth_required";
import success_error_states from "@/mixins/success_error_states";

export default {
  name: "info",
  mixins: [auth_required, success_error_states],
  components: {VueEditor},
  data() {
    return {
      lang: 'de',
      kkv_info_de: '',
      kkv_info_en: '',
      kkv_info_pl: '',
      id: null,
      toolbar: [
        [{header: [false, 1, 2, 3, 4, 5, 6]}],
        ["bold", "italic", "underline", "strike"],
        [{align: ""}, {align: "center"}, {align: "right"}, {align: "justify"}],
        ["blockquote", "code-block"],
        [{list: "ordered"}, {list: "bullet"}, {list: "check"}],
        [{indent: "-1"}, {indent: "+1"}],
        [{color: []}, {background: []}],
        ["link"], ["clean"]
      ]
    }
  },
  methods: {
    @handle('Info successfully updated')
    async updateInfo(text) {
      await this.$services.kkv.updateKkvInfo({id: this.id, [text]: this[text]})
    },
    async getInfo() {
      const response = await this.$services.kkv.getKkvInfo()
      this.kkv_info_en = response.kkv_info_en
      this.kkv_info_de = response.kkv_info_de
      this.kkv_info_pl = response.kkv_info_pl
      this.id = response.id
    }
  },
  async mounted() {
    await this.getInfo()
  }

}

</script>

<style lang="stylus">
.info-text
  padding: 10px

  &__title
    background #aaaaaa
    color white
    font-weight: 700
    padding: 2px
    margin-bottom: 30px

  &__row
    max-width 1200px
    margin: 0 auto
  &__button
    font-size: 20px
    margin-top: 12px
    padding: 0 8px
    cursor: pointer
  &__select
    margin-bottom: 32px
    margin-left: 16px

</style>
