<template>
    <tr>
        <td>{{ accountName }}</td>
        <td>{{ fullName }}</td>
        <td>{{ status }}</td>
        <td>
            <input type="password" v-model="new_password" />
            <button @click="newPasswordClick">OK</button>
        </td>
        <td>
            <button @click="activateClick" v-if="status === 0">Aktivieren</button>
            <button @click="deactivateClick" v-else>Deaktivieren</button>
            <button @click="remove">Löschen</button>
        </td>
    </tr>
</template>

<script>
export default {
    name: "user-data-row",
    data: () => ({
        new_password: '',
    }),
    props: {
        accountName: String,
        fullName: String,
        status: Number
    },
    methods: {
        newPasswordClick() {
            this.$emit('new-password', {
                new_password: this.new_password,
                username: this.accountName,
            })
            this.new_password = '';
        },
        activateClick() {
            this.$emit('activate', {
                username: this.accountName,
            })
        },
        deactivateClick() {
            this.$emit('deactivate', {
                username: this.accountName,
            })
        },
        remove() {
            this.$emit('remove', {
                username: this.accountName,
            })
        },
    },
}
</script>

<style lang="stylus" scoped>
    tr:nth-child(odd)
        background-color #fafafa
    tr:nth-child(even)
        background-color #e1e1e1
</style>
