<template>
    <main-layout>
        <div class="kkv">
            <div class="kkv__menu">
                <router-link to="/kkv/orders/" active-class="a-active">Orders</router-link>
                <router-link to="/kkv/prices/" active-class="a-active">Prices</router-link>
                <router-link to="/kkv/resellers/" active-class="a-active">Resellers</router-link>
              <router-link to="/kkv/promo_codes/" active-class="a-active">Gutscheincode</router-link>
                <router-link to="/kkv/emails_templates/" active-class="a-active">Emails templates</router-link>
                <router-link to="/kkv/content_texts/" active-class="a-active">Content texts</router-link>
                <router-link to="/kkv/disabled_dates/" active-class="a-active">Inactive dates</router-link>
                <router-link to="/kkv/csv/" active-class="a-active">CSV</router-link>
                <router-link to="/kkv/info/" active-class="a-active">Info</router-link>
            </div>
            <div class="kkv__body">
                <router-view></router-view>
            </div>
        </div>
    </main-layout>

</template>

<script>
import MainLayout from "./main-layout";

export default {
  name: "kkv-layout",
  components: { MainLayout, },
}

</script>

<style lang="stylus">
    .kkv
        &__menu
            background-color #F1932A
            padding 3px 0 2px 0
            a
                color #fefefe
                margin 0 10px 0 5px
                font-size 1.1em
</style>
