<template>
    <div class="disabled-dates">
        <div class="success-box"
             v-if="successBox.show"
             v-html="successBox.text">
        </div>
        <div class="error-box"
             v-if="errorBox.show"
             v-html="errorBox.text">
        </div>
        <calendar :disabledWeekDays="[6]" :disabledDates="datesArray" v-if="days" @weekSubmit="weekSubmit">
            <template v-slot:week-popup="{ wIndex }">
                <label>
                    Disabled:
                    <input type="checkbox" name="disabled" :checked="wIndex === 6" disabled>
                </label>
                <label>
                    Additional price:
                    <input type="number" name="additional_price" :value="daysTable[wIndex].additional_price">
                </label>
                <label>
                    Working dates:
                    <input type="number" name="difference" :value="daysTable[wIndex].difference">
                </label>
            </template>

            <template v-slot:day="{ date }">
                <button v-if="isDisabled(date)" @click="onEnableDate(date)">Enable</button>
                <button v-else @click="onDisableDate(date)">Disable</button>
            </template>
        </calendar>
    </div>
</template>

<script>
import {handle} from "../../decorators";
import auth_required from "../../mixins/auth_required";
import success_error_states from "../../mixins/success_error_states";
import calendar from "../../components/calendar.vue";


const DAYS_NAMES = {
    MONDAY: 'Monday',
    TUESDAY: 'Tuesday',
    WEDNESDAY: 'Wednesday',
    THURSDAY: 'Thursday',
    FRIDAY: 'Friday',
    SATURDAY: 'Saturday',
    SUNDAY: 'Sunday',
    DAYS_WITH_UP: 'High price days',
};

export default {
    name: "disabled_dates",
    mixins: [auth_required, success_error_states],
    components: { calendar },
    data: () => ({
        dates: {},
        days: null,
        newText: '',
    }),
    computed: {
        daysTable() {
            const result = [];
            for (const dayName in DAYS_NAMES) {
                result.push({
                    key: dayName,
                    name: DAYS_NAMES[dayName],
                    difference: this.days[dayName][0],
                    additional_price: this.days[dayName][1],
                })
            }
            return result;
        },
        datesArray() {
            const result = [];
            for (const dKey in this.dates) {
                result.push(this.dates[dKey]);
            }
            return result;
        },
    },
    methods: {
        @handle('Date disabled successfully')
        async onDisableDate(date) {
            const id = await this.$services.kkv.addDisabledDate(date);
            this.dates[id] = date;
            this.dates = await this.$services.kkv.getDisabledDates();
            await this.$nextTick()
        },
        @handle('Date disabled successfully')
        async onEnableDate(date) {
            for (let id in this.dates) {
                if (this.dates[id] === date) await this.deleteDate(id);
            }
            this.dates = await this.$services.kkv.getDisabledDates();
        },
        isDisabled(dateString) {
            for (let key in this.dates) {
                const disabledDateString = this.dates[key];
                if (disabledDateString === dateString) return true;
            }
            return false;
        },
        @handle('Date successfully added')
        async add() {
            const id = await this.$services.kkv.addDisabledDate(this.newText);
            this.dates[id] = this.newText;
            this.newText = '';
            await this.$nextTick();
        },
        @handle('Date successfully deleted')
        async deleteDate(id) {
            await this.$services.kkv.deleteDisabledDate(id);
            this.dates = await this.$services.kkv.getDisabledDates();
        },

        @handle('Success')
        async saveDay(key, value) {
            console.log('SAVE DAY', key, value)
            await this.$services.kkv.setDay(key, value[0], value[1]);
        },

        @handle('Week updated')
        async weekSubmit(data) {
            console.log('week submit', this.daysTable[data.week]);
            await this.saveDay(this.daysTable[data.week].key, [data.difference, data.additional_price]);
            this.days = await this.$services.kkv.getDays();
        },
    },
    async mounted() {
        this.dates = await this.$services.kkv.getDisabledDates();
        this.days = await this.$services.kkv.getDays();
    },
}
</script>

<style scoped>

</style>
