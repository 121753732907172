import {GetErrorMessage} from "@/errors";

export default {
    data: () => ({
        successBox: {
            show: false,
            text: '',
        },
        errorBox: {
            show: false,
            text: '',
        },
    }),
    methods: {
        resetBoxes() {
            this.successBox.show = false;
            this.errorBox.show = false;
        },
        handleSuccess(message) {
            this.successBox.text = message;
            this.successBox.show = true;
        },
        handleError(err) {
            this.errorBox.text = GetErrorMessage(err) || err;
            this.errorBox.show = true;
        },
    },
}
