


export function handle(messageName) {
    return function (target, property, descriptor) {
        const originalMethod = descriptor.value;

        descriptor.value = async function(...args) {
            this.resetBoxes();
            try {
                const result = await originalMethod.call(this, ...args);
                const successMessage = messageName.format(result);
                this.handleSuccess(successMessage);
                return result;
            } catch (err) {
                this.handleError(err);
            }
        };

        return descriptor;
    }
}
