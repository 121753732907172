<template>
    <div class="akv-plates-count">
        <date-picker v-model="month" type="month"
                     @change="getPlatesForMonth"
                     :disabled-date="beforeToday"
                     placeholder="Select month" />

        <button @click="getPlatesForMonth">Ok</button>

        <div class="akv-plates-count__result" v-if="result !== null">
            <b>{{ result }} plates</b> for {{ monthName }}, {{ year }}
        </div>
    </div>
</template>

<script>

import auth_required from "@/mixins/auth_required";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
    name: "plates_count",
    mixins: [auth_required],
    data: () => ({
        /** @type {Date} */ month: null,
        result: null,
    }),
    computed: {
        monthName() {
            if (!this.month) return '';
            return {
                0: 'January',
                1: 'February',
                2: 'March',
                3: 'April',
                4: 'May',
                5: 'June',
                6: 'July',
                7: 'August',
                8: 'September',
                9: 'October',
                10: 'November',
                11: 'December'
            }[this.month.getMonth()]
        },
        year() {
            if (!this.month) return '';
            return this.month.getFullYear();
        }
    },
    methods: {
        async getPlatesForMonth() {
            if (!this.month) return;

            console.log(this.month);

            const dateString = `${this.month.getFullYear()}-${this.month.getMonth() + 1}-${this.month.getDate()}`;
            console.log(dateString);

            this.result = await this.$services.akv.getPlatesForMonth(dateString);

            console.log(this.result);
        },
        beforeToday(date) {
            return date > new Date(new Date().setHours(0, 0, 0, 0));
        },
    },
    components: { DatePicker },
}

</script>

<style lang="stylus" scoped>
    .akv-plates-count
        padding 10px

        &__result
            margin-top 15px

    button
        margin-left 10px
        height 34px
        width 60px
        font-weight bold
</style>
