

export const ERROR_CODES = {
    SERVER_CONNECTION: 'SERVER_CONNECTION',
    UNKNOWN_ERROR: 'UNKNOWN_ERROR',

    INVALID_DATA: 'INVALID_DATA',
    BAD_CREDENTIALS: 'BAD_CREDENTIALS',
    DOES_NOT_EXISTS: 'DOES_NOT_EXISTS',
    USER_WITH_THIS_USERNAME_ALREADY_EXISTS: 'USER_WITH_THIS_USERNAME_ALREADY_EXISTS',
    TOKEN_EXPIRED: 'TOKEN_EXPIRED',
    BAD_TOKEN: 'BAD_TOKEN',
    NOT_AUTHENTICATED: 'NOT_AUTHENTICATED',
};


export const ERROR_MESSAGES = {
    SERVER_CONNECTION: 'Serververbindungsfehler',
    UNKNOWN_ERROR: 'Unbekannter Fehler',

    INVALID_DATA: 'Ungültige Daten eingegeben',
    BAD_CREDENTIALS: 'Falsche Kontodaten eingegeben',
    DOES_NOT_EXISTS: 'Dieses Objekt existiert nicht',
    USER_WITH_THIS_USERNAME_ALREADY_EXISTS: 'Benutzer mit dieser Benutzerkennung existiert bereits',
    TOKEN_EXPIRED: 'Autorisierungstoken abgelaufen',
    BAD_TOKEN: 'Ungültiges Autorisierungstoken',
    NOT_AUTHENTICATED: 'Sie sind nicht berechtigt',
};


export function GetErrorMessage(error) {
    if (error instanceof LogicError)
        return ERROR_MESSAGES[error.status_code];
    else
        return error.message;
}


export class LogicError extends Error {
    constructor(status_code, data = null) {
        super(`Logic Error with status code: ${status_code}`);
        this.status_code = status_code;
        this.data = data;
    }
}
