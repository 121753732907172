<template>
    <tr>
        <td>
            {{ short_name }}
        </td>
        <td>
            <input type="text" v-model="long_name">
        </td>
        <td>
            <input type="text" v-model="bg_color"
                   :style="`max-width: 60px; background-color: ${bg_color};`">
        </td>
        <td>
            <input type="text" v-model="color"
                   :style="`max-width: 60px; background-color: ${color};`">
        </td>
        <td>
            <input type="text" v-model="sort_weight">
        </td>
        <td>
            <button>Modul speichern</button>
        </td>
    </tr>
</template>

<script>
export default {
    name: "module-data-row",
    props: {
        short_name: String,
        long_name: String,
        bg_color: String,
        color: String,
        sort_weight: Number,
    }
}
</script>

<style lang="stylus" scoped>

</style>
