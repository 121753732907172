<template>
    <tr>
        <td>
            <input type="text" placeholder="Benutzername" v-model="username"/>
        </td>
        <td>
            <input type="text" placeholder="Voller Name" v-model="full_name"/>
        </td>
        <td></td>
        <td>
            <input type="password" placeholder="Passwort" v-model="password"/>
        </td>
        <td>
            <button @click="onCreateClick">Benutzer hinzufügen</button>
        </td>
    </tr>
</template>

<script>
export default {
    name: "user-data-add-line",
    data: () => ({
        username: '',
        full_name: '',
        password: '',
    }),
    methods: {
        onCreateClick() {
            this.$emit('create-user', {
                username: this.username,
                full_name: this.full_name,
                password: this.password,
            })

            this.username = '';
            this.full_name = '';
            this.password = '';

        }
    }
}
</script>

<style lang="stylus" scoped>
    tr:nth-child(odd)
        background-color #fafafa
    tr:nth-child(even)
        background-color #e1e1e1
</style>
