<template>
  <div class="kkv-orders">
    <div v-if="isModalOrders" class="kkv-orders__modal">
      <div class="kkv-orders__modal-mask"></div>
      <div class="kkv-orders__modal-content">
        Ich kann keine Bestellung aufgeben, weil der Manager, <span>{{ userLockOrder }}</span>, noch daran arbeitet.
        <p>Nur <span>{{ userLockOrder }}</span> kann sich anmelden</p>
        <button @click="isModalOrders = false">OK</button>
      </div>
    </div>
    <div class="kkv-orders__filter-row">
      <div>
        <input type="text"
               placeholder="Email/phone/company/person name..."
               v-model="searchInputText"
               style="margin-right: 8px">
        <button @click="getOrders">OK</button>
      </div>

      <div>
        Filter:
        <select v-model="filter">
          <option value="payment_received">Zahlung erhalten</option>
          <option value="all">Alle</option>
          <option value="all_without_waiting_for_payment">Alle ohne Warte auf Zahlung</option>
          <option value="waiting_for_payment">Warte auf Zahlung</option>
          <option value="waiting_for_issue">Warte auf Anmeldung</option>
          <option value="canceled">Storniert</option>
          <option value="in_processing">In Bearbeitung</option>
          <option value="issued">Angemeldet</option>
          <option value="sent">Versendet</option>
          <option value="request_sent">Ruckfragen</option>
        </select>
      </div>

      <div style="display: flex; align-items: center">
        <div>
          Limit:
          <select @change="setLimit($event.target.value)" :value="limit">
            <option value="30">30</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div>

        <div class="pagination">
          <div v-for="index in getPages()">
            <a class="content" v-if="currentPage !== index" href="#" @click="setPage(index)">{{ index }}</a>
            <a style="font-weight: bold; color: #f1932a;" class="content" v-else>{{ index }}</a>
          </div>
        </div>
      </div>
    </div>

    <loader v-if="loading" style="margin: auto"/>

    <table v-if="!loading" cellspacing="0" cellpadding="0">
      <thead>
      <tr>
        <th>
          BID
          <a @click.prevent="sortDataTable('id')" href=""><img src="@/assets/images/sort_icon.png" alt=""></a>
        </th>
        <th>
          Datum
          <a @click.prevent="sortDataTable('date_of_create')" href=""><img src="@/assets/images/sort_icon.png" alt=""></a>
        </th>
        <th>Kennzeichen</th>
        <th>eVB-Nr.</th>
        <th>Halter</th>
        <th>
          Liefertermin
          <a @click.prevent="sortDataTable('start_date')" href=""><img src="@/assets/images/sort_icon.png" alt=""></a>
        </th>
        <th>Gesamtpreis</th>
        <th>
          Zahlungsweise
        </th>
        <th>Status</th>
        <th>Gutscheincode</th>
      </tr>
      </thead>
      <tbody>
      <tr :class="{'kkv-orders__active-user': order.user_name !== null}" v-for="(order, orderKey) in orders"
          @click="onRowClick(order.id, order.user_name)"
          :key="`order${orderKey}Row`">
        <td>
          <b>
            {{ order.id }}
            <span v-if="order.reseller_id"> -- ({{ order.reseller_id }})</span>
          </b>
        </td>
        <td>{{ prettifyDate(order.date_of_create) }}</td>
        <td>{{ order.kennzeichen }}</td>
        <td>{{ order.evb_number }}</td>
        <td>{{ order.person_name }} {{ order.person_surname }} {{ order.person_company_name }}</td>
        <td>{{ prettifyDate(order.start_date) }}</td>
        <td>{{ order.summary_price || 155.00 }} EUR</td>
        <td>
          <span v-if="order.payment_method">{{ PAYMENT_METHODS[order.payment_method] }}</span>
        </td>
        <td :style="`background-color: ${tdColor(order.status)}`">{{ ORDER_STATUSES[order.status] }}</td>
        <td>{{ order.promo_code }}</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import auth_required from "@/mixins/auth_required";
import success_error_states from "@/mixins/success_error_states";
import loader from "@/components/loader";
import Datepicker from 'vue2-datepicker'
import centrifuge from "@/mixins/centrifugo";

const ORDER_STATUSES = {
  WAITING_FOR_PAYMENT: 'Warte auf Zahlung',
  PAYMENT_RECEIVED: 'Zahlung erhalten',
  IN_PROCESSING: 'In Bearbeitung',
  ISSUED: 'Angemeldet',
  SENT: 'Versendet',
  REQUEST_SENT: 'Ruckfragen',
  WAITING_FOR_ISSUE: 'Warte auf Anmeldung',
  CANCELED: 'Storniert',
}

export default {
  name: "orders",
  mixins: [auth_required, success_error_states, centrifuge],
  data: () => ({
    loading: false,
    ORDER_STATUSES,
    orders: [],
    searchDateFrom: null,
    searchDateTo: null,
    count: 0,
    interval: null,
    isModalOrders: false,
    idLockOrder: '',
    userLockOrder: '',
    PAYMENT_METHODS: {
      'sparkassen': 'Stripe',
      'paypal': 'PayPal',
      'sofort': 'Sofort',
    },
    sortingDirection: false,
  }),
  computed: {
    pagesCount() {
      return Math.ceil(this.count / this.limit);
    },
    currentPage() {
      return (this.offset / this.limit) + 1;
    },
    filter: {
      get() {
        return this.$route.query.filter || 'payment_received'
      },
      set(filter) {
        this.$router.push({query: {...this.$route.query, filter: filter}})
      }
    },
    offset: {
      get() {
        return this.$route.query.offset || 0
      },
      set(offset) {
        this.$router.push({query: {...this.$route.query, offset: offset}})
      }
    },
    limit: {
      get() {
        return this.$route.query.limit || 50
      },
      set(limit) {
        this.$router.push({query: {...this.$route.query, limit: limit}})
      }
    },
    searchInputText: {
      get() {
        return this.$route.query.searchInputText || ''
      },
      set(searchInputText) {
        this.$router.push({
          query: {
            ...this.$route.query,
            searchInputText: searchInputText
          }
        })
      }
    },
    sortValue: {
      get() {
        return this.$route.query.sortValue || ''
      },
      set(sort) {
        this.$router.push({
          query: {
            ...this.$route.query,
            sortValue: sort
          }
        })
      }
    }
  },
  watch: {
    filter: async function () {
      this.limit = 50;
      this.offset = 0;
      await this.getOrders()
    },
    offset: async function () {
      await this.getOrders()
    },
    sort: async function () {
      await this.getOrders()
    },
    limit: async function () {
      await this.getOrders()
    },
    searchInputText: async function () {
      await this.getOrders()
    }
  },
  methods: {
    async sortDataTable(column) {
      this.sortValue
      if (this.sortingDirection) this.sortValue = `-${column}`
      else this.sortValue = column
      const ordersData = await this.$services.kkv.getOrderList(this.limit, this.offset, this.searchInputText, this.filter, this.sortValue);
      this.orders = ordersData.data
      this.sortingDirection = !this.sortingDirection
    },
    async getOrders() {
      let ordersData
      if (this.sortValue !== '') {
        ordersData = await this.$services.kkv.getOrderList(this.limit, this.offset, this.searchInputText, this.filter, this.sortValue);
      } else {
        ordersData = await this.$services.kkv.getOrderList(this.limit, this.offset, this.searchInputText, this.filter);
      }
      this.count = ordersData.count;
      this.orders = ordersData.data;
      return this.orders
    },
    getPages() {
      let first_page = this.currentPage - 5 > 1 ? this.currentPage - 5 : 1;
      let last_page = this.currentPage + 5 < this.pagesCount ? this.currentPage + 5 : this.pagesCount;
      const range = (start, stop, step = 1) =>
        Array(Math.ceil((stop + 1 - start) / step)).fill(start).map((x, y) => x + y * step)
      return range(first_page, last_page, 1)
    },
    async setPage(page) {
      this.offset = (page - 1) * this.limit;
      await this.getOrders();
    },
    async setLimit(limit) {
      this.limit = limit;
      await this.getOrders();
    },
    prettifyDate(date) {
      return (new Date(date)).toLocaleString();
    },
    tdColor(val) {
      if (val === 'WAITING_FOR_PAYMENT') return '#DFFFD8';
      if (val === 'PAYMENT_RECEIVED') return '#FFF';
      if (val === 'IN_PROCESSING') return '#F5EA5A';
      if (val === 'ISSUED') return '#BAD7E9';
      if (val === 'SENT') return '#00CE51';
      if (val === 'REQUEST_SENT') return '#3A98B9';
      if (val === 'WAITING_FOR_ISSUE') return '#FFB100';
      if (val === 'CANCELED') return '#F55050';
      else {
        return '##9A6735';
      }
    },
    async onRowClick(id, order) {
      if (order === null || order === this.$store.state.userData.username) {
        this.$router.push({
          name: 'kkv_order',
          params: {id}
        })
      } else {
        this.isModalOrders = true
        this.idLockOrder = id
        this.userLockOrder = order
      }
    }
  },
  async created() {
    await this.getOrders();
    this.centrifuge.subscribe('kkv_delete_order', (message) => {
      const id = message.data.order_id
      for (let order in this.orders) {
        if (this.orders[order].id === id) {
          this.orders.splice(order, 1)
        }
      }
    })

    this.centrifuge.subscribe('kkv_lock_order', async (message) => {
      const id = message.data.id
      const user = message.data.user_name
      for (let order in this.orders) {
        if (this.orders[order].id === id) {
          this.orders[order].user_name = user
          console.log(message.data)
        }
      }
    })

    this.centrifuge.subscribe('update_orders', (message) => {
      const orderUpdate = message.data
      for (let order in this.orders) {
        const oldOrder = this.orders[order]
        if (oldOrder.id === orderUpdate.id) {
          for (let key in oldOrder) {
            if (orderUpdate.hasOwnProperty(key)) {
              oldOrder[key] = orderUpdate[key]
            }
          }
        }
      }
    })

    this.centrifuge.subscribe('kkv_create_order', (message) => {
      const order = message.data
      console.log(order)
      this.orders.unshift(order)
    })

  },
  async destroyed() {
  },
  components: {
    loader, Datepicker
  },
}
</script>

<style lang="stylus" scoped>
.kkv-orders
  padding 0 10px

  &__modal
    &-mask
      position fixed
      top: 0
      left: 0
      bottom: 0
      right: 0
      background rgba(black, 0.2)
      z-index 10

    &-content
      z-index 12
      position fixed
      display flex
      flex-direction column
      align-items center
      text-align center
      padding: 32px
      font-weight: 400
      font-size: 24px
      line-height 1.3
      background #eab7b7
      border 1px solid red
      top: 50%
      left: 50%
      transform translate(-50%, -50%)

      span
        font-weight: 700
        display contents
        text-decoration underline

      p
        margin: 0
        margin-top: 16px

    button
      margin-top: 16px
      padding: 2px 10px

  &__active-user
    background-color rgba(red, 0.1)

  &__filter-row
    background linear-gradient(top, #fff, #adf)
    background -moz-linear-gradient(top, #fff, #adf)
    background -webkit-gradient(linear, left top, left bottom, from(#fff), to(#adf))
    background -o-linear-gradient(top, #fff, #adf)
    background -ms-linear-gradient(top, #fff, #adf)
    background -webkit-linear-gradient(top, #fff, #adf)
    display flex
    margin-top 10px
    padding 4px 15px
    align-items center
    font-size 0.8em
    justify-content space-between

  table
    width 100%
    margin-top 10px
    font-size 12px

    thead
      background-color #FFFCAB

    th, td
      text-align left
      padding 5px

    th
      a
        vertical-align middle
        margin-left: 8px
        display inline-block

      img
        width: 15px

    tbody
      tr:hover
        background-color #FFFCAB
        cursor pointer

  .pagination
    display flex
    font-size 14px
    margin 0

    & > div
      margin-left 5px

</style>


<style lang="stylus">
.mx-input
  padding 8px !important
  height 24px !important

.mx-datepicker
  width 115px !important

a:active {
  background-color: yellow;
}
</style>
