import Vue from 'vue'
import VueRouter from 'vue-router'

import Login from '@/pages/login.vue'
import Home from '@/pages/home.vue'

import AdminLayout from '@/layouts/admin-layout'
import AdminUsers from '@/pages/admin/users'
import AdminRights from '@/pages/admin/rights'
import AdminModules from '@/pages/admin/modules'

import AkvLayout from '@/layouts/akv-layout'
import AkvOrders from '@/pages/akv/orders'
import AkvOrder from '@/pages/akv/order'
import AkvPlatesCount from '@/pages/akv/plates_count'
import AkvTemplates from '@/pages/akv/templates'
import AkvPrices from '@/pages/akv/prices'
import AkvProductsNumbers from '@/pages/akv/products_numbers'
import AkvSiteContent from '@/pages/akv/site-content'
import AkvCsv from '@/pages/akv/csv'

import KzevbLayout from '@/layouts/kzevb-layout'
import KzevbOrders from '@/pages/kzevb/orders'
import KzevbOrder from '@/pages/kzevb/order'
import KzevbTemplates from '@/pages/kzevb/templates'
import KzevbPrices from '@/pages/kzevb/prices'
import KzevbEvbSettings from '@/pages/kzevb/evb_settings'
import KzevbSiteContent from '@/pages/kzevb/site-content'
import KzevbResellerStatistics from '@/pages/kzevb/reseller_statistics'
import KzevbLogs from '@/pages/kzevb/logs'
import KzevbAds from '@/pages/kzevb/ads'
import KzevbUsers from '@/pages/kzevb/users'
import KzevbResellers from '@/pages/kzevb/resellers'
import KzevbCsv from '@/pages/kzevb/csv'


import KkvLayout from '@/layouts/kkv-layout'
import KkvOrders from '@/pages/kkv/orders'
import KkvOrder from '@/pages/kkv/order'
import KkvPrices from '@/pages/kkv/prices'
import KkvPromoCodes from '@/pages/kkv/promo_codes'
import KkvTemplates from '@/pages/kkv/templates'
import KkvDisabledDates from '@/pages/kkv/disabled_dates'
import KkvResellers from '@/pages/kkv/resellers'
import KkvCsv from '@/pages/kkv/csv'
import KkvInfo from '@/pages/kkv/info-text'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'login',
        component: Login
    },
    {
        path: '/home/',
        name: 'home',
        component: Home
    },
    {
        path: '/admin/',
        name: 'admin',
        component: AdminLayout,
        children: [
            { path: '', component: AdminUsers },
            { path: 'users', name: 'users', component: AdminUsers },
            { path: 'rights', name: 'rights', component: AdminRights },
            { path: 'modules', name: 'modules', component: AdminModules },
        ]
    },
    {
        path: '/akv/',
        name: 'akv',
        component: AkvLayout,
        children: [
            { path: '', component: AkvOrders },
            { path: 'orders', name: 'orders', component: AkvOrders },
            { path: 'orders/:id', name: 'order', component: AkvOrder },
            { path: 'plates_count', name: 'plates_count', component: AkvPlatesCount },
            { path: 'templates', name: 'templates', component: AkvTemplates },
            { path: 'prices', name: 'prices', component: AkvPrices },
            { path: 'products_numbers', name: 'products_numbers', component: AkvProductsNumbers },
            { path: 'site_content', name: 'site_content', component: AkvSiteContent },
            { path: 'csv', name: 'csv', component: AkvCsv },
        ]
    },
    {
        path: '/kzevb/',
        name: 'kzevb',
        component: KzevbLayout,
        children: [
            { path: '', component: KzevbOrders },
            { path: 'orders', name: 'kzevb_orders', component: KzevbOrders },
            { path: 'orders/:id', name: 'kzevb_order', component: KzevbOrder },
            { path: 'templates', name: 'templates', component: KzevbTemplates },
            { path: 'prices', name: 'prices', component: KzevbPrices },
            { path: 'site_content', name: 'site_content', component: KzevbSiteContent },
            { path: 'evb_settings', name: 'current_provider', component: KzevbEvbSettings },
            { path: 'reseller_statistics', name: 'reseller_statistics', component: KzevbResellerStatistics },
            { path: 'logs', name: 'logs', component: KzevbLogs },
            { path: 'ads', name: 'ads', component: KzevbAds },
            { path: 'users', name: 'users', component: KzevbUsers },
            { path: 'resellers', name: 'resellers', component: KzevbResellers },
            { path: 'csv', name: 'kzevb_csv', component: KzevbCsv },
        ],
    },
    {
        path: '/kkv/',
        name: 'kkv',
        component: KkvLayout,
        children: [
            { path: '', component: KkvOrders },
            { path: 'orders', name: 'kkv_orders', component: KkvOrders },
            { path: 'orders/:id', name: 'kkv_order', component: KkvOrder },
            { path: 'prices', name: 'kkv_prices', component: KkvPrices },
            { path: 'emails_templates', name: 'kkv_templates', component: KkvTemplates },
            { path: 'disabled_dates', name: 'kkv_disabled_dates', component: KkvDisabledDates },
            { path: 'resellers', name: 'kkv_resellers', component: KkvResellers },
            { path: 'csv', name: 'kkv_csv', component: KkvCsv },
            { path: 'info', name: 'kkv_info', component: KkvInfo },
            { path: 'promo_codes', name: 'promo_codes', component: KkvPromoCodes},
        ],
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
