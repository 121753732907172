<template>
    <main-layout>
        <div class="admin">
            <div class="admin__menu">
                <router-link to="/admin/users/" active-class="a-active">
                    Benutzer
                </router-link>

                <router-link to="/admin/rights/" active-class="a-active">
                    Rechte
                </router-link>

                <router-link to="/admin/modules/" active-class="a-active">
                    Module
                </router-link>

                <router-link to="/admin/translations/" active-class="a-active">
                    Übersetzungen
                </router-link>
            </div>
            <div class="admin__body">
                <router-view></router-view>
            </div>
        </div>
    </main-layout>
</template>

<script>
import MainLayout from '@/layouts/main-layout'
export default {
    name: "admin-layout",
    components: {MainLayout},
}
</script>

<style lang="stylus">
    .admin
        &__menu
            background-color #FF0000
            padding 3px 0 2px 0
            a
                color #010101
                margin 0 10px 0 5px
                font-size 1.1em
</style>
