<template>
  <main-layout>
    <div class="kzevb">
      <div class="kzevb__menu">
        <router-link to="/kzevb/orders/" active-class="a-active">
          Bestellungen
        </router-link>

        <router-link to="/kzevb/prices/" active-class="a-active">
          Preise
        </router-link>

        <router-link to="/kzevb/reseller_statistics/" active-class="a-active">
          Abr. Reseller
        </router-link>

        <router-link to="/kzevb/logs/" active-class="a-active">
          Log
        </router-link>

        <router-link to="/kzevb/evb_settings/" active-class="a-active">
          evbworker
        </router-link>

        <router-link to="/kzevb/ads/" active-class="a-active">
          Hinweise
        </router-link>

        <router-link to="/kzevb/users/" active-class="a-active">
          Kunden
        </router-link>

        <router-link to="/kzevb/templates/" active-class="a-active">
          Templates
        </router-link>

        <router-link to="/kzevb/site_content/" active-class="a-active">
          Übersetzungen
        </router-link>

        <router-link to="/kzevb/resellers/" active-class="a-active">
          Resellers
        </router-link>

        <router-link to="/kzevb/csv/" active-class="a-active">
          CSV
        </router-link>

      </div>
      <div class="kzevb__body">
        <router-view></router-view>
      </div>
    </div>
  </main-layout>
</template>

<script>
import MainLayout from '@/layouts/main-layout';

export default {
  name: "kzevb-layout",
  components: {MainLayout}
}
</script>

<style lang="stylus">
.kzevb
  &__menu
    background-color #7EB51E
    padding 3px 0 2px 0

    a
      color #fefefe
      margin 0 10px 0 5px
      font-size 1.1em
</style>
