<template>
    <div class="kzevb-prices">

        <div style="margin-top: 16px">
            <div class="success-box"
                 v-if="successBox.show"
                 v-html="successBox.text">
            </div>
            <div class="error-box"
                 v-if="errorBox.show"
                 v-html="errorBox.text">
            </div>
        </div>

        <table>
            <tr>
                <td>Monat</td>
                <td>
                    <select v-model.number="month">
                        <option value="1">Jan</option>
                        <option value="2">Feb</option>
                        <option value="3">Mar</option>
                        <option value="4">April</option>
                        <option value="5">May</option>
                        <option value="6">June</option>
                        <option value="7">July</option>
                        <option value="8">Aug</option>
                        <option value="9">Sep</option>
                        <option value="10">Oct</option>
                        <option value="11">Nov</option>
                        <option value="12">Dec</option>
                    </select>
                </td>
                <td>Year</td>
                <td>
                  <select v-model.number="year">
                      <option value="2020">2020</option>
                      <option value="2021">2021</option>
                      <option value="2022" selected>2022</option>
                      <option value="2023">2023</option>
                  </select>
                </td>
            </tr>
        </table>

        <div class="stats" v-if="statistics">
            <table>
                <thead>
                    <tr>
                        <td v-for="(value, name) in statistics">{{ name }}</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td v-for="(value, name) in statistics">{{ value }}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <pre>{{ statistics }}</pre>

        <div>
            <button @click="get">Load stats</button>
        </div>
    </div>
</template>

<script>

import auth_required from "@/mixins/auth_required";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import success_error_states from "@/mixins/success_error_states";
import {handle} from "@/decorators";

export default {
    name: "reseller_statistics",
    mixins: [auth_required, success_error_states],
    data: () => ({
        reseller: 'agaktiv',
        month: 1,
        year: 2021,
        statistics: null,
    }),
    computed: {
    },
    methods: {
        @handle("Current provider successful updated")
        async get() {
            this.statistics = await this.$services.kzevb.getResellersStats(this.year, this.month)
        }
    },
}

</script>

<style lang="stylus" scoped>
    .kzevb-prices
        padding 10px

        &__result
            margin-top 15px

    button
        height 34px
        width 60px
        font-weight bold
</style>
