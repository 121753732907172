<template>
    <div class="loader">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
             style="margin: auto; background: transparent; display: block; shape-rendering: auto;"
             width="64px" height="64px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
        <g transform="translate(50 50)">
        <g>
        <animateTransform attributeName="transform" type="rotate" values="0;45" keyTimes="0;1"
                          dur="0.47393364928909953s" repeatCount="indefinite">
        </animateTransform>
        <path d="M30.199337741083 -7 L43.199337741083 -7 L43.199337741083 7 L30.199337741083 7 A31 31 0 0 1 26.303903972368456 16.40440903575679 L26.303903972368456 16.40440903575679 L35.49629212779357 25.596797191181906 L25.596797191181906 35.496292127793566 L16.40440903575679 26.303903972368452 A31 31 0 0 1 7.000000000000003 30.199337741083 L7.000000000000003 30.199337741083 L7.0000000000000036 43.199337741083 L-6.999999999999997 43.199337741083 L-6.999999999999998 30.199337741083 A31 31 0 0 1 -16.404409035756792 26.303903972368452 L-16.404409035756792 26.303903972368452 L-25.59679719118191 35.49629212779357 L-35.496292127793566 25.59679719118192 L-26.30390397236845 16.4044090357568 A31 31 0 0 1 -30.199337741083 6.999999999999997 L-30.199337741083 6.999999999999997 L-43.199337741083 6.999999999999999 L-43.199337741083 -6.9999999999999885 L-30.199337741083 -6.99999999999999 A31 31 0 0 1 -26.303903972368452 -16.404409035756792 L-26.303903972368452 -16.404409035756792 L-35.49629212779357 -25.59679719118191 L-25.59679719118192 -35.496292127793566 L-16.4044090357568 -26.30390397236845 A31 31 0 0 1 -6.999999999999999 -30.199337741083 L-6.999999999999999 -30.199337741083 L-7.000000000000002 -43.199337741083 L6.999999999999985 -43.199337741083 L6.999999999999988 -30.199337741083 A31 31 0 0 1 16.40440903575679 -26.303903972368456 L16.40440903575679 -26.303903972368456 L25.596797191181906 -35.49629212779358 L35.496292127793566 -25.59679719118192 L26.30390397236845 -16.4044090357568 A31 31 0 0 1 30.199337741083 -7.000000000000001 M0 -19A19 19 0 1 0 0 19 A19 19 0 1 0 0 -19" fill="#004494"></path></g></g>
        <!-- [ldio] generated by https://loading.io/ --></svg>
    </div>
</template>

<script>
export default {
    name: "loader"
}
</script>

<style scoped>

</style>
