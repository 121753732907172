<template>
  <div class="kzevb-templates">

    <div class="kzevb-templates__title">E-Mail Templates</div>

    <div style="margin-top: 16px">
      <div class="success-box"
           v-if="successBox.show"
           v-html="successBox.text">
      </div>
      <div class="error-box"
           v-if="errorBox.show"
           v-html="errorBox.text">
      </div>
    </div>

    <div class="kzevb-templates__row">
      <div class="kzevb-templates__col">
        <div class="kzevb-templates__title --card">Neues Template</div>

        <div class="kzevb-templates__field">
          <div class="kzevb-templates__label"></div>
          <div class="kzevb-templates__value --title">
            <span>Ersetzungsvariablen</span>
            <select>
              <option value="%BID%">Bestell-ID</option>
              <option value="%BDATUMDE%">Bestelldatum(DE)</option>
              <option value="%BDATUMEN%">Bestelldatum(EN)</option>
              <option value="%EVBNR%">eVB-Nr.</option>
            </select>
            <button>Einfügen</button>
          </div>
        </div>

        <div class="kzevb-templates__field">
          <div class="kzevb-templates__label">Template-Name</div>
          <div class="kzevb-templates__value">
            <input type="text" v-model="newTemplate.name">
          </div>
        </div>

        <div class="kzevb-templates__field">
          <div class="kzevb-templates__label">Template-Text</div>
          <div class="kzevb-templates__value">
            <textarea v-model="newTemplate.text"></textarea>
          </div>
        </div>

        <button @click="checkUniqueCreateTemplate">Speichern</button>
      </div>

      <div class="kzevb-templates__col">
        <div class="kzevb-templates__title --card">Template einsehen / löschen</div>

        <div class="kzevb-templates__field">
          <div class="kzevb-templates__label">Vorhandene Templates</div>
          <div class="kzevb-templates__value">
            <select @change="getTemplate">
              <option value="" selected disabled></option>
              <option v-for="template in templates"
                      :key="template + 'option'">
                {{ template }}
              </option>
            </select>
          </div>
        </div>

        <div class="kzevb-templates__field">
          <div class="kzevb-templates__label">Template-Name</div>
          <div class="kzevb-templates__value">
            <input type="text" v-model="editTemplate.name">
          </div>
        </div>

        <div class="kzevb-templates__field">
          <div class="kzevb-templates__label">Template-Text</div>
          <div class="kzevb-templates__value">
            <textarea v-model="editTemplate.text"></textarea>
          </div>
        </div>

        <button @click="checkUniqueUpdateTemplate">Speichern</button>
        <button @click="deleteTemplate">Löschen</button>
      </div>
    </div>
  </div>
</template>

<script>

import {handle} from "@/decorators";
import auth_required from "@/mixins/auth_required";
import success_error_states from "@/mixins/success_error_states";

export default {
  name: "templates",
  mixins: [auth_required, success_error_states],
  data: () => ({
    templates: [],
    oldName: '',
    newTemplate: {
      name: '',
      text: '',
    },
    editTemplate: {
      name: '',
      text: '',
    },
    responseTemplate: []
  }),
  methods: {
    @handle('Template <b>{name}</b> created')
    async createTemplate(name, text) {
      this.templates.push(this.newTemplate.name);
      this.templates.sort()
      this.newTemplate.name = ''
      this.newTemplate.text = ''
      return {name, text};
    },
    invalidTemplate(err) {
      this.resetBoxes()
      this.handleError(err)
    },
    async checkUniqueCreateTemplate() {
      const {name, text} = this.newTemplate;
      this.responseTemplate = await this.$services.kzevb.createEmailTemplate(name, text);
      if (this.responseTemplate[0]) {
        await this.createTemplate(name, text)
      } else if (!this.responseTemplate[0]) {
        this.invalidTemplate(this.responseTemplate[1])
      }
    },
    async getTemplate(event) {
      const name = event.target.value;
      const text = await this.$services.kzevb.getEmailTemplate(name);

      this.editTemplate.name = name;
      this.editTemplate.text = text;
      this.oldName = name;
    },
    @handle('Template <b>{name}</b> updated')
    async updateTemplate(name, text) {
      this.templates = await this.$services.kzevb.getEmailTemplates();
      this.templates.sort()
      return {name, text};
    },
    async checkUniqueUpdateTemplate() {
      const {name, text} = this.editTemplate;
      this.responseTemplate = await this.$services.kzevb.updateEmailTemplate(name, text, this.oldName);
      if (this.responseTemplate[0]) {
        await this.updateTemplate(name, text)
      } else if(!this.responseTemplate[0]) {
        this.invalidTemplate(this.responseTemplate[1])
      }
    },
    @handle('Template <b>{name}</b> deleted')
    async deleteTemplate() {
      const {name} = this.editTemplate;
      await this.$services.kzevb.deleteEmailTemplate(name);
      const index = this.templates.indexOf(name);
      this.templates.splice(index, 1);
      this.editTemplate.name = '';
      this.editTemplate.text = '';
      return {name};
    },
  },
  async mounted() {
    this.templates = await this.$services.kzevb.getEmailTemplates();
    this.templates.sort()
  }
}

</script>

<style lang="stylus" scoped>
.kzevb-templates
  padding 10px

  &__title
    padding 2px
    background #aaa
    font-weight bold
    color white
    margin-bottom 30px

    &.--card
      background #7eb51e
      font-size 1.3em

  &__row
    display flex
    justify-content space-between

  &__col
    width 100%

  &__col + &__col
    margin-left 20px

  &__field
    display flex
    margin 8px 0

  &__label
    width 40%

  &__value
    display flex
    width 60%
    flex-direction column

    &.--title
      flex-direction row
      justify-content space-between
      align-items center

    *
      display block

textarea
  width 100%
  display block
</style>
