<template>
  <div>
    <div class="wrap-container">
      <div class="selector-wrap">
        <div class="selector">
          <button @click="toggleDate('month', -1)">Vorher.</button>
          <span>{{ date.format('MMMM') }}</span>
          <button @click="toggleDate('month', 1)">Nächste.</button>
        </div>
        <div class="selector">
          <button @click="toggleDate('year', -1)">Vorher.</button>
          <span>{{ date.format('YYYY') }}</span>
          <button @click="toggleDate('year', 1)">Nächste.</button>
        </div>
      </div>
      <div class="inner-container-week">
        <div class="calendar week" v-for="(w, wIndex) in week" @click.self="showWeekPopup(w)">
          {{ w }}
          <form class="popup" v-show="activePopupWeek === w" @submit.prevent="onWeekSubmit">
            <slot name="week-popup" v-bind:wIndex="wIndex"/>
            <input type="hidden" name="week" :value="wIndex">
            <input type="hidden" name="month" :value="date.month() + 1">
            <input type="hidden" name="year" :value="date.year()">
            <div class="buttons">
              <button type="submit">Save</button>
              <button type="button" @click="hideWeekPopup">Close</button>
            </div>
          </form>
        </div>
      </div>
      <div class="inner-container-days">
        <div class="calendar days"
             :class="{
               'today': isToday(d),
               'disabled': isDisabled(d),
               'earlier': isEarlier(d),
             }"
             v-for="d in days"
        >
          {{ d }}
          <slot name="day" v-bind:date="dateString(d)"/>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

const moment = require('moment')

export default {
  name: "datepicker",

  props: ['disabledWeekDays', 'disabledDates'],

  data() {
    return {
      days: [],
      week: [],
      month: [],
      date: moment().lang('en'),

      activePopupWeek: null,
      activePopupDay: null,
    }
  },
  created() {
    for (let i = 0; i <= 6; i++) {
      this.week[i] = moment().lang('de').startOf('isoWeek').weekday(i).format('dddd')
    }

  },
  mounted() {
    this.receiverArrayDays()

  },
  methods: {
    receiverArrayDays() {
      const days = []
      let firstDayInMonthIndex = this.date.startOf('month').day() - 1
      if (firstDayInMonthIndex === -1) {
        firstDayInMonthIndex = 6
      }
      for (let i = firstDayInMonthIndex; i < this.date.daysInMonth() + firstDayInMonthIndex; i++) {
        const dateArray = [this.date.year(), this.date.month(), i + 1 - (firstDayInMonthIndex)]
        dateArray.join('-')
        days[i] = moment(dateArray).date()
        this.days = days
      }
    },
    showWeekPopup(w) {
      this.activePopupWeek = w;
      this.activePopupDay = null;
    },
    hideWeekPopup() {
      this.activePopupWeek = null;
    },
    parseForm(form) {
      const data = {};
      for (const el of form.elements) {
        if (el.name) {
          if (el.type === 'checkbox') {
            data[el.name] = el.checked;
          } else if (el.type === 'number') {
            data[el.name] = Number.parseInt(el.value);
          } else if (el.type === 'hidden') {
            data[el.name] = Number.parseInt(el.value);
          } else if (el.value !== null && el.value !== undefined) {
            data[el.name] = el.value;
          }
        }
      }
      return data
    },
    onWeekSubmit(event) {
      const data = this.parseForm(event.target);
      this.$emit('weekSubmit', data);
      this.hideWeekPopup();
    },

    toggleDate(monthOrYear, step) {
      const date = moment(this.date)
      date.add(monthOrYear, step)
      this.date = date
      this.receiverArrayDays()
    },
    dateString(date) {
      const dateArray = [this.date.year(), this.date.month(), date]
      dateArray.join('-')
      const dateObj = moment(dateArray)
      return dateObj.format('DD.MM.YYYY');
    },
    isToday(date) {
      const dateArray = [this.date.year(), this.date.month(), date]
      dateArray.join('-')
      const dateObj = moment(dateArray)
      return dateObj.format('DD MM YY') === moment().format('DD MM YY')
    },
    isDisabled(date) {
      const dateArray = [this.date.year(), this.date.month(), date]
      dateArray.join('-')
      const dateObj = moment(dateArray)

      for (const disabledDateStr of this.disabledDates) {
        const disabledDate = moment(disabledDateStr, 'DD.MM.YYYY');
        if (dateObj.format('DD MM YY') === disabledDate.format('DD MM YY')) {
          return true;
        }
      }

      for (const disabledWeek of this.disabledWeekDays) {
        if (dateObj.weekday() === disabledWeek) {
          return true;
        }
      }

      return false;
    },
    isEarlier(date) {
      const today = moment();
      const dateArray = [this.date.year(), this.date.month(), date];
      dateArray.join('-');
      const dateObj = moment(dateArray);
      return dateObj < today;
    },
  }
}
</script>

<style lang="stylus" scoped>
.weekend
  color #D70000
  background rgba(#D70000, 0.1)


.selector
  display flex

  span
    margin: 0 8px
    width: 120px
    display block
    text-align center
    font-size: 20px
    font-weight: 600

  button
    cursor pointer
    padding: 4px 12px

  & + .selector
    margin-top: 16px

.wrap-container
  margin-top: 50px
  padding: 0 20px
  width: 100%

.week
  background rgba(#c9c9c9, 0.5)
  border-left 1px solid rgba(#c9c9c9, 1)
  padding: 20px 0
  position relative

  &:first-child
    border-left none


.days
  cursor pointer
  border-right 1px solid rgba(#c9c9c9, 1)
  border-bottom 1px solid rgba(#c9c9c9, 1)
  height: 100px
  display flex
  flex-direction column
  align-items center
  justify-content center

  &.earlier
    color gray

  &.disabled
    background #ff6868
    color white
    font-weight bold

  &.today
    background rgba(#f1932a, 0.5)
    font-weight bold
    opacity 1 !important


.inner-container-days
.inner-container-week
  display flex
  flex-wrap wrap

.inner-container-days
  border-left 1px solid rgba(#c9c9c9, 1)

.inner-container-week
  margin-top: 16px
  border 1px solid rgba(#c9c9c9, 1)
  border-bottom none

.calendar
  text-align center
  width: calc(100% / 7)
  position relative

.popup
  position absolute
  top 100%
  width 256px
  min-height 128px
  background #fef
  border 1px solid black
  z-index 999
  padding 8px
  display flex
  flex-direction column

  label
    display block
    border-bottom 1px dashed black

  .buttons
    margin-top auto
    display flex

</style>
