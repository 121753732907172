<template>
    <div class="kzevb-prices">
        <div style="margin-top: 16px">
            <div class="success-box"
                 v-if="successBox.show"
                 v-html="successBox.text">
            </div>
            <div class="error-box"
                 v-if="errorBox.show"
                 v-html="errorBox.text">
            </div>
        </div>

        <table cellpadding="0" cellspacing="0">
            <thead>
                <tr>
                    <td>KDNR</td>
                    <td>Passwort</td>
                    <td>Firma</td>
                    <td>Name</td>
                    <td>Straße + Nr.</td>
                    <td>PLZ</td>
                    <td>Ort</td>
                    <td>Land</td>
                    <td>E-Mail</td>
                    <td>Telefon</td>
                    <td>Lief. E-Mail</td>
                    <td>Lief. Handy</td>
                    <td>Preis ohne GK</td>
                    <td>Preis mit GK</td>
                    <td>Aktiv?</td>
                    <td></td>
                </tr>
            </thead>

            <tbody>
                <tr v-for="(user, userKey) in users"
                    :key="`log${userKey}Row`" @click="onIdClick(user.order_id)"
                    :class="!user.active ? '--inactive' : ''"
                >
                    <td><input type="text" v-model="user.login"></td>
                    <td><input type="text" v-model="user.password"></td>
                    <td><input type="text" v-model="user.company"></td>
                    <td><input type="text" v-model="user.name"></td>
                    <td><input type="text" v-model="user.street_and_number"></td>
                    <td><input type="text" v-model="user.postcode"></td>
                    <td><input type="text" v-model="user.state"></td>
                    <td><input type="text" v-model="user.country"></td>
                    <td><input type="text" v-model="user.email"></td>
                    <td><input type="text" v-model="user.phone"></td>
                    <td><input type="text" v-model="user.delivery_email"></td>
                    <td><input type="text" v-model="user.delivery_phone"></td>
                    <td><input type="number" v-model="user.price_evb"></td>
                    <td><input type="number" v-model="user.price_greencard"></td>
                    <td>
                        <input type="checkbox" v-model="user.active">
                    </td>
                    <td>
                        <button @click="saveUser(user.login)">
                            <img src="../../assets/images/save-icon.png" alt="">
                        </button>
                        <button @click="deleteUser(user.login)">
                            <img src="../../assets/images/del-1.png" alt="">
                        </button>
                        <button @click="sendUserEmail(user.login)">
                            <img src="../../assets/images/email-1.png" alt="">
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>

import auth_required from "@/mixins/auth_required";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import success_error_states from "@/mixins/success_error_states";
import {handle} from "@/decorators";

export default {
    name: "kzevb_logs",
    mixins: [auth_required, success_error_states],
    data: () => ({
        users: [],
    }),
    computed: {
    },
    methods: {
        @handle("User data saved")
        async saveUser(login) {
            const user = this.users.find(u => u.login === login);
            this.$services.kzevb.updateSiteUser(user);
            console.log(user);
        },
        @handle("User credentials email sent")
        async sendUserEmail(login) {
            this.$services.kzevb.sendSiteUserCredentials(login);
        },
        @handle("User deleted")
        async deleteUser(login) {
            this.$services.kzevb.deleteSiteUser(login);
            const userIndex = this.users.findIndex(u => u.login === login);
            this.users.splice(userIndex, 1);
        },
    },
    async mounted() {
        this.users = await this.$services.kzevb.getSiteUsers();
    },
}

</script>

<style lang="stylus" scoped>
    .kzevb-prices
        padding 10px

        &__result
            margin-top 15px

    button
        font-weight bold

    table
        width 100%

        thead tr
            background #FFFCAB
            font-weight bold

        td
            text-align center
            padding 4px 0

    input[type=text], input[type=number]
        width 60px
        transition .2s

    tr.--inactive
        background #FFA4CD
</style>
