<template>
    <div class="rights-page">
        <div class="success-box"
             v-if="successBox.show"
             v-html="successBox.text">
        </div>
        <div class="error-box"
             v-if="errorBox.show"
             v-html="errorBox.text">
        </div>
        <span>Benutzer:</span>
        <select v-model="selectedUser">
            <option disabled selected :value="null">
                Bitte wählen
            </option>

            <option v-for="(user, optionKey) in users"
                    :key="`userOption${optionKey}`"
                    :value="user">
                {{ user.username }} ({{ user.full_name }})
            </option>
        </select>
        <div class="rights-page__rights" v-if="selectedUser">
            <p>Rechte ändern für: {{ selectedUser.username }}</p>
            <div class="rights-page__checkbox">
                <input id="home" type="checkbox" v-model="selectedUser.rights.home" value="home" />
                <label for="home">Home</label>
            </div>
            <div class="rights-page__checkbox">
                <input id="admin" type="checkbox" v-model="selectedUser.rights.admin" value="admin" />
                <label for="admin">Admin</label>
            </div>
            <div class="rights-page__checkbox">
                <input id="goerlitz" type="checkbox" v-model="selectedUser.rights.goerlitz" value="goerlitz" />
                <label for="goerlitz">Görlitz</label>
            </div>
            <div class="rights-page__checkbox">
                <input id="akv" type="checkbox" v-model="selectedUser.rights.akv" value="akv" />
                <label for="akv">AKV</label>
            </div>
            <div class="rights-page__checkbox">
                <input id="kzevb" type="checkbox" v-model="selectedUser.rights.kzevb" value="kzevb" />
                <label for="kzevb">KZEVB</label>
            </div>
            <div class="rights-page__checkbox">
                <input id="kkv" type="checkbox" v-model="selectedUser.rights.kkv" value="kkv" />
                <label for="kkv">KKV</label>
            </div>
            <div class="rights-page__checkbox">
                <input id="kkeu" type="checkbox" v-model="selectedUser.rights.kkeu" value="kkeu" />
                <label for="kkeu">KKEU</label>
            </div>
            <div class="rights-page__checkbox">
                <input id="zkv" type="checkbox" v-model="selectedUser.rights.zkv" value="zkv" />
                <label for="zkv">ZKV</label>
            </div>
            <div class="rights-page__checkbox">
                <input id="za" type="checkbox" v-model="selectedUser.rights.za" value="za" />
                <label for="za">ZA</label>
            </div>
            <div class="rights-page__checkbox">
                <input id="vin_check" type="checkbox" v-model="selectedUser.rights.vin_check" value="vin_check" />
                <label for="vin_check">VIN-CHECK</label>
            </div>
            <div class="rights-page__checkbox">
                <input id="ra" type="checkbox" v-model="selectedUser.rights.ra" value="ra" />
                <label for="ra">RA</label>
            </div>
            <div class="rights-page__checkbox">
                <input id="fsp" type="checkbox" v-model="selectedUser.rights.fsp" value="fsp" />
                <label for="fsp">FSP</label>
            </div>
            <div class="rights-page__checkbox">
                <input id="gf" type="checkbox" v-model="selectedUser.rights.gf" value="gf" />
                <label for="gf">GF</label>
            </div>
            <button @click="saveUserRights">Speichern</button>
        </div>
    </div>
</template>

<script>
import auth_required from "@/mixins/auth_required";
import success_error_states from "@/mixins/success_error_states";
import {handle} from "@/decorators";

export default {
    name: "rights",
    mixins: [auth_required, success_error_states],
    data: () => ({
        users: [],
        selectedUser: null,
    }),
    async created() {
        this.users = await this.$services.admin.getUsers();
    },
    methods: {
        @handle('Benutzerrechte <b>{username}</b> erfolgreich gespeichert')
        async saveUserRights() {
            await this.$services.admin.setRights(
                this.selectedUser.username,
                this.selectedUser.rights
            );
            return { username: this.selectedUser.username }
        },
    }
}
</script>

<style lang="stylus" scoped>
    .rights-page
        padding 8px

        button
            margin-top 16px
</style>
