<template>
    <div class="main">
        <div class="main__menu">
            <router-link to="/home" active-class="a-active">Home</router-link>
            <router-link to="/admin" active-class="a-active">Admin</router-link>
            <router-link to="/akv" active-class="a-active">AKV</router-link>
            <router-link to="/kzevb" active-class="a-active">KZEVB</router-link>
            <router-link to="/kkv" active-class="a-active">KKV</router-link>
            <router-link to="/kkeu" active-class="a-active">KKEU</router-link>
            <router-link to="/zkv" active-class="a-active">ZKV</router-link>
            <router-link to="/ra" active-class="a-active">RA</router-link>

            <current-user-panel
                style="margin-left: auto"
                :nickname="$store.state.userData.username"
                ip="188.162.15.45"
                @logout-click="logout" />
        </div>
        <div class="main__body">
            <slot></slot>
        </div>
        <div class="main__notification" v-if="notification">
            <div class="main__notification-title">{{ notification.title }}</div>
            <div class="main__notification-text">{{ notification.text }}</div>
            <button @click="notification = null">OK</button>
        </div>
        <audio src="../assets/alarm.mp3" ref="notification"></audio>
    </div>
</template>

<script>
import CurrentUserPanel from '@/components/current-user-panel'
import centrifugoMixin from "../mixins/centrifugo";

export default {
    name: "main-layout",
    mixins: [centrifugoMixin],
    data: () => ({
        centrifugo: null,
        notification: null,
    }),
    components: { CurrentUserPanel },
    methods: {
        async logout() {
            localStorage.removeItem('auth_token');
            await this.$router.push({ name: 'login' });
        },
        showNotification(title, text) {
            this.notification = { title, text };
            this.$refs.notification.play()
        },
    },
};

</script>

<style lang="stylus" scoped>
.main
    position relative

    &__menu
        height 36px
        background-color #dfdfdf
        display flex
        a
          line-height 36px
          color #010101
          margin 0 10px 0 5px

    &__body
        font-size 0.9em

    &__notification
        position fixed
        border 1px solid red
        padding 8px
        background white
        left 8px
        bottom 8px
        max-width 228px

    &__notification-title
        font-size 14px
        font-weight bold

    &__notification-text
        margin-top 8px
        font-size 11px

audio
    opacity 0
</style>
