<template>
  <div class="kzevb-orders">
    <div class="kzevb-orders__filter-row">
      <div>
        <input type="text"
               placeholder="Email/phone/company/person name..."
               v-model="searchInputText"
               style="margin-right: 8px">

        <datepicker v-show="filter !== 'evb_active_before'" v-model="searchDateFrom" value-type="format"
                    format="DD.MM.YYYY"></datepicker>
        <span v-show="filter !== 'evb_active_before'">(from)</span>

        <datepicker v-model="searchDateTo" value-type="format" format="DD.MM.YYYY"></datepicker>
        <span v-show="filter !== 'evb_active_before'">(to)</span>

        <button @click="getOrders">OK</button>
      </div>

      <div>
        Filter:
        <select v-model="filter">
          <option value="all">Alle</option>
          <option value="all_besides_cancelled">Alle außer stornierte</option>
          <option value="not_checked">Zu bearbeiten</option>
          <option value="cancelled">Storniert</option>
          <option value="paid">Zahlung erhalten</option>
          <option value="evb_active_before">eVB gültig bis</option>
        </select>
      </div>

      <div style="display: flex; align-items: center">
        <div>
          Limit:
          <select @change="setLimit($event.target.value)" :value="limit">
            <option value="30">30</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div>

        <div class="pagination">
          <div v-for="index in getPages()">
            <a class="content" v-if="currentPage !== index" href="#" @click="setPage(index)">{{ index }}</a>
            <a style="font-weight: bold; color: #7EB51E;" class="content" v-else>{{ index }}</a>
          </div>
        </div>
      </div>
    </div>

    <loader v-if="loading" style="margin: auto"/>

    <table v-if="!loading" cellspacing="0" cellpadding="0">
      <thead>
      <tr>
        <th>BID</th>
         <th>Name</th>
        <th>KDNR</th>
        <th>Site</th>
        <th>Datum</th>
        <th>eVB-Nr.</th>
        <th>Karten-Nr.</th>
        <th>RNR</th>
        <th>Preis</th>
        <th>Status</th>
        <th>Bezahlung</th>
        <th>Prüf.</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(order, orderKey) in orders"
          @click="onRowClick(order.id)"
          :key="`order${orderKey}Row`">
        <td>
          <b>
            {{ order.id }}
            <sup v-if="order.reseller" style="color: blue">{{ order.reseller.name[0].toUpperCase() }}</sup>
          </b>
        </td>
         <td v-if="order.customer_type === 'person'">{{ order.delivery_name }} {{ order.delivery_surname }}</td>
         <td v-else>{{ order.delivery_company_name }}</td>
        <td>{{ order.user_login }}</td>
        <td>{{ order.site }}</td>
        <td>{{ prettifyDate(order.date_of_create) }}</td>
        <td>{{ order.insurance_number }}</td>
        <td>{{ order.greencard_number }}</td>
        <td>
          <span v-if="order.payment">{{ order.payment.id }}</span>
        </td>
        <td>{{ order.summary_price_pretty }} EUR</td>
        <td :style="`background-color: ${tdColor(order.status.sent, order.status.cancelled)}`">
          {{ sendingText(order.status.sent, order.status.cancelled) }}
        </td>
        <td :style="`background-color: ${tdColor(order.status.paid)}`">
          {{ paymentText(order.status.paid) }}
        </td>
        <td :style="`background-color: ${tdColor(order.status.data_checked)}`">
          {{ checkingText(order.status.data_checked) }}
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import auth_required from "@/mixins/auth_required";
import success_error_states from "@/mixins/success_error_states";
import loader from "@/components/loader";
import Datepicker from 'vue2-datepicker'

export default {
  name: "orders",
  mixins: [auth_required, success_error_states],
  data: () => ({
    loading: false,
    orders: [],
    count: 0,
    interval: null,
    PAYMENT_METHODS: {
      'sparkassen': 'Stripe',
      'paypal': 'PayPal',
      'sofort': 'Sofort',
    },
  }),
  computed: {
    pagesCount() {
      return Math.ceil(this.count / this.limit);
    },
    currentPage() {
      return (this.offset / this.limit) + 1;
    },
    filter: {
      get() {
        return this.$route.query.filter || 'all'
      },
      set(filter) {
        this.$router.push({
          query: {
            ...this.$route.query,
            filter: filter
          }
        })
      }
    },
    offset: {
      get() {
        return this.$route.query.offset || 0
      },
      set(offset) {
        this.$router.push({
          query: {
            ...this.$route.query,
            offset: offset
          }
        })
      }
    },
    limit: {
      get() {
        return this.$route.query.limit || 50
      },
      set(limit) {
        this.$router.push({
          query: {
            ...this.$route.query,
            limit: limit
          }
        })
      }
    },
    searchInputText: {
      get() {
        return this.$route.query.searchInputText || ''
      },
      set(searchInputText) {
        this.$router.push({
          query: {
            ...this.$route.query,
            searchInputText: searchInputText
          }
        })
      }
    },
    searchDateFrom: {
      get() {
        return this.$route.query.searchDateFrom || null
      },
      set(searchDateFrom) {
        this.$router.push({
          query: {
            ...this.$route.query,
            searchDateFrom: searchDateFrom
          }
        })
      }
    },
    searchDateTo: {
      get() {
        return this.$route.query.searchDateTo || null
      },
      set(searchDateTo) {
        this.$router.push({
          query: {
            ...this.$route.query,
            searchDateTo: searchDateTo
          }
        })
      }
    }
  },
  watch: {
    filter: async function () {
      this.limit = 50;
      this.offset = 0;
      await this.getOrders()
    },
    offset: async function () {
      await this.getOrders()
    },
    limit: async function () {
      await this.getOrders()
    },
    searchInputText: async function () {
      await this.getOrders()
    },
  },
  methods: {
    statusColor(statusText) {
      return {
        'Warte auf Zahlung': '#555',
        'Zahlung erhalten': 'green',
        'Schilder geprägt': 'green',
        'Versendet': 'white',
        'Storniert': 'red',
      }[statusText]
    },
    statusBg(statusText) {
      return {
        'Warte auf Zahlung': '#fff',
        'Zahlung erhalten': 'transparent',
        'Schilder geprägt': '#dfdfdf',
        'Versendet': 'green',
        'Storniert': '#fff',
      }[statusText]
    },
    onRowClick(id) {
      this.$router.push({
        name: 'kzevb_order',
        params: {id},
      })
    },
    getPages() {
      let first_page = this.currentPage - 5 > 1 ? this.currentPage - 5 : 1;
      let last_page = this.currentPage + 5 < this.pagesCount ? this.currentPage + 5 : this.pagesCount;
      const range = (start, stop, step = 1) =>
        Array(Math.ceil((stop + 1 - start) / step)).fill(start).map((x, y) => x + y * step)
      return range(first_page, last_page, 1)
    },
    prettifyDate(date) {
      return (new Date(date)).toLocaleString();
    },
    prettifyPrice(price) {
      return (new Date(date)).toLocaleString();
    },
    async getOrders() {
      const date_from = this.searchDateFrom || undefined;
      const date_to = this.searchDateTo || undefined;
      let filter = {};
      if (this.filter === 'evb_active_before') {
        filter = {
          filter: this.filter,
          date_to,
        };
      } else {
        filter = {
          filter: this.filter,
          date_from, date_to
        }
      }

      const ordersData = await this.$services.kzevb.getOrderList(this.limit, this.offset, this.searchInputText, filter);

      this.count = ordersData.count;
      this.orders = ordersData.data;

    },
    async setLimit(limit) {
      this.limit = limit;
      await this.getOrders();
    },
    setPage(page) {
      this.offset = (page - 1) * this.limit;
      this.getOrders();
    },
    paymentMethodImage(method) {
      return {
        sparkassen: "Kreditkarte",
        paypal: "PayPal",
        sofort: "Sofort",
        ueberweisung: "Ueberweisung",
      }[method];
    },
    paymentMethod(method) {
      return {
        sparkassen: "Kreditkarte",
        paypal: "PayPal",
        sofort: "Sofort",
        ueberweisung: "Ueberweisung",
      }[method];
    },
    tdColor(val, v1) {
      if (v1) return '#a78383';
      if (val) return '#6DF26D';
      return '#E9F97A';
    },
    sendingText(val, v1) {
      if (v1) return 'Storniert';
      if (val) return 'Versendet';
      return 'Unbearbeitet';
    },
    paymentText(val) {
      if (val) return 'Bezahlt';
      return 'Unbezahlt';
    },
    checkingText(val) {
      if (val) return 'Geprüft';
      return 'Ungeprüft';
    },
  },
  async created() {
    await this.getOrders();
    this.interval = setInterval(() => {
      this.getOrders();
    }, 5000)
  },
  async destroyed() {
    clearInterval(this.interval);
  },
  components: {loader, Datepicker},
}
</script>

<style lang="stylus" scoped>
.kzevb-orders
  padding 0 10px

  &__filter-row
    background linear-gradient(top, #fff, #adf)
    background -moz-linear-gradient(top, #fff, #adf)
    background -webkit-gradient(linear, left top, left bottom, from(#fff), to(#adf))
    background -o-linear-gradient(top, #fff, #adf)
    background -ms-linear-gradient(top, #fff, #adf)
    background -webkit-linear-gradient(top, #fff, #adf)
    display flex
    margin-top 10px
    padding 4px 15px
    align-items center
    font-size 0.8em
    justify-content space-between

  table
    width 100%
    margin-top 10px
    font-size 12px

    thead
      background-color #FFFCAB

    th, td
      text-align left
      padding 5px

    tbody
      tr:hover
        background-color #FFFCAB
        cursor pointer

  .pagination
    display flex
    font-size 14px
    margin 0

    & > div
      margin-left 5px

</style>


<style lang="stylus">
.mx-input
  padding 8px !important
  height 24px !important

.mx-datepicker
  width 115px !important
</style>
