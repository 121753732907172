<template>
    <div class="resellers">
        <div class="success-box"
             v-if="successBox.show"
             v-html="successBox.text">
        </div>
        <div class="error-box"
             v-if="errorBox.show"
             v-html="errorBox.text">
        </div>
        <table>
            <thead>
                <tr>
                    <td>ID</td>
                    <td>Name</td>
                    <td>Price</td>
                    <td>Price IVK</td>
                    <td></td>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(reseller, key) in resellers" :key="key">
                    <td>
                        <input type="text" v-model="reseller.id">
                    </td>
                    <td>
                        <input type="text" v-model="reseller.name">
                    </td>
                    <td>
                        <input type="number" v-model.number="reseller.price_evb">
                    </td>
                    <td>
                        <input type="number" v-model.number="reseller.price_greencard">
                    </td>
                    <td>
                        <button @click="update(reseller.id)">Save</button>
                    </td>
                </tr>
                <tr>
                    <td>
                        <input type="text" v-model="addReseller.id" placeholder="ID...">
                    </td>
                    <td>
                        <input type="text" v-model="addReseller.name" placeholder="Name...">
                    </td>
                    <td>
                        <input type="number" v-model.number="addReseller.price_evb" placeholder="Price...">
                    </td>
                    <td>
                        <input type="number" v-model.number="addReseller.price_greencard" placeholder="Price greencard...">
                    </td>
                    <td>
                        <button @click="create">Create</button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import {handle} from "@/decorators";
import auth_required from "../../mixins/auth_required";
import success_error_states from "../../mixins/success_error_states";


export default {
    name: "resellers",
    mixins: [auth_required, success_error_states],
    data: () => ({
        resellers: [],
        addReseller: {
            id: '',
            name: '',
            price_evb: 0,
            price_greencard: 0,
        },
    }),
    methods: {
        @handle("Reseller was created")
        async create() {
            const { id, name, price_evb, price_greencard } = this.addReseller;
            const reseller = await this.$services.kzevb.createReseller(id, name, price_evb, price_greencard);
            this.resellers.push(reseller);
            this.addReseller.id = '';
            this.addReseller.name = '';
            this.addReseller.price_evb = 0;
            this.addReseller.price_greencard = 0;
        },
        @handle("Reseller was saved")
        async update(resellerId) {
            const reseller = this.resellers.find(el => el.id === resellerId);
            await this.$services.kzevb.updateReseller(resellerId, reseller);
        },

    },
    async created() {
        this.resellers = await this.$services.kzevb.getAllResellers();
    },
}
</script>

<style scoped>

</style>
