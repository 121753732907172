<template>
  <div class="kkv-order">

    <div style="margin-top: 16px">
      <div class="success-box"
           v-if="successBox.show"
           v-html="successBox.text">
      </div>
      <div class="error-box"
           v-if="errorBox.show"
           v-html="errorBox.text">
      </div>

      <div class="error-box"
           v-if="leaved">
        You're leaved from this order because other user editing this order
      </div>

      <div class="kkv-order__actions-panel">
        <button v-if="!editing && !leaved && !can_bump" @click="enableEdit">
          Bearbeitungsmodus
        </button>

        <button @click="saveEdit" class="save-button" v-if="editing && !leaved && !can_bump">
          Speichern
        </button>

        <button @click="cancelEdit" v-if="editing && !leaved && !can_bump">
          Ansichtsmodus
        </button>

        <button @click="goToList">Bestellübersicht</button>
      </div>
    </div>

    <div class="kkv-order__row">
      <div class="kkv-order__col">
        <div class="kkv-order__field">
          <div class="kkv-order__field-label">Bestellnummer:</div>
          <div class="kkv-order__field-value"><b>{{ order.id }}</b></div>
        </div>

        <div class="kkv-order__field">
          <div class="kkv-order__field-label">Reseller:</div>
          <div class="kkv-order__field-value">
            <b v-if="order.reseller_id">
              {{ order.reseller_name }}
              ({{ order.reseller_id }})
            </b>
            <b v-else>NONE</b>
          </div>
        </div>

        <div class="kkv-order__field">
          <div class="kkv-order__field-label">Site:</div>
          <div class="kkv-order__field-value">
            <b>KURZZEITKENNZEICHEN-VERSAND</b>
          </div>
        </div>

        <div class="kkv-order__field">
          <div class="kkv-order__field-label">Datum:</div>
          <div class="kkv-order__field-value">{{ prettifyDate(order.date_of_create) }}</div>
        </div>

        <div class="kkv-order__field">
          <div class="kkv-order__field-label">E-Mail-Adresse:</div>
          <div class="kkv-order__field-value">
            <a v-if="!editing" :href="'mailto:' + order.contact_email">
              {{ order.contact_email }}
            </a>
            <input type="text" v-if="editing" v-model="editData.contact_email">
          </div>
        </div>

        <div class="kkv-order__field">
          <div class="kkv-order__field-label">Telefon:</div>
          <div class="kkv-order__field-value">
            <template v-if="!editing">
              {{ order.contact_phone }}
            </template>
            <input type="text" v-if="editing" v-model="editData.contact_phone">
          </div>
        </div>
      </div>
      <div class="kkv-order__col">
        <div class="kkv-order__field">
          <div class="kkv-order__field-label">Evb:</div>
          <div class="kkv-order__field-value">
            <input type="text" v-if="editing" v-model="editData.evb_number">
            <span v-else>{{ editData.evb_number }}</span>
          </div>
        </div>
        <div class="kkv-order__field">
          <div class="kkv-order__field-label">Evb Okay:</div>
          <div class="kkv-order__field-value">
            <input type="checkbox" :checked="order.evb_ok" @change="setOrderEvbOk">
          </div>
        </div>
        <div class="kkv-order__field">
          <div class="kkv-order__field-label">Versicherungsgesellschaft:</div>
          <div class="kkv-order__field-value">
            <input type="text" v-if="editing" v-model="editData.evb_company">
            <span v-else>{{ editData.evb_company }}</span>
          </div>
        </div>
        <div class="kkv-order__field">
          <div class="kkv-order__field-label">Kennzeichen:</div>
          <div class="kkv-order__field-value">
            <div style="display: flex" v-if="(order.status === 'SENT' && order.kennzeichen === '') || editing">
              <input type="text" v-model="editData.kennzeichen">
              <button v-if="order.status === 'SENT' && order.kennzeichen === ''" @click="setTransportIndicator">OK</button>
            </div>
            <span v-else>{{ editData.kennzeichen }}</span>
          </div>
        </div>
        <hr>
        <div class="kkv-order__field">
          <div class="kkv-order__field-label">Gültig von:</div>
          <datepicker v-if="editing" :disabled-date="isDisabledDate" v-model="startDate" value-type="format" format="DD.MM.YYYY"></datepicker>
          <div v-else class="kkv-order__field-value">{{ order.start_date | date() }}</div>
        </div>
        <div class="kkv-order__field">
          <div class="kkv-order__field-label">Gültig biz:</div>
          <datepicker v-if="editing" v-model="editData.end_date" value-type="format" format="DD.MM.YYYY"></datepicker>
          <div v-else class="kkv-order__field-value">{{ order.end_date | date() }}</div>
        </div>
        <div v-if="order.payment" style="margin-top: 32px;" class="kkv-order__field">
          <div class="kkv-order__field-label">Zahlungsdetails:</div>
          <div class="kkv-order__field-value">{{ order.payment.external_payment_id }}</div>
        </div>
        <div v-if="order.payment" class="kkv-order__field">
          <div class="kkv-order__field-label"></div>
          <div class="kkv-order__field-value">{{ order.payment.date_of_create }}</div>
        </div>
      </div>
      <div class="kkv-order__col">
        <div class="kkv-order__field">
          <div class="kkv-order__field-label">Aktueller Status:</div>
          <div class="kkv-order__field-value">{{ ORDER_STATUSES[order.status] }}</div>
        </div>
        <div class="kkv-order__field" v-if="order.payment_method">
          <div class="kkv-order__field-label">Zahlungsmethode:</div>
          <div class="kkv-order__field-value">{{ PAYMENT_METHODS[order.payment_method] }}</div>
        </div>
        <div v-if="order.promo_code" class="kkv-order__field">
          <div class="kkv-order__field-label">Gutscheincode:</div>
          <div class="kkv-order__field-value">{{ order.promo_code.code }}</div>
        </div>
        <div class="kkv-order__field">
          <div class="kkv-order__field-label">Preis:</div>
          <div class="kkv-order__field-value">{{ order.summary_price }} EUR</div>
        </div>
        <div class="kkv-order__field" v-if="order.payment !== null">
          <div class="kkv-order__field-label">Rechnung PDF:</div>
          <div class="kkv-order__field-value">
            <a target="_blank" :href="invoicePDFUrl">KKV-{{ invoicePdfYear }}-{{ paymentId }}</a>
          </div>
        </div>
        <div class="kkv-order__field" v-if="correctInvoicePDF !== null">
          <div class="kkv-order__field-label">Korrektur PDF:</div>
          <div class="kkv-order__field-value">
            <a target="_blank" :href="correctedInvoicePDFUrl">KKV-GUT-{{
                correctInvoicePdfYear
              }}-{{ correctInvoicePDF.id }}</a>
          </div>
        </div>
        <div class="kkv-order__field">
          <div class="kkv-order__field-label">Status:</div>
          <div class="kkv-order__field-value">
            <select @change="setStatus($event.target.value)" :value="order.status">
              <option value="WAITING_FOR_PAYMENT">Warte auf Zahlung</option>
              <option value="PAYMENT_RECEIVED">Zahlung erhalten</option>
              <option value="IN_PROCESSING">In Bearbeitung</option>
              <option value="ISSUED">Angemeldet</option>
              <option value="SENT">Versendet</option>
              <option value="REQUEST_SENT">Ruckfragen</option>
              <option value="WAITING_FOR_ISSUE">Warte auf Anmeldung</option>
              <option value="CANCELED">Storniert</option>
            </select>
          </div>
        </div>
        <div>
          <div class="kkv-order__field">
            <div class="kkv-order__field-label">Sendungs-Nummer:</div>
            <div class="kkv-order__field-value">
              <input v-if="order.status === 'ISSUED'" v-model="parcelNumber" type="text">
              <span v-else>{{ order.package_number }}</span>
            </div>
          </div>
          <div class="kkv-order__field">
            <div class="kkv-order__field-label">Transportunternehmen:</div>
            <div style="max-width: 50%" class="kkv-order__field-value">
              <div v-if="order.status === 'ISSUED'" class="kkv-order__radio-inputs">
                <label for="tnt">TNT</label>
                <input id="tnt" name="transport_сompany" v-model="transportCompany" value="TNT" type="radio">
                <label for="dhl">DHL</label>
                <input id="dhl" name="transport_сompany" v-model="transportCompany" value="DHL" type="radio">
                <label for="andere">Andere</label>
                <input id="andere" name="transport_сompany" v-model="transportCompany" value="Andere" type="radio">
              </div>
              <a target="_blank" :href="order.package_service" v-else>
                {{ order.package_service }}</a>
            </div>
          </div>
          <div v-if="transportCompany === 'Andere'" class="kkv-order__field">
            <div class="kkv-order__field-label">Link:</div>
            <div class="kkv-order__field-value">
              <input v-model="linkAndere" type="text">
            </div>
          </div>
          <div v-if="order.status === 'ISSUED'" class="kkv-order__field">
            <button @click="setTransportCompany">OK</button>
          </div>
          <button @click="priceData = true" style="margin: 8px 0;">Rechnungskorrektur erstellen</button>
          <div v-if="priceData">
            <div class="kkv-order__field">
              <div class="kkv-order__field-label">Kurzzeitkennzeichen</div>
              <div class="kkv-order__field-value">
                <input onkeyup="this.value = this.value.replace(/[^\d.]/g,'');" v-model="correctNetto" type="text">
              </div>
            </div>
            <div class="kkv-order__field">
              <div class="kkv-order__field-label">Versicherung</div>
              <div class="kkv-order__field-value">
                <input onkeyup="this.value = this.value.replace(/[^\d.]/g,'');" type="text" v-model="correctInsurance">
              </div>
            </div>
            <div class="kkv-order__field">
              <div class="kkv-order__field-label">Amtsgebühr</div>
              <div class="kkv-order__field-value">
                <input onkeyup="this.value = this.value.replace(/[^\d.]/g,'');" type="text" v-model="correctFee">
              </div>
            </div>
            <button @click.prevent="updatePriceData">Speichern</button>
          </div>
        </div>
      </div>
    </div>

    <hr>

    <div class="kkv-order__row">
      <div class="kkv-order__col">
        <div class="kkv-order__field">
          <div class="kkv-order__field-label"><b>Halter:</b></div>
          <div class="kkv-order__field-value"></div>
        </div>
      </div>
      <div class="kkv-order__col">
        <div class="kkv-order__field">
          <div class="kkv-order__field-label"><b>Lieferadresse:</b></div>
          <div class="kkv-order__field-value"></div>
        </div>
      </div>
      <div class="kkv-order__col">
        <div class="kkv-order__field">
          <div class="kkv-order__field-label"><b>Rechnungsadresse:</b></div>
          <div class="kkv-order__field-value"></div>
        </div>
      </div>
    </div>

    <hr>

    <div class="kkv-order__row">
      <div class="kkv-order__col">
                <pre style="white-space: pre-line" v-if="editing">
                    <input type="text" v-model="editData.person_surname"/> <input type="text"
                                                                                  v-model="editData.person_name"/> <input
                  type="text" v-model="editData.person_company_name"/>
                    <input type="text" v-model="editData.person_street"/> <input type="text"
                                                                                 v-model="editData.person_house"/>
                    <input type="text" v-model="editData.person_postcode"/> <input type="text"
                                                                                   v-model="editData.person_state"/>
                    <input type="text" v-model="editData.person_country"/> <input type="text"
                                                                                  v-model="editData.person_additional_info"/>
                </pre>
        <pre style="white-space: pre-line" v-else>
                    {{ order.person_surname }} {{ order.person_name }} {{ order.person_company_name }}
                    {{ order.person_additional_info }}
                    {{ order.person_street }} {{ order.person_house }}
                    {{ order.person_postcode }} {{ order.person_state }}
                    {{ order.person_country }}
                </pre>
        <button style="margin: 0; margin-bottom: 12px;" v-clipboard:copy="copyData('person')">Kopie von</button>
        <div class="kkv-order__middle-field-label"><b>VIN Nummer: </b>
          {{ order.vin_number }}
        </div>
        <br>
        <div>
          <div>
            <input type="checkbox" @change="setDocsOk" :checked="order.docs_ok">
            Brief
          </div>
          <div>
            <input type="checkbox" @change="setAusweis" :checked="order.ausweis">
            Schein
          </div>
          <div>
            <input type="checkbox" @change="setVollmacht" :checked="order.vollmacht">
            HU
          </div>
          <div>
            <input type="checkbox" @change="setTuv" :checked="order.tuv">
            Ausweis
          </div>
          <div>
            <input type="checkbox" @change="setAbgemeldet" :checked="order.abgemeldet">
            Abgemeldet
          </div>
        </div>
      </div>
      <div class="kkv-order__col">
                <pre style="white-space: pre-line" v-if="editing">
                    <input type="text" v-model="editData.delivery_surname"/> <input type="text"
                                                                                    v-model="editData.delivery_name"/> <input
                  type="text" v-model="editData.delivery_company_name"/>
                    <input type="text" v-model="editData.delivery_street"/> <input type="text"
                                                                                   v-model="editData.delivery_house"/>
                    <input type="text" v-model="editData.delivery_postcode"/> <input type="text"
                                                                                     v-model="editData.delivery_state"/>
                    <input type="text" v-model="editData.delivery_country"/> <input type="text"
                                                                                    v-model="editData.delivery_additional_info"/>
                </pre>
        <pre style="white-space: pre-line" v-else>
                    {{ order.delivery_surname }} {{ order.delivery_name }} {{ order.delivery_company_name }}
                    {{ order.delivery_additional_info }}
                    {{ order.delivery_street }} {{ order.delivery_house }}
                    {{ order.delivery_postcode }} {{ order.delivery_state }}
                    {{ order.delivery_country }}
                </pre>
        <button style="margin: 0; margin-bottom: 12px;" v-clipboard:copy="copyData('delivery')">Kopie von</button>
        <div class="kkv-order__middle-field-label"><b>Bemerkung:</b></div>
        <pre style="white-space: pre-line" v-if="editing">
                  <textarea style="width: 100%" rows="4" v-model="editData.additional_info"></textarea>
                </pre>
        <pre style="white-space: pre-line" v-else>
                    {{ order.additional_info }}
                </pre>
      </div>
      <div class="kkv-order__col">
                <pre style="white-space: pre-line" v-if="editing">
                    <input type="text" v-model="editData.payment_surname"/> <input type="text"
                                                                                   v-model="editData.payment_name"/> <input
                  type="text" v-model="editData.payment_company_name"/>
                    <input type="text" v-model="editData.payment_street"/> <input type="text"
                                                                                  v-model="editData.payment_house"/>
                    <input type="text" v-model="editData.payment_postcode"/> <input type="text"
                                                                                    v-model="editData.payment_state"/>
                    <input type="text" v-model="editData.payment_country"/> <input type="text"
                                                                                   v-model="editData.payment_additional_info"/>
                </pre>
        <pre style="white-space: pre-line" v-else>
                    {{ order.payment_surname }} {{ order.payment_name }} {{ order.payment_company_name }}
                    {{ order.payment_additional_info }}
                    {{ order.payment_street }} {{ order.payment_house }}
                    {{ order.payment_postcode }} {{ order.payment_state }}
                    {{ order.payment_country }}
                </pre>
        <button style="margin: 0; margin-bottom: 12px;" v-clipboard:copy="copyData('payment')">Kopie von</button>
        <div v-if="order.tax_number !== null">
          <div class="kkv-order__field">
            <b class="kkv-order__label">UST.-ID:</b>
          </div>
          <div class="kkv-order__tax-number">{{ order.tax_number }}</div>
        </div>
      </div>
    </div>
    <hr>
    <div class="kkv-order__row">
      <div class="kkv-order__col">
        <textarea style="width: 100%; display: block" rows="3" v-model="noteData.text"></textarea>
        <button @click="writeANote">Notiz speichern</button>

        <div class="kkv-order__notes">
          <div class="kkv-order__note"
               v-for="note in order.notes"
               :key="note.datetime_of_create + 'note'">
            <div class="kkv-order__note-title">
              {{ note.datetime_of_create }}
              <span style="float: right">{{ note.actor_name }}</span>
            </div>

            <div class="kkv-order__note-text" v-html="note.text"></div>
          </div>
        </div>
      </div>

      <div class="kkv-order__col">

        <div class="kkv-order__middle-field">
          <div class="kkv-order__middle-field-label">Template:</div>
          <div class="kkv-order__middle-field-value">
            <select style="width: 192px" @change="selectTemplate">
              <option value="" selected disabled></option>
              <option v-for="template in templates"
                      :key="template + 'option'"
                      :value="template">
                {{ template }}
              </option>
            </select>
          </div>
        </div>

        <div class="kkv-order__middle-field">
          <div class="kkv-order__middle-field-label">E-Mail an:</div>
          <div class="kkv-order__middle-field-value">
            <input style="width: 192px" type="text" v-model="emailData.email"/>
          </div>
        </div>

        <div class="kkv-order__middle-field">
          <div class="kkv-order__middle-field-label">Betreff:</div>
          <div class="kkv-order__middle-field-value">
            Bestellung Nr. K-{{ order.id }}
          </div>
        </div>

        <p class="little-grey-text">Sehr geehrter Kunde,</p>
        <textarea style="width: 100%" rows="7" v-model="emailData.text"></textarea>
        <p class="little-grey-text">
          Mit freundlichen Grüßen <br>
          Das Team von kurzzeitkennzeichen-versand.de
        </p>
        <button style="color: #777" v-show="!emailData.active" @click="emailData.active = true;">
          Senden aktivieren
        </button>
        <button v-show="emailData.active" @click="sendEmail">
          E-Mail senden
        </button>
      </div>
      <div class="kkv-order__col">
        <div style="display: flex; flex-direction: column; align-items: flex-start" class="kkv-order__middle-field-label">Zahlungslink erneut Senden:
          <button style="margin-top: 8px" @click="resendEmail">Senden</button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import auth_required from "@/mixins/auth_required";
import success_error_states from "@/mixins/success_error_states";
import {handle} from "@/decorators";
import moment from "moment";
import Datepicker from 'vue2-datepicker'
import pdfjsLib from 'pdfjs-dist/webpack'


const ORDER_STATUSES = {
  WAITING_FOR_PAYMENT: 'Warte auf Zahlung',
  PAYMENT_RECEIVED: 'Zahlung erhalten',
  IN_PROCESSING: 'In Bearbeitung',
  ISSUED: 'Angemeldet',
  SENT: 'Versendet',
  REQUEST_SENT: 'Ruckfragen',
  WAITING_FOR_ISSUE: 'Warte auf Anmeldung',
  CANCELED: 'Storniert',
}

const Centrifuge = require("centrifuge");

const defaultEmailSubject = 'Bestellung Nr. K-{id}';

const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});


export default {
  name: "kkv-order",
  mixins: [auth_required, success_error_states],
  components: {Datepicker},
  data: () => ({
    correctFee: 13.1,
    correctInsurance: 49.0,
    correctNetto: 0,
    // invoicePdfYear: null,
    // correctInvoicePdfYear: null,
    priceData: false,
    correctInvoicePDF: null,
    order: null,
    ORDER_STATUSES,
    transportCompany: '',
    linkAndere: '',
    parcelNumber: '',
    evbStatus: '',
    PAYMENT_METHODS: {
      'sparkassen': 'Stripe',
      'paypal': 'PayPal',
      'sofort': 'Sofort',
    },
    templates: [],
    creditNoteProducts: [],
    notes: [
      'Kunde konnte telefonisch nicht erreicht werden.',
    ],
    trackingLink: '',
    deliveryMethod: 'DHL',
    emailData: {
      email: '',
      subject: '',
      text: '',
      active: false,
    },
    noteData: {
      text: '',
    },
    leaved: false,
    can_bump: false,
    editing: false,
    editData: {
      contact_email: '',
      contact_phone: '',

      evb_number: '',
      evb_company: '',
      kennzeichen: '',
    },
    creditNoteData: {
      cancelOrder: true,
      productsAmount: 0,
      deliveryAmount: 0,
      note: '',
    },
    customEvb: {
      evbNumber: '',
      greenCardNumber: '',
      pdf: null,
    },
    sendNotificationContacts: {
      sms: '',
      dkEmail: '',
      paymentEmail: '',
    },
  }),
  computed: {
    invoicePdfYear() {
      if (this.order.payment === null) {
        return 0
      } else {
        return moment(this.order.payment.date_of_create).year()
      }
    },
    correctInvoicePdfYear() {
      if (this.correctInvoicePDF === null) {
        return 0
      } else {
        return moment(this.correctInvoicePDF.update_date).year()
      }
    },
    paymentId() {
      if (this.order.payment === null) {
        return 0;
      } else {
        return this.order.payment.id
      }
    },
    invoicePDFUrl() {
      return `/admin_rpc/call_method/?service_name=kkv&method_name=get_order_invoice&order_id=${this.order.id}`;
    },
    correctedInvoicePDFUrl() {
      return `/admin_rpc/call_method/?service_name=kkv&method_name=get_corrected_order_invoice&order_id=${this.order.id}`;
    },
    startDate: {
      get() {
        return this.editData.start_date
      },
      set(value) {
        this.editData.start_date = value
        this.editData.end_date = moment(this.editData.start_date, 'DD.MM.YYYY').add(3, 'days').format('DD.MM.YYYY')
      }
    }
  },
  methods: {
    copyData(type) {
      return `${this.order[type +'_surname']} ${this.order[type + '_name']} ${this.order[type + '_company_name']}\n${this.order[type + '_additional_info']}\n${this.order[type + '_street']} ${this.order[type + '_house']}\n${this.order[type + '_postcode']} ${this.order[type + '_state']}\n${this.order[type + '_country']}`
    },
    isDisabledDate(date) {
      if (this.order.person_country === 'Deutschland') {
        return date.getDay() === 0;
      } else {
        return date.getDay() === 6 || date.getDay() === 0
      }
    },
    @handle('Creation of an invoice adjustment was successful')
    async updatePriceData() {
      await this.$services.kkv.correctPrice(this.order.id, Number(this.correctNetto), Number(this.correctInsurance), Number(this.correctFee), this.$store.state.userData.username)
      this.priceData = false
      this.correctInvoicePDF = await this.$services.kkv.getAdjustmentByOrderId(this.order.id)
    },
    goToList() {
      this.$router.push({name: 'kkv_orders'});
    },

    prettifyDate(date) {
      return (new Date(date)).toLocaleString();
    },

    enableEdit() {
      this.editing = true;
    },

    @handle("Order data successfully changed")
    async saveEdit() {
      const dataForSend = {};
      for (const field in this.editData) {
        if (this.order[field] !== this.editData[field]) {
          dataForSend[field] = this.editData[field];
        }
      }
      const note = await this.$services.kkv.updateOrder(this.order.id, dataForSend);
      this.emailData.email = this.editData.contact_email
      for (const field in this.editData) {
          this.order[field] = this.editData[field];
      }
      this.order.notes = [note, ...this.order.notes];
      this.editing = false;
    },

    @handle("Order status successfully updated")
    async setStatus(status) {
      await this.$services.kkv.setOrderStatus(this.order.id, status);
      this.order.status = status;
      if (status === 'PAYMENT_RECEIVED') {
        this.order = await this.$services.kkv.getOrderById(this.$route.params.id);
      }
    },

    async setTransportIndicator() {
      await this.$services.kkv.saveKennzeichen(this.order.id, this.editData.kennzeichen)
      const orderId = Number(this.$route.params.id);
      this.order = await this.$services.kkv.getOrderById(orderId);
    },

    async setTransportCompany() {
      if (this.transportCompany === 'Andere') {
        this.transportCompany = this.linkAndere
      }
      await this.$services.kkv.savePackage(this.order.id, this.parcelNumber, this.transportCompany)
      const orderId = Number(this.$route.params.id);
      this.order = await this.$services.kkv.getOrderById(orderId);
    },

    @handle("Email sent")
    async resendEmail() {
      await this.$services.kkv.resendEmail(this.order.id)
    },

    cancelEdit() {
      this.editing = false;
    },

    @handle("Note created")
    async writeANote() {
      const note = await this.$services.kkv.writeANote(this.order.id, this.noteData.text);
      this.order.notes.push(note);
      this.noteData.text = '';
    },

    @handle("Order 'Evb Okay' parameter updated successfully")
    async setOrderEvbOk(event) {
      await this.$services.kkv.setOrderEvbOk(this.order.id, event.target.checked);
    },

    async selectTemplate(event) {
      const templateName = event.target.value;
      this.emailData.text = await this.$services.akv.getEmailTemplate(templateName);
      this.emailData.subject = defaultEmailSubject.format(this.order);
    },

    @handle("Email sent")
    async sendEmail() {
      await this.$services.kkv.sendEmail(
        this.order.id, this.emailData.email, this.emailData.subject,
        this.emailData.text);

      this.clearEmailFields();
    },

    clearEmailFields() {
      this.emailData.subject = defaultEmailSubject.format(this.order);
      this.emailData.text = '';
      this.emailData.active = false;
    },

    @handle("setDocsOk successfully")
    async setDocsOk(event) {
      await this.$services.kkv.setDocsOk(this.order.id, event.target.checked);
    },

    @handle("setAusweis successfully")
    async setAusweis(event) {
      await this.$services.kkv.setAusweis(this.order.id, event.target.checked);
    },

    @handle("setVollmacht successfully")
    async setVollmacht(event) {
      await this.$services.kkv.setVollmacht(this.order.id, event.target.checked);
    },

    @handle("setTuv successfully")
    async setTuv(event) {
      await this.$services.kkv.setTuv(this.order.id, event.target.checked);
    },
    @handle("setAbgemeldet successfully")
    async setAbgemeldet(event) {
      await this.$services.kkv.setAbgemeldet(this.order.id, event.target.checked);
    },
    async unlockOrders(id) {
        await this.$services.kkv.unlockOrder(id)
    },
    closeBrowserTab(event) {
      event.preventDefault()
      this.unlockOrders(this.order.id)
    }
  },
  async mounted() {
    const orderId = Number(this.$route.params.id);
    this.order = await this.$services.kkv.getOrderById(orderId);
    this.order.start_date = moment(this.order.start_date).format('DD.MM.YYYY')
    this.order.end_date = moment(this.order.end_date).format('DD.MM.YYYY')
    this.editData = {
      contact_email: this.order.contact_email,
      contact_phone: this.order.contact_phone,

      evb_number: this.order.evb_number,
      evb_company: this.order.evb_company,
      kennzeichen: this.order.kennzeichen,
      additional_info: this.order.additional_info,

      person_surname: this.order.person_surname,
      person_name: this.order.person_name,
      person_company_name: this.order.person_company_name,
      person_street: this.order.person_street,
      person_house: this.order.person_house,
      person_postcode: this.order.person_postcode,
      person_state: this.order.person_state,
      person_country: this.order.person_country,
      person_additional_info: this.order.person_additional_info,

      delivery_surname: this.order.delivery_surname,
      delivery_name: this.order.delivery_name,
      delivery_company_name: this.order.delivery_company_name,
      delivery_street: this.order.delivery_street,
      delivery_house: this.order.delivery_house,
      delivery_postcode: this.order.delivery_postcode,
      delivery_state: this.order.delivery_state,
      delivery_country: this.order.delivery_country,
      delivery_additional_info: this.order.delivery_additional_info,

      payment_surname: this.order.payment_surname,
      payment_name: this.order.payment_name,
      payment_company_name: this.order.payment_company_name,
      payment_street: this.order.payment_street,
      payment_house: this.order.payment_house,
      payment_postcode: this.order.payment_postcode,
      payment_state: this.order.payment_state,
      payment_country: this.order.payment_country,
      payment_additional_info: this.order.payment_additional_info,
      start_date: this.order.start_date,
      end_date: this.order.end_date,
    };
    console.log(this.order)
    this.templates = await this.$services.kkv.getEmailTemplates();
    this.emailData.email = this.order.contact_email;
    await this.$services.kkv.lockOrder(orderId, this.$store.state.userData.username)
    window.addEventListener('beforeunload', this.closeBrowserTab);
    window.addEventListener('unload', this.closeBrowserTab);
    this.correctInvoicePDF = await this.$services.kkv.getAdjustmentByOrderId(this.order.id)
  },
  async created() {
    const orderId = Number(this.$route.params.id);
    localStorage.setItem('id', this.$route.params.id)
  },
  async beforeCreate() {
    if (localStorage.getItem('id')) {
      await this.$services.kkv.unlockOrder(localStorage.getItem('id'))
    }
  },
  destroyed() {
    window.removeEventListener('beforeunload', this.closeBrowserTab);
    window.removeEventListener('unload', this.closeBrowserTab);
  },
  beforeRouteLeave(to, from, next) {
    this.unlockOrders(from.params.id)
    next()
  }
}
</script>

<style lang="stylus" scoped>
.kkv-order
  padding 0 10px

  &__tax-number
    display inline-block
    margin-top: 8px
    font-size: 18px
    border 3px solid red
    padding: 6px 12px

  &__actions-panel
    background-color #afafaf
    display flex
    margin-top 10px
    padding 4px 15px
    justify-content flex-end
    margin-bottom 8px


    &__row
      display flex

  &__radio-inputs
    display flex
    align-items center

    input
      margin: 0 8px

  &__row
    display flex

  &__col
    max-width 33.3333%
    width 100%
    padding 3px

    &.--green
      background-color #97C14E

    select, textarea
      display block
      width 100%

  &__logs
    width 100%
    font-size 0.75em

    tr:nth-child(odd)
      background #f9f9f9

    tr:nth-child(even)
      background #ebebeb

  &__field
    display flex
    margin-top: 4px

    &-label
      width 180px

    &-value
      max-width 100%
      width auto

  &__middle-field
    display flex
    align-items center

    &-label
      min-width 144px

  &__mini-field
    display flex
    align-items center
    font-size 0.75em

    &-label
      min-width 80px

    &-value
      display flex
      align-items center

  &__product
    $border = 1px solid #555
    border-top $border
    border-bottom $border
    padding 5px
    display flex
    align-items center

    &.--cancelled
      text-decoration line-through

    & + &
      margin 10px 0

  &__middle-field-value
    table
      font-size 0.7em

  &__product-index
    color #555
    font-size 0.75em

  &__product-name
    color #111
    margin-left 10px

  &__product-data
    color #111
    font-size 0.8em
    margin-left auto

  &__product-price
    margin-left auto

  &__notes
    margin-top 16px

  &__note-title
    background #aaa
    font-size 0.75em
    padding 3px 5px
    font-weight bold

  &__note-text
    background #FFFCAB
    padding 3px 5px
    white-space pre-line


.little-grey-text
  color #777
  margin 0
  font-size 0.8em

.save-button
  font-weight bold
  color green

button + button
  margin-left 5px

.grey-box
  background-color #efefef
  border 1px solid #999
  padding 4px
  margin 24px 0

  &__title
    color #97C14E
    font-weight bold
    margin-bottom 5px
    padding-bottom 5px
    border-bottom 1px solid #97C14E

  button
    margin-top 16px

label
  display block

  input
    float right

.--monospaced
  font-family monospace

.--green-text
  color #97C14E

.--green-btn
  display block
  color green
  font-weight bold
  margin 16px 0

.--red-btn
  display block
  color red
  margin 16px 0

.--black-btn
  display block
  color black
  margin 16px 0

table.--logs
  font-family monospace
  font-size 11px

th
  text-align left

</style>
