<template>
  <div class="kkv-resellers">
    <div class="success-box"
         v-if="successBox.show"
         v-html="successBox.text">
    </div>
    <div class="error-box"
         v-if="errorBox.show"
         v-html="errorBox.text">
    </div>
    <table cellspacing="0" cellpadding="0">
      <thead>
      <tr>
        <th>ID</th>
        <th>Name des Wiederverkäufers</th>
        <th>Bearbeiten</th>
        <th>Löschen</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="reseller in resellers" :key="reseller.reseller_id">
        <td>{{ reseller.reseller_id }}</td>
        <td>
          {{ reseller.reseller_name }}
          <div v-if="editResellerId === reseller.reseller_id" class="kkv-resellers__edit-container">
            <input v-model="newValueForReseller" placeholder="Neuer Name" type="text">
            <div>
              <button @click.prevent="editResellerId = null">Zurück</button>
              <button @click.prevent="updateReseller(reseller.reseller_id, newValueForReseller)">Speichern</button>
            </div>
          </div>
        </td>
        <td>
          <button class="kkv-resellers__edit-btn"
                  type="button"
                  @click.prevent="editResellerId = reseller.reseller_id"
          >
            <img src="@/assets/images/edit.png" alt="">
          </button>
        </td>
        <td>
          <button class="kkv-resellers__edit-btn"
                  @click.prevent="deleteReseller(reseller.reseller_id)"
          >
            <img style="width: 15px;" src="@/assets/images/del-icon.png" alt="">
          </button>
        </td>
      </tr>
      </tbody>
    </table>
    <form class="kkv-resellers__create-form" action="" @submit.prevent="createReseller(createId, createName)">
      <h3>Einen Wiederverkäufer anlegen</h3>
      <div>
        <input placeholder="Id" v-model="createId" type="text">
        <input placeholder="Name" v-model="createName" type="text">
      </div>
      <button>Erstellen</button>
    </form>
    <form action="" @submit.prevent="getResellersStatistics">
      <div>
        <span>Monat</span>
        <select v-model="selectMonth" name="">
          <option value="" disabled>Monat</option>
          <option
            v-for="(month, id) in dateCollectionInSelect('months', 'MMMM', startMonth, endMonth)"
            :value="id + 1"
            :key="id"
          >
            {{ month }}
          </option>
        </select>
      </div>
      <div>
        <span>Jahr</span>
        <select v-model="selectYear" name="">
          <option value="" disabled>Jahr</option>
          <option
            v-for="(year, id) in dateCollectionInSelect('years', 'YYYY', startDate, todayDate)"
            :value="year"
            :key="id"
          >
            {{ year }}
          </option>
        </select>
      </div>
      <button>Statistiken anzeigen</button>
    </form>
    <table cellspacing="0" cellpadding="0">
      <thead>
      <tr>
        <th>Name des Wiederverkäufers</th>
        <th>Anzahl der Anfragen</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(value, key) in resellersStatisticsForMonth" :key="key">
        <td>{{ key }}</td>
        <td>{{ value }}</td>
      </tr>
      <tr class="kkv-resellers__notification"
          v-if="showStatistics">
        Keine Anträge von Wiederverkäufern in diesem Monat
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import {handle} from "@/decorators";
import success_error_states from "@/mixins/success_error_states";

const moment = require('moment')

function toString(val) {
  return String(val)
}

function toNumber(val) {
  return Number(val)
}

export default {
  name: "resellers",
  mixins: [success_error_states],
  data() {
    return {
      resellers: [],
      resellersStatisticsForMonth: {},
      startDate: moment('2020-01-01'),
      todayDate: moment(),
      startMonth: moment().startOf('year'),
      endMonth: moment().endOf('year'),
      selectYear: '',
      selectMonth: '',
      newValueForReseller: '',
      showStatistics: false,
      editResellerId: null,
      createId: '',
      createName: ''
    }
  },
  async created() {
    this.resellers = await this.$services.kkv.getResellers()
  },
  methods: {
    dateCollectionInSelect(type, format, startDate, endDate) {
      let dateArray = [];
      const diffDate = endDate.diff(startDate, type)
      for (let i = 0; i <= diffDate; i++) {
        const date = moment(startDate).locale('de').add(i, type).format(format)
        dateArray.push(date)
      }
      return dateArray
    },
    async getResellersStatistics() {
      let resellerStaticsForMonth = await this.$services.kkv.getResellerStatisticsForMonth(toNumber(this.selectYear), toNumber(this.selectMonth))
      console.log(resellerStaticsForMonth)
      let editedByResellerStatisticsForMonth = {}
      for (let reseller in resellerStaticsForMonth) {
        let resellerIdAndName = await this.$services.kkv.getResellerById(toString(reseller))
        editedByResellerStatisticsForMonth[resellerIdAndName.reseller_name] = resellerStaticsForMonth[reseller]
      }
      this.resellersStatisticsForMonth = editedByResellerStatisticsForMonth
      this.showStatistics = Object.keys(this.resellersStatisticsForMonth).length === 0;
    },
    @handle("Wiederverkäufer erfolgreich erneuert")
    async updateReseller(id, name) {
      await this.$services.kkv.updateResellerById(toString(id), toString(name))
      this.editResellerId = null
      this.newValueForReseller = null
      if (!this.errorBox.show) {
        this.resellers = await this.$services.kkv.getResellers()
      }
    },
    @handle('Wiederverkäufer erfolgreich gelöscht')
    async deleteReseller(id) {
      await this.$services.kkv.deleteResellerById(toString(id))
      if (!this.errorBox.show) {
        this.resellers = await this.$services.kkv.getResellers()
      }
    },
    @handle('Wiederverkäufer erfolgreich etabliert')
    async createReseller(id, name) {
      await this.$services.kkv.createResellers(id, name)
      if (!this.errorBox.show) {
        this.resellers = await this.$services.kkv.getResellers()
        this.createName = this.createId = ''
      }
    }
  },
  async mounted() {
    console.log(this.$services.kkv)
  }

}
</script>

<style lang="stylus">
.kkv-resellers
  &__notification
    display block
    padding-left: 6px
    margin-top: 6px

  &__create-form
    width: 50%
    flex-direction column
    align-items flex-start
    h3
      margin: 0
      margin-bottom: 12px
    div
      display flex
    input
      width: 100%

    input + input
      margin-left: 12px

    button
      margin-left: 0 !important
      margin-top: 12px

  &__edit
    &-btn
      padding: 0
      border none
      background none
      cursor pointer

      img
        width: 20px

    &-container
      width: 240px
      position absolute
      border 1px solid
      border-color #c9c9c9
      bottom: -90px
      z-index 1
      padding: 20px
      background white
      box-shadow 0 2px 10px 4px rgba(#000000, 0.2)

      input
        margin-bottom: 12px

      button
        cursor pointer

      button + button
        margin-left: 12px

  table
    width 50%
    margin-top 10px
    font-size 12px
    margin-left: 16px

    thead
      background-color #FFFCAB

    th, td
      text-align left
      padding 5px
      position relative

    tbody
      tr:nth-child(even)
        background-color #cdcdcd

  form
    margin-left: 16px
    margin-top: 32px
    display flex

    span
      margin-right: 8px

    div + div
      margin-left: 8px

    select
      cursor: pointer

    button
      cursor pointer
      margin-left: 16px

</style>
