<template>
  <div class="kkv-promo-codes">
    <div class="success-box"
         v-if="successBox.show"
         v-html="successBox.text">
    </div>
    <div class="error-box"
         v-if="errorBox.show"
         v-html="errorBox.text">
    </div>
    <table cellspacing="0" cellpadding="0">
      <thead>
      <tr>
        <th>ID</th>
        <th>Gutscheincode</th>
        <th>Rabatt</th>
        <th>Bearbeiten</th>
        <th>Löschen</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="promoCode in promoCodes" :key="promoCode.id">
        <td>{{ promoCode.id }}</td>
        <td>
          {{ promoCode.code }}
          <div v-if="editPromoCodeId === promoCode.id" class="kkv-promo-codes__edit-container">
            <input v-model="newValueForPromoCode" placeholder="Neuer Code" type="text">
            <input v-model="newValuePromoCodeDiscount" placeholder="Neuer Rabatt" type="text">
            <div>
              <button @click.prevent="editPromoCodeId = null">Zurück</button>
              <button @click.prevent="updatePromoCode(promoCode.id, newValueForPromoCode, newValuePromoCodeDiscount)">Speichern</button>
            </div>
          </div>
        </td>
        <td>{{ promoCode.discount }}</td>
        <td>
          <button class="kkv-promo-codes__edit-btn"
                  type="button"
                  @click.prevent="editPromoCodeId = promoCode.id"
          >
            <img src="@/assets/images/edit.png" alt="">
          </button>
        </td>
        <td>
          <button class="kkv-promo-codes__edit-btn"
                  @click.prevent="deletePromoCode(promoCode.id)"
          >
            <img style="width: 15px;" src="@/assets/images/del-icon.png" alt="">
          </button>
        </td>
      </tr>
      </tbody>
    </table>
    <form class="kkv-promo-codes__create-form" action="" @submit.prevent="createPromoCode(code, discount)">
      <h3>Einen Wiederverkäufer anlegen</h3>
      <div>
        <input placeholder="Code" v-model="code" type="text">
        <input placeholder="Discount" v-model="discount" type="text">
      </div>
      <button>Erstellen</button>
    </form>
  </div>
</template>

<script>
import {handle} from "@/decorators";
import success_error_states from "@/mixins/success_error_states";

const moment = require('moment')

function toString(val) {
  return String(val)
}
function toInt(val) {
  return parseInt(val)
}
function toFloat(val) {
  return parseFloat(val)
}

export default {
  name: "promoCodes",
  mixins: [success_error_states],
  data() {
    return {
      promoCodes: [],
      editPromoCodeId: null,
      newValueForPromoCode: null,
      newValuePromoCodeDiscount: null,
    }
  },
  async created() {
    this.promoCodes = await this.$services.kkv.getPromoCodes()
  },
  methods: {
    @handle("Gutscheincode erfolgreich erneuert")
    async updatePromoCode(id, code, discount) {
      let data = {
        code: toString(code),
        discount: toFloat(discount)
      }
      await this.$services.kkv.updatePromoCode(toInt(id), data)
      this.editResellerId = null
      this.newValueForPromoCode = null
      if (!this.errorBox.show) {
        this.resellers = await this.$services.kkv.getPromoCodes()
      }
    },
    @handle('Gutscheincode erfolgreich gelöscht')
    async deletePromoCode(id) {
      await this.$services.kkv.deletePromoCode(toInt(id))
      if (!this.errorBox.show) {
        this.resellers = await this.$services.kkv.getPromoCodes()
      }
    },
    @handle('Gutscheincode erfolgreich etabliert')
    async createPromoCode(code, discount) {
      await this.$services.kkv.createPromoCode(code, toFloat(discount))
      if (!this.errorBox.show) {
        this.resellers = await this.$services.kkv.getPromoCodes()
        this.createName = this.createId = ''
      }
    }
  },
  async mounted() {
    console.log(this.$services.kkv)
  }

}
</script>

<style lang="stylus">
.kkv-promo-codes
  &__notification
    display block
    padding-left: 6px
    margin-top: 6px

  &__create-form
    width: 50%
    flex-direction column
    align-items flex-start
    h3
      margin: 0
      margin-bottom: 12px
    div
      display flex
    input
      width: 100%

    input + input
      margin-left: 12px

    button
      margin-left: 0 !important
      margin-top: 12px

  &__edit
    &-btn
      padding: 0
      border none
      background none
      cursor pointer

      img
        width: 20px

    &-container
      width: 240px
      position absolute
      border 1px solid
      border-color #c9c9c9
      bottom: -90px
      z-index 1
      padding: 20px
      background white
      box-shadow 0 2px 10px 4px rgba(#000000, 0.2)

      input
        margin-bottom: 12px

      button
        cursor pointer

      button + button
        margin-left: 12px

  table
    width 50%
    margin-top 10px
    font-size 12px
    margin-left: 16px

    thead
      background-color #FFFCAB

    th, td
      text-align left
      padding 5px
      position relative

    tbody
      tr:nth-child(even)
        background-color #cdcdcd

  form
    margin-left: 16px
    margin-top: 32px
    display flex

    span
      margin-right: 8px

    div + div
      margin-left: 8px

    select
      cursor: pointer

    button
      cursor pointer
      margin-left: 16px

</style>
