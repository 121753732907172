import Vue from 'vue'
import App from './App.vue'
import router from './router.js'
import store from './store'
import plugins from './plugins'
import dateFilter from "@/filters/date.filter";
import VueClipboard from 'vue-clipboard2';

Vue.filter('date', dateFilter)
Vue.use(VueClipboard)


String.prototype.format = function(args) {
    console.log('args', args, 'of', this);
    return this.replace(/{(\w+)}/g, function(match, number) {
        console.log('', match, number);
        return typeof args[number] != 'undefined'
            ? args[number]
            : match;
    });
};


plugins.transport().then(plugin => {

    Vue.use(plugin)

    Vue.config.productionTip = false

    new Vue({
        router,
        store,
        render: h => h(App)
    }).$mount('#app')

})

