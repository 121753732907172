import {ERROR_CODES, LogicError} from "@/errors";

export default {
    async created () {
        const auth_token = localStorage.getItem('auth_token');
        if (!auth_token) {
            await this.$router.push({name: 'login'});
            return;
        }

        try {
            const user = await this.$services.admin.authenticate(auth_token);

            this.$store.commit('userData/SET_USER_DATA', user);
        } catch (err) {
            if (err instanceof LogicError && err.status_code === ERROR_CODES.NOT_AUTHENTICATED) {
                localStorage.removeItem('auth_token');
                await this.$router.push({name: 'login'});
                return;
            }

            throw err;
        }

    },
}
