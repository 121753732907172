<template>
    <div class="kzevb-order">

        <div style="margin-top: 16px">
            <div class="success-box"
                 v-if="successBox.show"
                 v-html="successBox.text">
            </div>
            <div class="error-box"
                 v-if="errorBox.show"
                 v-html="errorBox.text">
            </div>

            <div class="error-box"
                 v-if="leaved">
                You're leaved from this order because other user editing this order
            </div>
            <div class="error-box"
                 v-if="can_bump">
                This order is editing now by other user. Do you want to kick them?
                <button @click="kick">Kick other user</button>
            </div>

            <div class="kzevb-order__actions-panel">
                <button v-if="!editing && !leaved && !can_bump" @click="enableEdit">
                    Bearbeitungsmodus
                </button>

                <button @click="saveEdit" class="save-button" v-if="editing && !leaved && !can_bump">
                    Speichern
                </button>

                <button @click="cancelEdit" v-if="editing && !leaved && !can_bump">
                    Ansichtsmodus
                </button>

                <button @click="goToList">Bestellübersicht</button>
            </div>
        </div>

        <div class="kzevb-order__row" style="color: white; font-weight: bold; font-size: 1.1em">

            <div class="kzevb-order__col --green">
                Stammdaten:
            </div>

            <div class="kzevb-order__col --green">
                Bestellung:
            </div>

            <div class="kzevb-order__col --green">
                Status:
            </div>
        </div>

        <div class="kzevb-order__row">
            <div class="kzevb-order__col">
                <div class="kzevb-order__field">
                    <div class="kzevb-order__field-label">Bestellnummer:</div>
                    <div class="kzevb-order__field-value"><b>{{ order.id }}</b></div>
                </div>

                <div class="kzevb-order__field" v-if="order.payment">
                    <div class="kzevb-order__field-label">Payment ID:</div>
                    <div class="kzevb-order__field-value"><b>{{ order.payment.id }}</b></div>
                </div>

                <div class="kzevb-order__field">
                    <div class="kzevb-order__field-label">Reseller:</div>
                    <div class="kzevb-order__field-value">
                        <b v-if="order.reseller">{{ order.reseller.name }}</b>
                        <b v-else>NONE</b>
                    </div>
                </div>

                <div class="kzevb-order__field">
                    <div class="kzevb-order__field-label">Site:</div>
                    <div class="kzevb-order__field-value">
                      <b>{{ order.site }}</b>
                    </div>
                </div>

                <div class="kzevb-order__field">
                    <div class="kzevb-order__field-label">Datum:</div>
                    <div class="kzevb-order__field-value">{{ prettifyDate(order.date_of_create) }}</div>
                </div>

                <div class="kzevb-order__field">
                    <div class="kzevb-order__field-label">IP:</div>
                    <div class="kzevb-order__field-value">{{ order.ip }}</div>
                </div>

                <div class="kzevb-order__field">
                    <div class="kzevb-order__field-label">Preis:</div>
                    <div class="kzevb-order__field-value">{{ order.summary_price_pretty }} EUR</div>
                </div>

                <div class="kzevb-order__field">
                    <div class="kzevb-order__field-label">Seller:</div>
                    <div class="kzevb-order__field-value">{{ order.sale_throw }}</div>
                </div>

                <div class="kzevb-order__field">
                    <div class="kzevb-order__field-label">Zahlweise:</div>
                    <div class="kzevb-order__field-value">
                        <span v-if="paymentMethod">{{ paymentMethod }}</span>
                        <span v-else>unbekannt</span>
                    </div>
                </div>

                <div style="margin-top: 16px">
                    <b>Kontaktdaten</b>
                </div>

                <div class="kzevb-order__field">
                    <div class="kzevb-order__field-label">E-Mail-Adresse:</div>
                    <div class="kzevb-order__field-value">
                        <a v-if="!editing" :href="'mailto:' + order.contact_email">
                            {{ order.contact_email }}
                        </a>
                        <input type="text" v-if="editing" v-model="editData.contact_email">
                    </div>
                </div>

                <div class="kzevb-order__field">
                    <div class="kzevb-order__field-label">Telefon:</div>
                    <div class="kzevb-order__field-value">
                        <template v-if="!editing">
                            {{ order.contact_phone }}
                        </template>
                        <input type="text" v-if="editing" v-model="editData.contact_phone">
                    </div>
                </div>

                <br>

                <div style="margin-top: 16px">
                    <b>Gültigkeit der eVB</b>
                </div>

                <div class="kzevb-order__field">
                    <div class="kzevb-order__field-label">eVB-Nr:</div>
                    <div class="kzevb-order__field-value">
                        <span>{{ order.insurance_number }}</span>
                        <button v-if="!editing && !leaved && !can_bump" @click="getEvbStatus">?</button>
                    </div>
                </div>

                <div class="kzevb-order__field">
                    <div class="kzevb-order__field-label">eVB gültig:</div>
                    <div class="kzevb-order__field-value">30 Tage</div>
                </div>

                <div class="kzevb-order__field" v-if="order.admitted_from">
                    <div class="kzevb-order__field-label">eVB gültig von:</div>
                    <div class="kzevb-order__field-value">
                        {{ order.admitted_from }}
                    </div>
                </div>

                <div class="kzevb-order__field" v-if="order.admitted_to">
                    <div class="kzevb-order__field-label">eVB gültig bis:</div>
                    <div class="kzevb-order__field-value">
                        {{ order.admitted_to }}
                    </div>
                </div>

                <div class="kzevb-order__field" v-if="order.insurance_number">
                    <div class="kzevb-order__field-label">eVB-Status:</div>
                    <div class="kzevb-order__field-value">
                        <div style="display: flex">
                            <span :style="`color: ${evbStatusColor}; position: relative; top: 5px;`">
                                {{ order.evb_status }}
                            </span>
                            <select v-if="order.can_cancel_evb" v-model="evbStatus" style="margin-left: 8px" @change="changeEVBStatus">
                                <option value="">Aendern</option>
                                <option value="Offen">Offen</option>
                                <option value="Verbraucht">Verfallen</option>
                                <option value="Abgelaufen">Abgelaufen</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="kzevb-order__field" v-if="order.greencard_number">
                    <div class="kzevb-order__field-label">IVK Nr:</div>
                    <div class="kzevb-order__field-value">
                        <span>{{ order.greencard_number }}</span>
                    </div>
                </div>



                <br>

                <div style="margin-top: 16px">
                    <b>Extras</b>
                </div>

                <div class="kzevb-order__field">
                    <div class="kzevb-order__field-label">Mit IVK:</div>
                    <div class="kzevb-order__field-value">
                        <span v-if="order.green_card_included">Ja</span>
                        <span v-else>Nein</span>
                    </div>
                </div>

            </div>
            <div class="kzevb-order__col">

                <div><b>Versicherungsnehmer</b></div>

                <div class="kzevb-order__field">
                    <div class="kzevb-order__field-label">Typ</div>
                    <div class="kzevb-order__field-value">

                        <template v-if="!editing">
                            <template v-if="order.customer_type === 'person'">Natürliche Person / Privat</template>
                            <template v-else>Juristische Person / Firma</template>
                        </template>
                        <select v-if="editing" v-model="editData.customer_type">
                            <option value="person">Natürliche Person / Privat</option>
                            <option value="company">Juristische Person / Firma</option>
                        </select>
                    </div>
                </div>

                <div class="kzevb-order__field" v-if="!editing && order.customer_type === 'person'">
                    <div class="kzevb-order__field-label">Name</div>
                    <div class="kzevb-order__field-value">
                        {{ order.delivery_name }}
                    </div>
                </div>

                <div class="kzevb-order__field" v-if="editing && editData.customer_type === 'person'">
                    <div class="kzevb-order__field-label">Name</div>
                    <div class="kzevb-order__field-value">
                        <input v-model="editData.delivery_name">
                    </div>
                </div>

                <div class="kzevb-order__field" v-if="!editing && order.customer_type === 'person'">
                    <div class="kzevb-order__field-label">Surname</div>
                    <div class="kzevb-order__field-value">
                        {{ order.delivery_surname }}
                    </div>
                </div>

                <div class="kzevb-order__field" v-if="editing && editData.customer_type === 'person'">
                    <div class="kzevb-order__field-label">Surname</div>
                    <div class="kzevb-order__field-value">
                        <input v-model="editData.delivery_surname">
                    </div>
                </div>

                <div class="kzevb-order__field" v-if="!editing && order.customer_type === 'company'">
                    <div class="kzevb-order__field-label">Company</div>
                    <div class="kzevb-order__field-value">
                        {{ order.delivery_company_name }}
                    </div>
                </div>

                <div class="kzevb-order__field" v-if="editing && editData.customer_type === 'company'">
                    <div class="kzevb-order__field-label">Company</div>
                    <div class="kzevb-order__field-value">
                        <input v-model="editData.delivery_company_name">
                    </div>
                </div>

                <div class="kzevb-order__field">
                    <div class="kzevb-order__field-label">Straße + Nr.</div>
                    <div class="kzevb-order__field-value">
                        <template v-if="!editing">
                            {{ addressParts.delivery_street }}
                            {{ addressParts.delivery_house }} |
                            {{ addressParts.delivery_house_letter }}
                        </template>
                        <div style="display: flex">
                            <input v-if="editing" v-model="editData.delivery_street">
                            <input style="width: 30px" v-if="editing" v-model="editData.delivery_house">
                            <input style="width: 30px" v-if="editing" v-model="editData.delivery_house_letter">
                        </div>
                    </div>
                </div>

                <div class="kzevb-order__field">
                    <div class="kzevb-order__field-label">PLZ</div>
                    <div class="kzevb-order__field-value">
                        <template v-if="!editing">
                            {{ order.delivery_postcode }}
                        </template>
                        <input v-if="editing" v-model="editData.delivery_postcode">
                    </div>
                </div>

                <div class="kzevb-order__field">
                    <div class="kzevb-order__field-label">Stadt</div>
                    <div class="kzevb-order__field-value">
                        <template v-if="!editing">
                            {{ order.delivery_state }}
                        </template>
                        <input v-if="editing" v-model="editData.delivery_state">
                    </div>
                </div>

                <div class="kzevb-order__field">
                    <div class="kzevb-order__field-label">Land</div>
                    <div class="kzevb-order__field-value">
                        <template v-if="!editing">
                            {{ order.delivery_country }}
                        </template>
                        <input v-if="editing" v-model="editData.delivery_country">
                    </div>
                </div>

                <div style="margin-top: 16px"><b>Rechnungsempfänger</b></div>

                <div class="kzevb-order__field">
                    <div class="kzevb-order__field-label">Name</div>
                    <div class="kzevb-order__field-value --monospaced" v-if="!order.payment_name">
                        <span v-if="order.delivery_name">
                            {{ order.delivery_name }} {{ order.delivery_surname }} <br>
                        </span>
                        <span v-else>
                            {{ order.delivery_company_name }} <br>
                        </span>
                    </div>
                    <div class="kzevb-order__field-value --monospaced" v-else>
                        {{ order.payment_name }} {{ order.payment_surname }} <br>
                    </div>
                </div>

                <div class="kzevb-order__field">
                    <div class="kzevb-order__field-label">Straße</div>
                    <div class="kzevb-order__field-value --monospaced" v-if="!order.payment_name">
                        {{ order.delivery_street }} <br>
                    </div>
                    <div class="kzevb-order__field-value --monospaced" v-else>
                        {{ order.payment_street }} <br>
                    </div>
                </div>

                <div class="kzevb-order__field">
                    <div class="kzevb-order__field-label">PLZ + Stadt.</div>
                    <div class="kzevb-order__field-value --monospaced" v-if="!order.payment_name">
                        {{ order.delivery_postcode }} {{ order.delivery_state }} <br>
                    </div>
                    <div class="kzevb-order__field-value --monospaced" v-else>
                        {{ order.payment_postcode }} {{ order.payment_state }} <br>
                    </div>
                </div>

                <div class="kzevb-order__field">
                    <div class="kzevb-order__field-label">Land</div>
                    <div class="kzevb-order__field-value --monospaced" v-if="!order.payment_name">
                        {{ order.delivery_country }}
                    </div>
                    <div class="kzevb-order__field-value --monospaced" v-else>
                        {{ order.payment_country }}
                    </div>
                </div>

                <br>

                <div><b>Lieferadresse</b></div>

                <div class="kzevb-order__field">
                    <div class="kzevb-order__field-label">Handy-Nr.</div>
                    <div class="kzevb-order__field-value">{{ order.contact_phone }}</div>
                </div>

                <div class="kzevb-order__field">
                    <div class="kzevb-order__field-label">E-Mail DK</div>
                    <div class="kzevb-order__field-value">{{ order.contact_email }}</div>
                </div>

                <div class="kzevb-order__field">
                    <div class="kzevb-order__field-label">E-Mail Rech.</div>
                    <div class="kzevb-order__field-value">{{ order.contact_email }}</div>
                </div>

                <div class="grey-box" v-if="order.status.paid && !order.status.sent">
                    <div class="grey-box__title">Manuelle eVB-Zuweisung</div>

                    <div class="kzevb-order__middle-field">
                        <div class="kzevb-order__middle-field-label">eVB-Nr.:</div>
                        <div class="kzevb-order__middle-field-value">
                            <input type="text" v-model="customEvb.evbNumber">
                        </div>
                    </div>

                    <div class="kzevb-order__middle-field">
                        <div class="kzevb-order__middle-field-label">IVK Nr.:</div>
                        <div class="kzevb-order__middle-field-value">
                            <input type="text" v-model="customEvb.greenCardNumber">
                        </div>
                    </div>

                    <div class="kzevb-order__middle-field" style="margin-top: 16px">
                        <div class="kzevb-order__middle-field-label">Doppelkarte als PDF:</div>
                        <div class="kzevb-order__middle-field-value">
                            <input type="file" style="display: block" @change="event => customEvb.pdf = event.target.files[0]">
                        </div>
                    </div>

                    <div class="kzevb-order__middle-field">
                        <div class="kzevb-order__middle-field-label"></div>
                        <div class="kzevb-order__middle-field-value">
                            <button v-if="!editing && !leaved && !can_bump" @click="loadCustomEvb">eVB zuweisen</button>
                        </div>
                    </div>
                </div>

            </div>
            <div class="kzevb-order__col">

                <div class="kzevb-order__field">
                    <div class="kzevb-order__field-label">Gesamtstatus:</div>
                    <div class="kzevb-order__field-value" :style="`background-color: ${tdColor(order.status.sent, order.status.cancelled)}`">
                        {{ sendingText(order.status.sent, order.status.cancelled) }}
                    </div>
                </div>

                <div class="kzevb-order__field">
                    <div class="kzevb-order__field-label">Bezahlung:</div>
                    <div class="kzevb-order__field-value" :style="`background-color: ${tdColor(order.status.paid)}`">
                        {{ paymentText(order.status.paid) }}
                    </div>
                </div>

                <div class="kzevb-order__field">
                    <div class="kzevb-order__field-label">Prüfung:</div>
                    <div class="kzevb-order__field-value" :style="`background-color: ${tdColor(order.status.data_checked)}`">
                        {{ checkingText(order.status.data_checked) }}
                    </div>
                </div>

                <br>

                <div class="kzevb-order__field" v-if="order.payment">
                    <div class="kzevb-order__field-label">Payer ID: <br> External payment ID:</div>
                    <div class="kzevb-order__field-value">
                        {{ order.payment.payer_id }} <br> {{ order.payment.external_payment_id }}
                    </div>
                </div>

                <br>

                <div class="kzevb-order__field">
                    <div class="kzevb-order__field-label">eVB zugewiesen:</div>
                    <div class="kzevb-order__field-value">
                        <img src="../../assets/images/time.png">
                        <span v-if="!order.evb_got_datetime">---</span>
                        <span style="color: darkgreen" v-if="order.evb_got_datetime">
                            {{ order.evb_got_datetime }}
                        </span>
                    </div>
                </div>

                <div class="kzevb-order__field">
                    <div class="kzevb-order__field-label">E-Mail Rec. gesendet:</div>
                    <div class="kzevb-order__field-value">
                        <img src="../../assets/images/time.png">
                        <span v-if="!order.email_sent_datetime">---</span>
                        <span style="color: darkgreen" v-if="order.email_sent_datetime">
                            {{ order.email_sent_datetime }}
                        </span>
                    </div>
                </div>

                <div class="kzevb-order__field">
                    <div class="kzevb-order__field-label">SMS eVB gesendet:</div>
                    <div class="kzevb-order__field-value">
                        <img src="../../assets/images/time.png">
                        <span v-if="!order.evb_sms_sent_datetime">---</span>
                        <span style="color: darkgreen" v-if="order.evb_sms_sent_datetime">
                            {{ order.evb_sms_sent_datetime }}
                        </span>
                    </div>
                </div>

                <div class="kzevb-order__field">
                    <div class="kzevb-order__field-label">E-Mail DK gesendet:</div>
                    <div class="kzevb-order__field-value">
                        <img src="../../assets/images/time.png">
                        <span v-if="!order.dk_email_sent_datetime">---</span>
                        <span style="color: darkgreen" v-if="order.dk_email_sent_datetime">
                            {{ order.dk_email_sent_datetime }}
                        </span>
                    </div>
                </div>

                <div class="kzevb-order__field" style="margin-top: 16px">
                    <div class="kzevb-order__field-label">eVB-Nr:</div>
                    <div class="kzevb-order__field-value">
                        <span>{{ order.insurance_number }}</span>
                    </div>
                </div>

                <div class="kzevb-order__field" v-if="order.insurance_number">
                    <div class="kzevb-order__field-label">eVB-Status:</div>
                    <div class="kzevb-order__field-value">
                        <div style="display: flex">
                            <span :style="`color: ${evbStatusColor}`">
                                {{ order.evb_status }}
                            </span>
                        </div>
                    </div>
                </div>

                <div class="kzevb-order__field" v-if="order.greencard_number">
                    <div class="kzevb-order__field-label">IVK Nr:</div>
                    <div class="kzevb-order__field-value">
                        <span>{{ order.greencard_number }}</span>
                    </div>
                </div>

                <div class="kzevb-order__field" v-if="order.insurance_number">
                    <div class="kzevb-order__field-label">PDF Doppelkarte:</div>
                    <div class="kzevb-order__field-value">
                        <a target="_blank" :href="evbPdfUrl">PDF anschauen</a>
                    </div>
                </div>

                <div class="kzevb-order__field" v-if="order.insurance_number">
                    <div class="kzevb-order__field-label">PDF Rechnung:</div>
                    <div class="kzevb-order__field-value">
                        <a target="_blank" :href="invoicePDFUrl">PDF anschauen</a>
                    </div>
                </div>

                <div class="kzevb-order__field" v-if="order.credit_note && order.credit_note.pdf_path">
                    <div class="kzevb-order__field-label">PDF Gutschrift:</div>
                    <div class="kzevb-order__field-value">
                        <a target="_blank" :href="creditNotePDFUrl">PDF anschauen</a>
                    </div>
                </div>

                <button class="--green-btn" v-if="!leaved && !can_bump && !order.status.cancelled && !order.status.paid" @click="setOrderPaid">Als bezahlt markieren</button>

                <button class="--green-btn" v-if="order.status.paid && !order.status.sent && !leaved && !can_bump && !order.status.cancelled && !data_checked" @click="() => { data_checked = true; }">
                    Als geprüft markieren / Autom. Versand starten
                </button>

                <div class="grey-box" v-if="data_checked">
                    <span>Sind alle Daten wirklich korrekt? Sind Sie sich sicher, dass Sie den automatischen Versand starten wollen?</span>
                    <div style="display: flex">
                        <button class="--green-btn" v-if="data_checked" @click="setOrderDataChecked">
                            Ja
                        </button>
                        <button style="margin-left: 8px" class="--black-btn" v-if="data_checked" @click="data_checked = false">
                            Nein
                        </button>
                    </div>
                </div>

                <button class="--black-btn" v-if="!order.status.sent && order.status.paid && !order.status.payment_method" @click="setOrderNotPaid">
                    Als unbezahlt markieren
                </button>

                <button class="--red-btn" v-if="!leaved && !can_bump && !order.status.cancelled && !order.status.sent" @click="setOrderCancelled">
                    Stornieren
                </button>

                <button class="--red-btn" v-if="order.evb_status === 'Abgelaufen' && !order.status.cancelled" @click="generateCreditNote">
                    Stornieren / Gutschrift zusenden
                </button>

                <button class="--green-btn" v-if="!leaved && !can_bump && order.status.cancelled && (!order.credit_note || !order.credit_note.pdf_path)" @click="setOrderRestored">
                    Restore
                </button>

                <div class="kzevb-order__row" style="color: white; font-weight: bold; font-size: 1.1em">
                    <div class="kzevb-order__col --green" style="max-width: 100%">
                        Log:
                    </div>
                </div>

                <table class="--logs">
                    <thead>
                        <tr>
                            <th>Aktion</th>
                            <th>Benutzer</th>
                            <th>Zeitpunkt</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(log, logKey) in order.logs" :key="`log${logKey}`">
                            <td>{{ log.action_text }}</td>
                            <td class="--green-text">{{ log.actor_name }}</td>
                            <td>{{ log.datetime_of_create }}</td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </div>

        <div class="kzevb-order__row" v-if="!editing" style="color: white; font-weight: bold; font-size: 1.1em">

            <div class="kzevb-order__col --green">
                Notizen:
            </div>

            <div class="kzevb-order__col --green">
                Kommunikation:
            </div>

            <div class="kzevb-order__col --green">
                Manueller Versand:
            </div>
        </div>

        <div class="kzevb-order__row" v-if="!editing">
            <div class="kzevb-order__col">
                <select @change="onSelectNoteText">
                    <option disabled selected>Select template</option>
                    <option v-for="note in notes" :value="note">{{ note }}</option>
                </select>
                <textarea style="width: 100%; display: block" rows="3" v-model="noteData.text"></textarea>
                <button v-if="!editing && !leaved && !can_bump" @click="writeANote">Notiz speichern</button>

                <div class="kzevb-order__notes">
                    <div class="kzevb-order__note"
                         v-for="note in order.notes"
                         :key="note.datetime_of_create + 'note'">
                        <div class="kzevb-order__note-title">
                            {{ note.datetime_of_create }}
                            <span style="float: right">{{ note.actor_name }}</span>
                        </div>

                        <div class="kzevb-order__note-text" v-html="note.text"></div>
                    </div>
                </div>
            </div>

            <div class="kzevb-order__col">

                <div class="kzevb-order__middle-field">
                    <div class="kzevb-order__middle-field-label">Template:</div>
                    <div class="kzevb-order__middle-field-value">
                        <select style="width: 192px" @change="selectTemplate">
                            <option value="" selected disabled></option>
                            <option v-for="template in templates"
                                    :key="template + 'option'"
                                    :value="template">
                              {{ template }}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="kzevb-order__middle-field">
                    <div class="kzevb-order__middle-field-label">E-Mail an:</div>
                    <div class="kzevb-order__middle-field-value">
                        <input style="width: 192px" type="text" v-model="emailData.email" />
                    </div>
                </div>

                <div class="kzevb-order__middle-field">
                    <div class="kzevb-order__middle-field-label">Betreff:</div>
                    <div class="kzevb-order__middle-field-value">
                        <input style="width: 192px" type="text" v-model="emailData.subject" />
                    </div>
                </div>

                <p class="little-grey-text">Sehr geehrter Kunde,</p>
                <textarea style="width: 100%" rows="7" v-model="emailData.text"></textarea>
                <p class="little-grey-text">
                    Mit freundlichen Grüßen <br>
                    Das Team von Kurzzeit-eVB.de
                </p>
                <button style="color: #777" v-show="!emailData.active" @click="emailData.active = true;">
                    Senden aktivieren
                </button>
                <button v-show="emailData.active" @click="sendEmail">
                    E-Mail senden
                </button>
            </div>

            <div class="kzevb-order__col">
                <template>

                    <div class="grey-box" v-if="order.insurance_number">
                        <div class="grey-box__title">Manueller Versand der Bestellung</div>

                        <div class="kzevb-order__middle-field">
                            <div class="kzevb-order__middle-field-label">eVB per SMS:</div>
                            <div class="kzevb-order__middle-field-value">
                                <input v-model="sendNotificationContacts.sms" type="text">
                                <button v-if="!leaved && !can_bump" @click="smsEvb">OK</button>
                            </div>
                        </div>

                        <div class="kzevb-order__middle-field">
                            <div class="kzevb-order__middle-field-label">DK per E-Mail:</div>
                            <div class="kzevb-order__middle-field-value">
                                <input v-model="sendNotificationContacts.dkEmail" type="text">
                                <button v-if="!leaved && !can_bump" @click="emailDk">OK</button>
                            </div>
                        </div>

                        <div class="kzevb-order__middle-field">
                            <div class="kzevb-order__middle-field-label">Rec. per E-Mail:</div>
                            <div class="kzevb-order__middle-field-value">
                                <input v-model="sendNotificationContacts.paymentEmail" type="text">
                                <button v-if="!leaved && !can_bump" @click="emailPayment">OK</button>
                            </div>
                        </div>
                    </div>
                   <div class="grey-box" v-else>
                     <div class="grey-box__title">Zahlungslink erneut Senden</div>
                  <div class="kzevb-order__middle-field-label">E-Mail:
                    <span class="kzevb-order__middle-field-value">
                                <input v-model="sendNotificationContacts.dkEmail" type="text">
                                <button v-if="!leaved && !can_bump" @click="resendEmail">OK</button>
                    </span>
                    </div>
                </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import auth_required from "@/mixins/auth_required";
import success_error_states from "@/mixins/success_error_states";
import {handle} from "@/decorators";
import moment from "moment";

const Centrifuge = require("centrifuge");

const defaultEmailSubject = 'Bestellung Nr. A-{id}';

const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});


export default {
    name: "kzevb-order",
    mixins: [auth_required, success_error_states],
    data: () => ({
        order: null,
        evbStatus: '',
        templates: [],
        creditNoteProducts: [],
        notes: [
            'Kunde konnte telefonisch nicht erreicht werden.',
        ],
        trackingLink: '',
        deliveryMethod: 'DHL',
        emailData: {
            email: '',
            subject: '',
            text: '',
            active: false,
        },
        noteData: {
            text: '',
        },
        leaved: false,
        can_bump: false,
        editing: false,
        editData: {
            contact_email: '',
            contact_phone: '',

            customer_type: '',

            delivery_company_name: '',
            delivery_name: '',
            delivery_postcode: '',
            delivery_state: '',
            delivery_street: '',
            delivery_house: '',
            delivery_house_letter: '',
        },
        creditNoteData: {
            cancelOrder: true,
            productsAmount: 0,
            deliveryAmount: 0,
            note: '',
        },
        customEvb: {
            evbNumber: '',
            greenCardNumber: '',
            pdf: null,
        },
        sendNotificationContacts: {
            sms: '',
            dkEmail: '',
            paymentEmail: '',
        },

        data_checked: '',

        centrifugo: null,
        // Centrifugo subs
        kickSub: null,
        updateSub: null,
        can_cancel: false,
    }),
    computed: {
        statusColor() {
            if (!this.order) return '#000';

            return {
                'Warte auf Zahlung': '#555',
                'Zahlung erhalten': 'green',
                'Schilder geprägt': 'green',
                'Versendet': 'white',
                'Storniert': 'red',
            }[this.order.status.text]
        },
        statusBg() {
            if (!this.order) return '#fff';

            return {
                'Warte auf Zahlung': '#fff',
                'Zahlung erhalten': 'white',
                'Schilder geprägt': '#dfdfdf',
                'Versendet': 'green',
                'Storniert': '#fff',
            }[this.order.status.text]
        },
        summaryPrice() {
            let summaryPrice = 0;
            return summaryPrice;
        },
        trackingLinkWithMethod() {
            let baseUrl;
            switch (this.order.status.delivery_method) {
                case 'DHL':
                    baseUrl = 'http://nolp.dhl.de/nextt-online-public/set_identcodes.do?idc=';

                    break;
                case 'UPS':
                    baseUrl = 'https://wwwapps.ups.com/WebTracking/' +
                              'processRequest?HTMLVersion=5.0&Requester=NES&' +
                              'AgreeToTermsAndConditions=yes&loc=de_DE&tracknum=';
                    break;
                case 'GO':
                    baseUrl = 'http://tracking.general-overnight.de/go_tracking_show.php?frachtbriefnummern=';
                    break;
                default:
                    return '';
            }
            return baseUrl + this.order.status.tracking_link;
        },
        invoicePDFUrl() {
            return `/admin_rpc/call_method/?service_name=kzevb&method_name=get_order_invoice&order_id=${this.order.id}`;
        },
        creditNotePDFUrl() {
            return `/admin_rpc/call_method/?service_name=kzevb&method_name=get_order_credit_note&order_id=${this.order.id}`;
        },
        evbPdfUrl() {
            return `/admin_rpc/call_method/?service_name=kzevb&method_name=get_order_pdf&order_id=${this.order.id}`;
        },
        paymentMethod() {
            return {
                sparkassen: "Kreditkarte",
                paypal: "PayPal",
                sofort: "Sofortüberweisung",
                ueberweisung: "Ueberweisung",
            }[this.order.status.payment_method];
        },
        creditNoteProductsData() {
            const result = [];
            return result;
        },
        notCancelledProducts() {
            const result = [];
            for (const product of this.order.products) {
                if (!product.cancelled) result.push(product);
            }
            return result;
        },
        fullCreditNotePrice() {
            const products = this.creditNoteProductsData;
            console.log(products);
            let price = 0;
            for (const product of products) price += product.price;
            return price;
        },
        evbStatusColor() {
            return {
                'Offen': 'grey',
                'Verbraucht': 'green',
                'Abgelaufen': 'orange',
            }[this.order.evb_status]
        },
        addressParts() {
            /**@type{Array}*/const splitName = this.order.delivery_street.split(' ');
            /**@type{string}*/let house = splitName[splitName.length - 1];
            let houseLetter = house.charAt(house.length - 1);
            if (houseLetter.match(/[A-Za-z]/i)) {
                house = house.substring(0, house.length - 1);
            } else {
                houseLetter = '';
            }
            return {
                delivery_street: splitName.splice(0, splitName.length - 1).join(' '),
                delivery_house: house,
                delivery_house_letter: houseLetter,
            }
        },
    },
    methods: {
        @handle("Order restored")
        async restoreOrder() {
            this.order.status = await this.$services.kzevb.restoreOrder(this.order.id);
        },

        @handle("Evb status received")
        async getEvbStatus() {
            const status = await this.$services.kzevb.getEvbStatus(this.order.id);
            alert(`Opened: ${status.opened} \nRegistered: ${status.registered} \nAdmitted: ${status.admitted}`);
        },

        @handle("Credit note generated")
        async generateCreditNote() {
            await this.$services.kzevb.createOrderCreditNote(this.order.id);
            await this.$services.kzevb.generateCreditNotePdf(this.order.id);
        },

        @handle("Order cancelled")
        async setOrderCancelled() {
            this.order.status = await this.$services.kzevb.setOrderCancelled(this.order.id);
        },

        @handle("Order restored")
        async setOrderRestored() {
            this.order.status = await this.$services.kzevb.restoreOrder(this.order.id);
        },

        @handle("Order paid")
        async setOrderPaid() {
            this.order.status = await this.$services.kzevb.setOrderPaid(this.order.id);
        },

        @handle("Order not paid")
        async setOrderNotPaid() {
            this.order.status = await this.$services.kzevb.setOrderNotPaid(this.order.id);
        },

        @handle("EVB status changed")
        async changeEVBStatus() {
            console.log(this.evbStatus)
            if (this.evbStatus) {
                await this.$services.kzevb.setEvbStatus(this.order.id, this.evbStatus);
                this.order.evb_status = this.evbStatus;
                this.evbStatus = '';
            }
        },
        @handle("Email sent")
        async resendEmail() {
        await this.$services.kzevb.resendEmail(this.order.id)
        },

        @handle("Order insurance successful issued")
        async setOrderDataChecked() {
            const response = await this.$services.kzevb.setOrderDataChecked(this.order.id);
            if (response.error) {
                throw new Error(response.error);
            } else {
                this.order.status = response;
            }
        },

        @handle("Order sent")
        async setOrderSent() {
            this.order.status = await this.$services.kzevb.setOrderSent(
                this.order.id, this.trackingLink, this.deliveryMethod);
        },

        async selectTemplate(event) {
            const templateName = event.target.value;
            this.emailData.text = await this.$services.akv.getEmailTemplate(templateName);
            this.emailData.subject = defaultEmailSubject.format(this.order);
        },

        @handle("Email sent")
        async sendEmail() {
            await this.$services.kzevb.sendEmail(
                this.order.id, this.emailData.email, this.emailData.subject,
                this.emailData.text);

            this.clearEmailFields();
        },

        clearEmailFields() {
            this.emailData.subject = defaultEmailSubject.format(this.order);
            this.emailData.text = '';
            this.emailData.active = false;
        },

        async onSelectNoteText(event) {
            this.noteData.text = event.target.value;
        },

        @handle("Note created")
        async writeANote() {
            const note = await this.$services.kzevb.writeANote(
                this.order.id, this.noteData.text
            );

            this.order.notes.push(note);

            this.noteData.text = '';
        },

        @handle("EVB successfully loaded")
        async loadCustomEvb() {
            let fileBase64 = null;
            if (this.customEvb.pdf) {
                fileBase64 = await toBase64(this.customEvb.pdf);
            }
            if (fileBase64) {
              await this.$services.kzevb.setOrderEvb(
                this.order.id, this.customEvb.evbNumber, this.customEvb.greenCardNumber, fileBase64,
              );
            } else {
              await this.$services.kzevb.setOrderEvb(
                this.order.id, this.customEvb.evbNumber, this.customEvb.greenCardNumber,
              );
            }
        },

        enableEdit() {
            this.editData.contact_email = this.order.contact_email;
            this.editData.contact_phone = this.order.contact_phone;

            this.editData.customer_type = this.order.customer_type;

            this.editData.delivery_company_name = this.order.delivery_company_name;
            this.editData.delivery_name = this.order.delivery_name;
            this.editData.delivery_surname = this.order.delivery_surname;
            this.editData.delivery_postcode = this.order.delivery_postcode;
            this.editData.delivery_state = this.order.delivery_state;

            /**@type{Array}*/const splitName = this.order.delivery_street.split(' ');
            /**@type{string}*/let house = splitName[splitName.length - 1];
            let houseLetter = house.charAt(house.length - 1);
            if (houseLetter.match(/[A-Za-z]/i)) {
                house = house.substring(0, house.length - 1);
            } else {
                houseLetter = '';
            }
            this.editData.delivery_street = splitName.splice(0, splitName.length - 1).join(' ');
            this.editData.delivery_house = house;
            this.editData.delivery_house_letter = houseLetter;
            this.editData.delivery_country = this.order.delivery_country;

            this.editing = true;
        },

        @handle("Order data successfully changed")
        async saveEdit() {
            this.editData.delivery_street = this.editData.delivery_street + ' ' +
                this.editData.delivery_house +
                this.editData.delivery_house_letter;

            await this.$services.kzevb.updateOrder(this.order.id, this.editData);

            this.order.contact_email = this.editData.contact_email;
            this.order.contact_phone = this.editData.contact_phone;

            this.order.customer_type = this.editData.customer_type;

            this.order.delivery_company_name = this.editData.delivery_company_name;
            this.order.delivery_name = this.editData.delivery_name;
            this.order.delivery_surname = this.editData.delivery_surname;
            this.order.delivery_postcode = this.editData.delivery_postcode;
            this.order.delivery_state = this.editData.delivery_state;
            this.order.delivery_street = this.editData.delivery_street;
            this.order.delivery_country = this.editData.delivery_country;

            this.editing = false;
        },

        cancelEdit() {
            this.editing = false;
        },

        goToList() {
            this.$router.push({ name: 'kzevb_orders' });
        },

        formatDate(/** @type {string} */ dateString) {
            const date = new Date(dateString);
            const year = date.getFullYear();
            let month = date.getMonth() + 1;
            if (month < 10) month = '0' + month;
            const day = date.getDate();

            return `${year}-${month}-${day}`;
        },

        prettifyDate(date) {
            return (new Date(date)).toLocaleString();
        },

        presentPdf() {
            if (this.order.pdf_url.startsWith('http')) {
                return this.order.pdf_url;
            }

            return '/media/kzevb/' + this.order.pdf_url;
        },

        @handle('Credit note was created')
        async createCreditNote() {
            const { credit_note, note, status } = await this.$services.kzevb.createCreditNote(
                this.order.id,
                this.creditNoteData.cancelOrder,
                this.creditNoteProducts,
                this.creditNoteData.note,
            );
            this.order.credit_notes.push(credit_note);
            this.order.notes.push(note);
            this.order.status = status;

            this.creditNoteData.productsAmount = 0;
            this.creditNoteData.deliveryAmount = 0;
            this.creditNoteData.note = '';

            this.creditNoteProducts = [];
        },

        tdColor(val, v1) {
            if (v1) return '#FF9ABE';
            if (val) return '#6DF26D';
            return '#E9F97A';
        },
        sendingText(val, v1) {
            if (v1) return 'Storniert';
            if (val) return 'Versendet';
            return 'Unbearbeitet';
        },
        paymentText(val) {
            if (val) return 'Bezahlt';
            return 'Unbezahlt';
        },
        checkingText(val) {
            if (val) return 'Geprüft';
            return 'Ungeprüft';
        },

        @handle('smsEvb successfully sent')
        async smsEvb() {
            await this.$services.kzevb.smsEvb(this.order.id, this.sendNotificationContacts.sms);
        },

        @handle('emailDk successfully sent')
        async emailDk() {
            await this.$services.kzevb.emailDk(this.order.id, this.sendNotificationContacts.emailDk);
        },

        @handle('emailPayment successfully sent')
        async emailPayment() {
            await this.$services.kzevb.emailPayment(this.order.id, this.sendNotificationContacts.emailPayment);
        },

        @handle('Order edittor successfully kicked')
        async kick() {
            await this.$services.admin.kick(this.order.id, this.$store.state.userData.username);
            this.leaved = false;
            this.can_bump = false;
        },
    },
    async mounted() {
        const orderId = Number(this.$route.params.id);
        this.order = await this.$services.kzevb.getOrderById(orderId);
        if (this.order.error) {
            this.handleError(new Error(this.order.error));
        }
        this.templates = await this.$services.kzevb.getEmailTemplates()
        this.emailData.email = this.order.contact_email;
        this.sendNotificationContacts.dkEmail = this.order.contact_email;
        this.sendNotificationContacts.paymentEmail = this.order.contact_email;
        this.sendNotificationContacts.sms = this.order.contact_phone;

        const openResult = await this.$services.admin.startToEditOrder(orderId);

        if (!openResult.success) {
            this.can_bump = true;
        }
    },
    async created() {
        const orderId = Number(this.$route.params.id);

        this.centrifuge = new Centrifuge('wss://neueadmin.gva24.de/msgs/connection/websocket');
        this.centrifuge.on('disconnect', function(ctx){
            console.log('Centrifugo disconnected: ' + ctx.reason);
        });

        this.kickSub = this.centrifuge.subscribe(`kzevb_order_user_kick__order=${orderId}`, (message) => {
            const data = message.data;
            const { order_id, user_leave } = data;
            const username = this.$store.state.userData.username;
            if (order_id === orderId && username === user_leave) {
                this.leaved = true;
                this.can_bump = true;
            }
        });

        this.updateSub = this.centrifuge.subscribe(`kzevb_order_updated`, (message) => {
            const data = message.data;
            if (this.order.id === data.id) {
                this.order = data;
            }
            console.log(data);
        });

        this.centrifuge.connect();
    },
    destroyed() {
        this.kickSub.unsubscribe();
        this.updateSub.unsubscribe();
        this.centrifuge.disconnect();
    },
}
</script>

<style lang="stylus" scoped>
.kzevb-order
    padding 0 10px

    &__actions-panel
        background-color #afafaf
        display flex
        margin-top 10px
        padding 4px 15px
        justify-content flex-end
        margin-bottom 8px

    &__row
        display flex

    &__col
        max-width 33.3333%
        width 100%
        padding 3px

        &.--green
            background-color #97C14E

        select, textarea
            display block
            width 100%

    &__logs
        width 100%
        font-size 0.75em

        tr:nth-child(odd)
            background #f9f9f9

        tr:nth-child(even)
            background #ebebeb

    &__field
        display flex

        &-label
            width 180px

        &-value
            max-width 100%
            width auto

    &__middle-field
        display flex
        align-items center

        &-label
            min-width 144px

    &__mini-field
        display flex
        align-items center
        font-size 0.75em

        &-label
            min-width 80px

        &-value
            display flex
            align-items center

    &__product
        $border = 1px solid #555
        border-top $border
        border-bottom $border
        padding 5px
        display flex
        align-items center

        &.--cancelled
            text-decoration line-through

        & + &
          margin 10px 0

    &__middle-field-value
        table
            font-size 0.7em

    &__product-index
        color #555
        font-size 0.75em

    &__product-name
        color #111
        margin-left 10px

    &__product-data
        color #111
        font-size 0.8em
        margin-left auto

    &__product-price
        margin-left auto

    &__notes
        margin-top 16px

    &__note-title
        background #aaa
        font-size 0.75em
        padding 3px 5px
        font-weight bold

    &__note-text
        background #FFFCAB
        padding 3px 5px
        white-space pre-line


.little-grey-text
    color #777
    margin 0
    font-size 0.8em

.save-button
    font-weight bold
    color green

button + button
    margin-left 5px

.grey-box
    background-color #efefef
    border 1px solid #999
    padding 4px
    margin 24px 0

    &__title
        color #97C14E
        font-weight bold
        margin-bottom 5px
        padding-bottom 5px
        border-bottom 1px solid #97C14E

    button
        margin-top 16px

label
    display block
    input
        float right

.--monospaced
    font-family monospace

.--green-text
    color #97C14E

.--green-btn
    display block
    color green
    font-weight bold
    margin 16px 0

.--red-btn
    display block
    color red
    margin 16px 0

.--black-btn
    display block
    color black
    margin 16px 0

table.--logs
    font-family monospace
    font-size 11px

    th
        text-align left

</style>
