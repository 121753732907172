
const state = () => ({
    username: null,
    fullName: null,
    status: null,
    rights: null,
});


const mutations = {
    SET_USER_DATA(state, { username, fullName, status, rights }) {
        state.username = username;
        state.fullName = fullName;
        state.status = status;
        state.rights = rights;
    }
};


const actions = {
};

export default { namespaced: true, state, mutations, actions, }
