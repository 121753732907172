<template>
    <div class="akv-prices">

        <div style="margin-top: 16px">
            <div class="success-box"
                 v-if="successBox.show"
                 v-html="successBox.text">
            </div>
            <div class="error-box"
                 v-if="errorBox.show"
                 v-html="errorBox.text">
            </div>
        </div>

        <table>
            <tr>
                <td>Einzeiliges Europa-Kennzeichen</td>
                <td>
                    <input type="number" step=".01" v-model.number="prices.LICENCE_PLATE" />
                </td>
            </tr>
            <tr>
                <td>WÜRTH-Kennzeichenhalter</td>
                <td>
                    <input type="number" step=".01" v-model.number="prices.PLATE_HOLDER" />
                </td>
            </tr>
            <tr>
                <td>Versandkosten (Standard)</td>
                <td>
                    <input type="number" step=".01" v-model.number="prices.STANDARD_DELIVERY" />
                </td>
            </tr>
            <tr>
                <td>Versandkosten (Express)</td>
                <td>
                    <input type="number" step=".01" v-model.number="prices.EXPRESS_DELIVERY" />
                </td>
            </tr>
            <tr>
                <td>MWST</td>
                <td>
                    <input style="width: 40px" min="0" max="100"
                           type="number" step="1" v-model.number="prices.MWST" />
                    %
                </td>
            </tr>
        </table>

        <div>
            <button @click="save">Save</button>
        </div>
    </div>
</template>

<script>

import auth_required from "@/mixins/auth_required";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import success_error_states from "@/mixins/success_error_states";
import {handle} from "@/decorators";

export default {
    name: "prices",
    mixins: [auth_required, success_error_states],
    data: () => ({
        prices: {},
    }),
    computed: {
    },
    methods: {
        @handle("AkvPrices are saved")
        async save() {
            await this.$services.akv.updatePrices(this.prices)
        }
    },
    async mounted() {
        this.prices = await this.$services.akv.getPrices();
    },
    components: { DatePicker },
}

</script>

<style lang="stylus" scoped>
    .akv-prices
        padding 10px

        &__result
            margin-top 15px

    button
        height 34px
        width 60px
        font-weight bold
</style>
