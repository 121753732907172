<template>
    <div class="kzevb-prices">
        <table cellpadding="0" cellspacing="0">
            <thead>
                <tr>
                    <td>BID</td>
                    <td>Aktion</td>
                    <td>Benutzer</td>
                    <td>Zeitpunkt</td>
                </tr>
            </thead>

            <tbody>
                <tr v-for="(log, logKey) in logs"
                    :key="`log${logKey}Row`" @click="onIdClick(log.order_id)">
                    <td>
                      <a href="#">{{ log.order_id }}</a>
                    </td>
                    <td>{{ log.action_text }}</td>
                    <td>{{ log.actor_name }}</td>
                    <td>{{ log.datetime_of_create }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>

import auth_required from "@/mixins/auth_required";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import success_error_states from "@/mixins/success_error_states";
import {handle} from "@/decorators";

export default {
    name: "kzevb_logs",
    mixins: [auth_required, success_error_states],
    data: () => ({
        logs: [],
    }),
    computed: {
    },
    methods: {
        onIdClick(id) {
            this.$router.push({name: 'kzevb_order', params: { id }})
        }
    },
    async mounted() {
        this.logs = await this.$services.kzevb.getAllLogs();
    },
}

</script>

<style lang="stylus" scoped>
    .kzevb-prices
        padding 10px

        &__result
            margin-top 15px

    button
        height 34px
        width 60px
        font-weight bold

    table
        width 100%

        thead tr
            background grey
            font-weight bold

        td
            padding 4px
</style>
