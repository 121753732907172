<template>
    <div class="login">
        <div class="login__title">Adminserver - Login</div>
        <div class="login__body">
            <img alt="SVA logo" src="../assets/images/sva_logo.png">
            <div class="login__form-message" v-if="formMessage.show">
                <div class="login__form-message-text">{{ formMessage.text }}</div>

                <div class="login__form-message-note">
                    Bitte wenden Sie sich telefonisch an Herrn Kloke, da sich
                    Ihre Zugangsdaten ggf. geändert haben.
                    <br><br>
                    <b>Tel. 02421 / 3951728</b>
                </div>

                <a href="#" class="login__form-message-back-button"
                   @click="formMessage.show = false">
                    Zurück
                </a>
            </div>
            <div v-else>
                <div class="login__field">
                    <label for="username">Benutzername:</label>
                    <input type="text" id="username" v-model="username" />
                </div>
                <div class="login__field">
                    <label for="password">Passwort:</label>
                    <input type="password" id="password" v-model="password" />
                </div>
                <button @click="login">Anmelden</button>
            </div>
        </div>
    </div>
</template>

<script>
import no_auth_required from "@/mixins/no_auth_required";
import {GetErrorMessage} from "@/errors";

export default {
    name: 'Login',
    mixins: [no_auth_required],
    data: () => ({
        username: '',
        password: '',
        formMessage: {
            text: null,
            show: false,
        },
    }),
    methods: {
        async login() {
            try {
                const auth_token = await this.$services.admin.authorizeUser(
                    this.username, this.password
                );

                localStorage.setItem('auth_token', auth_token);

                await this.$router.push({
                    name: 'home',
                });
            } catch (err) {
                this.handleError(err);
            }
        },
        handleError(error) {
            const message = GetErrorMessage(error);
            this.formMessage.show = true;
            this.formMessage.text = message;
        }
    },
}
</script>

<style lang="stylus">
.login
    border 1px solid #D80028
    max-width 600px
    margin auto
    &__title
        color #fefefe
        background-color #D80028
        font-size 1.3em
        text-align center
    &__field
        display flex
        flex-direction row
        justify-content flex-end
        width auto
        margin 16px auto
        max-width 300px
        label
            margin-right 16px
    &__body
        padding 16px

        img
            margin auto
            display block
    &__form-message
        &-text
            color red
            font-size 1.2em
            margin-top 16px
        &-note
            border 1px solid grey
            padding 16px
            background-color #fafafa
            margin-top 16px
        &-back-button
            margin-top 16px
            display block
    button
        margin auto
        display block
</style>
