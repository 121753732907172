const Centrifuge = require("centrifuge");


export default {
    data: () => ({
        centrifuge: null,
        subs: {},
    }),
    methods: {
        subscribe(data) {
            const subs = {};
            for (const key in data) {
                subs[key] = this.centrifuge.subscribe(data.event, data.callback);
            }
            this.subs = subs;
            console.log(this.subs)
        },
        showNotification(title, text) {
            this.notification = { title, text };
            this.$refs.notification.play()
        },
    },
    created() {
        this.centrifuge = new Centrifuge(process.env.VUE_APP_CENT_URL);
        this.centrifuge.on('disconnect', function(ctx){
            console.log('Centrifugo disconnected: ' + ctx.reason);
        });

        this.centrifuge.subscribe(`kzevb_order_failed`, (message) => {
            const data = message.data;
            this.showNotification(
                `Order KZEVB-${data.id} failed`,
                `EVB provider returned error. Reason: ${data.error}`,
            )
            console.log("Failed!", data);
        });

        this.centrifuge.connect();
    },
    destroyed() {
        this.centrifuge.disconnect();
    }
}
