<template>
    <div class="akv-orders">
        <div class="akv-orders__filter-row">
            <div>
                <input type="text" placeholder="Suche" v-model="searchInputText"
                       @change="getOrders">
                <span style="margin-left: 5px">(email, phone, company/person name)</span>
            </div>

            <div>
                Filter:
                <select v-model="filter" @change="getOrders">
                    <option value="all">Alle</option>
                    <option value="all_besides_cancelled">Alle außer stornierte</option>
                    <option value="sent">Versendet</option>
                    <option value="paid">Zahlung erhalten</option>
                    <option value="not_paid">Warte auf Zahlung</option>
                    <option value="data_checked">Geprägte Schilder</option>
              </select>
            </div>

            <div style="display: flex; align-items: center">
                <div>
                    Limit:
                    <input type="number" style="width: 40px; padding: 0" v-model="limit">
                </div>

                <div class="pagination">
                    <div v-for="(page, index) in pagesCount">
                        <a v-if="currentPage !== index" href="#" @click="setPage(index)">{{ index }}</a>
                        <a v-else>{{ index }}</a>
                    </div>
                </div>
            </div>
        </div>

        <loader v-if="loading" style="margin: auto" />

        <table v-if="!loading" cellspacing="0" cellpadding="0">
            <thead>
                <tr>
                    <th>BID</th>
                    <th>Datum</th>
                    <th>Name</th>
                    <th>Anz. Kennzeichen</th>
                    <th>Zahlungsweise</th>
                    <th>Versandart</th>
                    <th>Reservierung</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(order, orderKey) in orders"
                    @click="onRowClick(order.id)"
                    :key="`order${orderKey}Row`">
                    <td><b>{{ order.id }}</b></td>
                    <td>{{ order.date_of_create }}</td>
                    <td>{{ order.delivery_name }}</td>
                    <td>{{ order.plates_count }}</td>
                    <td>
                        <img v-if="order.status.payment_method === 'sparkassen'"
                             src="../../assets/images/icon_mini_kreditkarte.png" />
                        <img v-if="order.status.payment_method === 'paypal'"
                             src="../../assets/images/icon_mini_paypal.png" />
                        <img v-if="order.status.payment_method === 'sofort'"
                             src="../../assets/images/icon_mini_sofortueberweisung.png" />

                        {{ paymentMethod(order.status.payment_method) }}
                    </td>
                    <td>
                        <span v-if="order.express_delivery" style="color: red">Express</span>
                        <span v-else style="color: blue">Standard</span>
                    </td>
                    <td>-</td>
                    <td :style="`color: ${statusColor(order.status_text)}; background: ${statusBg(order.status_text)};`">
                        {{ order.status_text }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import auth_required from "@/mixins/auth_required";
import success_error_states from "@/mixins/success_error_states";
import loader from "@/components/loader";

export default {
    name: "orders",
    mixins: [auth_required, success_error_states],
    data: () => ({
        loading: false,
        orders: [],
        searchInputText: '',
        filter: 'all',
        limit: 30,
        offset: 0,
        count: 0,
    }),
    computed: {
        pagesCount() {
            return Math.ceil(this.count / this.limit);
        },
        currentPage() {
            return (this.offset / this.limit);
        },
    },
    methods: {
        statusColor(statusText) {
            return {
                'Warte auf Zahlung': '#555',
                'Zahlung erhalten': 'green',
                'Schilder geprägt': 'green',
                'Versendet': 'white',
                'Storniert': 'red',
            }[statusText]
        },
        statusBg(statusText) {
            return {
                'Warte auf Zahlung': '#fff',
                'Zahlung erhalten': 'transparent',
                'Schilder geprägt': '#dfdfdf',
                'Versendet': 'green',
                'Storniert': '#fff',
            }[statusText]
        },
        onRowClick(id) {
            this.$router.push({
                name: 'order',
                params: { id },
            })
        },
        async getOrders() {
            this.loading = true;

            const ordersData = await this.$services.akv.getOrderList(
                this.limit, this.offset, this.searchInputText, this.filter);

            this.count = ordersData.count;
            this.orders = ordersData.data;

            this.loading = false;
        },
        setPage(page) {
            this.offset = this.limit * page;
            this.getOrders();
        },
        paymentMethodImage(method) {
            return {
                sparkassen: "../../assets/images/icon_mini_kreditkarte.png",
                paypal: "../../assets/images/icon_mini_paypal.png",
                sofort: "../../assets/images/icon_mini_sofortueberweisung.png",
                ueberweisung: "",
            }[method];
        },
        paymentMethod(method) {
            return {
                sparkassen: "Kreditkarte",
                paypal: "PayPal",
                sofort: "Sofortüberweisung",
                ueberweisung: "Ueberweisung",
            }[method];
        },
    },
    async created() {
        await this.getOrders();
    },
    components: { loader },
}
</script>

<style lang="stylus" scoped>
.akv-orders
    padding 0 10px

    &__filter-row
        background-color #dfdfdf
        display flex
        margin-top 10px
        padding 4px 15px
        align-items center
        font-size 0.8em
        justify-content space-between

    table
        width 100%
        margin-top 10px

        thead
            background-color #FFFCAB

        th, td
            text-align left
            padding 5px

        tbody
            tr:hover
                background-color #FFFCAB
                cursor pointer

    .pagination
        display flex
        font-size 14px
        margin 0

        & > div
            margin-left 5px

</style>
