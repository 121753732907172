<template>
    <main-layout>
        <div class="home">
            <p>Herzlich willkommen auf unserem Adminserver!</p>
            <p>Bitte wählen Sie ein Modul aus der oberen Menüleiste.</p>
        </div>
    </main-layout>
</template>

<script>
import MainLayout from '@/layouts/main-layout'
import auth_required from "@/mixins/auth_required";

export default {
    name: "home",
    mixins: [auth_required],
    components: {
        MainLayout
    },
}
</script>

<style lang="stylus" scoped>
    .home
        padding 0 8px
</style>
