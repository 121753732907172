<template>
    <div class="akv-site-content">

        <div class="akv-site-content__title">Inhalt der Website</div>

        <div style="margin-top: 16px">
            <div class="success-box"
                 v-if="successBox.show"
                 v-html="successBox.text">
            </div>
            <div class="error-box"
                 v-if="errorBox.show"
                 v-html="errorBox.text">
            </div>
        </div>

        <button class="save-button" @click="save">Save</button>

        <div class="akv-site-content__language">
            Sprache zum Übersetzen:
            <select v-model="language" class="akv-site-content__language-select">
                <option v-for="(verb, key) in languages"
                        :key="`lang${key}`" :value="key">
                    {{ verb }}
                </option>
            </select>
        </div>

        <div v-if="siteContents" class="akv-site-content__menu">

            <a href="#" v-for="(tl, section) in content" :key="`section${section}`"
               :class="sectionClass(section)" @click="setSection(section)">{{ section }}</a>
        </div>

        <table v-if="siteContents">
            <thead>
                <tr>
                    <th>Marker</th>
                    <th>Deutsch original:</th>
                    <th>{{ languages[language] }}</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(text, name) in currentSectionContent" :key="`name${name}`">
                    <th>{{ name }}</th>
                    <th>
                        <textarea disabled :value="defaultSectionContent(name)" cols="50" rows="10"></textarea>
                    </th>
                    <th>
                        <textarea @input="setText(name, $event)" :value="currentSectionContent[name]" cols="50" rows="10"></textarea>
                    </th>
                    <th></th>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>

import {handle} from "@/decorators";
import auth_required from "@/mixins/auth_required";
import success_error_states from "@/mixins/success_error_states";

const LANGUAGES = {
    DE: 'Deutsch / Deutsch',
    RU: 'Russisch / Русский',
    EN: 'Englisch / English',
}


export default {
    name: "site-content",
    mixins: [auth_required, success_error_states],
    data: () => ({
        language: 'DE',
        languages: LANGUAGES,
        siteContents: null,
        currentSection: null,
    }),
    computed: {
        content() {
            if (!this.siteContents) return null;

            return this.siteContents[this.language];
        },
        currentSectionContent() {
            if (!this.siteContents) return null;

            console.log(this.currentSection)

            return this.content[this.currentSection];
        },
    },
    methods: {
        sectionClass(section) {
            if (section === this.currentSection) return 'active';
            return '';
        },
        defaultSectionContent(name) {
            return this.siteContents.DE[this.currentSection][name];
        },
        setSection(section) {
            console.log(this.siteContents);
            this.currentSection = section;
            this.$forceUpdate();
            console.log(this.currentSection);
            console.log(this.content);
            console.log(this.currentSectionContent);
        },
        @handle("Site contents were saved!")
        async save() {
            await this.$services.akv.updateSiteContents(this.siteContents);
        },
        setText(name, event) {
            this.siteContents[this.language][this.currentSection][name] = event.target.value;
            this.$forceUpdate();
        },
    },
    async mounted() {
        this.siteContents = await this.$services.akv.getSiteContents();
        console.log(this.siteContents);
        if (!this.currentSection) {
            this.currentSection = Object.keys(this.siteContents[this.language])[0];;
        }
    }
}

</script>

<style lang="stylus" scoped>
    .akv-site-content
        padding 10px

        &__title
            padding 2px
            background #aaa
            font-weight bold
            color white
            margin-bottom 30px

        &__menu
            font-size 22px
            display flex
            justify-content space-between
            flex-wrap wrap
            margin-bottom 30px

            a
                color #222 !important
                display block
                font-weight bold
                text-decoration underline

        &__language
            margin 30px auto
            font-size 1.1em
            text-align center

        &__language-select
            font-size 1.5em
            text-align center
            width 400px
            display block
            margin 5px auto



        table, thead, tbody, tr
            width 100%
            text-align left

        thead
            tr
                background #FFFCAB

        th:first-child
            width 200px

    .save-button
        display block
        position fixed
        right 20px
        bottom 20px
        font-size 22px
        padding 5px 10px
</style>
