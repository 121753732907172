<template>
    <div class="kzevb-prices">

        <div style="margin-top: 16px">
            <div class="success-box"
                 v-if="successBox.show"
                 v-html="successBox.text">
            </div>
            <div class="error-box"
                 v-if="errorBox.show"
                 v-html="errorBox.text">
            </div>
        </div>

        <div class="form">
            <div class="row">
                <span>Typ:</span>
                <select>
                    <option selected>NOTIZ</option>
                </select>
            </div>
            <div class="row">
                <span>Hinweis:</span>
                <textarea cols="40" rows="10" v-model="newAd.text"></textarea>
            </div>
            <div class="row">
                <span>Anzeigen von:</span>
                <input v-model="newAd.dateFrom" placeholder="dd.mm.YY hh:mm:ss" />
            </div>
            <div class="row">
                <span>Anzeigen bis:</span>
                <input v-model="newAd.dateTo" placeholder="dd.mm.YY hh:mm:ss" />
            </div>
            <button @click="createNewAd">Speichern</button>
        </div>


        <table cellpadding="0" cellspacing="0">
            <thead>
                <tr>
                    <td>Hinzugefügt</td>
                    <td>Typ</td>
                    <td>Anzeige von</td>
                    <td>Anzeige bis</td>
                    <td>Hinweistext</td>
                    <td>Aktion</td>
                </tr>
            </thead>

            <tbody>
                <tr v-for="(ad, adKey) in ads"
                    :key="`ad${adKey}Row`"
                >
                    <td>{{ ad.datetime_of_create }}</td>
                    <td>NOTIZ</td>
                    <td>{{ ad.date_from }}</td>
                    <td>{{ ad.date_to }}</td>
                    <td>{{ ad.text }}</td>
                    <td>
                        <a href="#" @click="removeAd(ad.id)">Löschen</a>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>

import auth_required from "@/mixins/auth_required";
import 'vue2-datepicker/index.css';
import success_error_states from "@/mixins/success_error_states";
import {handle} from "@/decorators";

function getDate(high) {
    const now = new Date();
    let day = now.getDate();
    if (day < 10) {
        day = '0' + day;
    }
    let month = now.getMonth() + 1;
    if (month < 10) {
        month = '0' + month;
    }
    let hours = now.getHours();
    if (!high) {
        hours = '00';
    } else {
        hours = '23';
    }
    let minutes = now.getMinutes();
    if (!high) {
        minutes = '00';
    } else {
        minutes = '59';
    }
    let seconds = now.getSeconds();
    if (!high) {
        seconds = '00';
    } else {
        seconds = '59';
    }
    return `${day}.${month}.${now.getFullYear()} ${hours}:${minutes}:${seconds}`;
}

export default {
    name: "kzevb_ads",
    mixins: [auth_required, success_error_states],
    data: () => ({
        ads: [],
        newAd: {
            text: '',
            dateFrom: getDate(),
            dateTo: getDate(true),
        },
    }),
    computed: {
    },
    methods: {
        @handle('Ad successfully created')
        async createNewAd() {
            const ad = await this.$services.kzevb.createAd(this.newAd.dateFrom, this.newAd.dateTo, this.newAd.text);
            this.ads = [ad, ...this.ads];
        },

        @handle('Ad successfully removed')
        async removeAd(id) {
            await this.$services.kzevb.removeAd(id);
            const index = this.ads.findIndex(value => value.id === id);
            this.ads.splice(index, 1);
        }
    },
    async mounted() {
        this.ads = await this.$services.kzevb.getAllAds();
    },
}

</script>

<style lang="stylus" scoped>
    .kzevb-prices
        padding 10px

        &__result
            margin-top 15px

    button
        height 34px
        font-weight bold

    table
        width 100%
        margin-top 32px

        thead tr
            background grey
            font-weight bold

        td
            padding 4px

    .row
        display flex

    .row span:first-child
        display block
        width 150px
</style>
