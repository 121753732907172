<template>
    <div class="user-panel">
        <div class="user-panel__data">
            <b>{{ nickname }}</b><br>
            <span>{{ ip }}</span>
        </div>
        <div class="user-panel__buttons">
            <button @click="() => {}">
                <img src="../assets/images/bell.png" alt="notifications">

                <div class="chip">1</div>
            </button>
            <button @click="$emit('settings-click')">
                <img src="../assets/images/config-btn.png" alt="config">
            </button>
            <button @click="$emit('logout-click')">
                <img src="../assets/images/logout-btn.png" alt="logout">
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: "current-user-panel",
    props: {
        nickname: String,
        ip: String,
    },
}
</script>

<style lang="stylus" scoped>
    .user-panel
        background-color #FFF888
        display flex
        max-width 280px
        width 100%
        &__data
            font-size 0.75em
            padding 2px
        &__buttons
            display flex
            justify-content center
            align-items center
            margin-left auto
        button
            width 32px
            height 32px
            border 0
            background-color transparent
            padding 0
            display block
            position relative

            img
                display block
                width 100%

            .chip
                width 16px
                height 16px
                border-radius 50%
                color #eee
                background #e03939
                font-size 12px
                position absolute
                top 0
                right 0
                display flex
                align-items center
                justify-content center
                font-weight bold
</style>
