import Vue from 'vue'
import Vuex from 'vuex'
import userData from './user_data'

Vue.use(Vuex)

console.log(userData)

export default new Vuex.Store({
    modules: {
        userData,
    }
})
