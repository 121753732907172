import {
  ERROR_CODES,
  LogicError,
} from "@/errors";


export const backendUrl = process.env.VUE_APP_BACKEND_URL || 'http://localhost:5000/admin_rpc';


function _camel(key) {
  const result = key.replace(/([A-Z])/g, " $1");
  return result.split(' ').join('_').toLowerCase();
}

function _snake(str) {
  return str.replace(
    /([-_][a-z])/g,
    (group) => group.toUpperCase()
      .replace('-', '')
      .replace('_', '')
  );
}


/**
 * Makes json http _request
 *
 * @returns {Promise<Response>} - http response
 */
export async function makeJsonRequest({url, method = 'GET', data = null,}) {
  try {
    let body = undefined;
    if (data) {
      body = JSON.stringify(data);
    }

    const headers = {'Content-Type': 'application/json'};

    const authToken = localStorage.getItem('auth_token');
    if (authToken && authToken !== 'null' && authToken !== 'undefined') {
      headers['Authorization'] = authToken;
    }

    const response = await fetch(backendUrl + url, {
      method,
      credentials: 'include',
      body, headers,
    })

    if (response.status !== 200) {
      throw new LogicError(ERROR_CODES.UNKNOWN_ERROR);
    }

    return response;
  } catch (err) {
    if (err.name === 'TypeError' && err.message === 'Failed to fetch') {
      throw new LogicError(ERROR_CODES.SERVER_CONNECTION);
    }

    throw err;
  }
}

const pluginFactory = function () {
  return makeJsonRequest({url: '/methods/'})
    .then(response => response.json())
    .then(services_data => {
      const services = {};
      for (const service_name in services_data) {
        const service_methods = services_data[service_name];
        const service = {};
        for (let method_name in service_methods) {
          service[_snake(method_name)] = async function () {
            const response = await makeJsonRequest({
              url: '/call_method/',
              method: 'POST',
              data: {
                service_name,
                method_name: _camel(method_name),
                arguments: Array.from(arguments),
              }
            });
            let response_data;
            if (response.headers.get('Content-Type') !== 'application/pdf') {
              response_data = await response.json();
              const response_status = response_data.status.toLowerCase();
              switch (response_status) {
                case 'ok':
                  return response_data.result;
                case 'logic_error':
                  throw new LogicError(
                    response_data.error_code,
                    response_data.data);
                case 'unknown_error':
                default:
                  throw new LogicError(ERROR_CODES.UNKNOWN_ERROR);
              }
            } else {
              response_data = await response.blob();
              return response_data;
            }
          }
        }
        services[service_name] = service;
      }
      return services;
    })
    .then(services => ({
      install(Vue) {
        Vue.prototype.$services = /** @type {Services} */ services;
      }
    }))
}


export default pluginFactory;
