<template>
    <div class="akv-products-numbers">

        <div style="margin-top: 16px">
            <div class="success-box"
                 v-if="successBox.show"
                 v-html="successBox.text">
            </div>
            <div class="error-box"
                 v-if="errorBox.show"
                 v-html="errorBox.text">
            </div>
        </div>

        <table>
            <tr>
                <td>Einzeiliges Europa-Kennzeichen</td>
                <td>
                    <input type="number" step=".01" v-model.number="products_numbers.LICENCE_PLATE" />
                </td>
            </tr>
            <tr>
                <td>WÜRTH-Kennzeichenhalter</td>
                <td>
                    <input type="number" step=".01" v-model.number="products_numbers.PLATE_HOLDER" />
                </td>
            </tr>
            <tr>
                <td>Versandkosten (Standard)</td>
                <td>
                    <input type="number" step=".01" v-model.number="products_numbers.STANDARD_DELIVERY" />
                </td>
            </tr>
            <tr>
                <td>Versandkosten (Express)</td>
                <td>
                    <input type="number" step=".01" v-model.number="products_numbers.EXPRESS_DELIVERY" />
                </td>
            </tr>
        </table>

        <div>
            <button @click="save">Save</button>
        </div>
    </div>
</template>

<script>

import auth_required from "@/mixins/auth_required";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import success_error_states from "@/mixins/success_error_states";
import {handle} from "@/decorators";

export default {
    name: "products_numbers",
    mixins: [auth_required, success_error_states],
    data: () => ({
        products_numbers: {},
    }),
    computed: {
    },
    methods: {
        @handle("Kontos are saved")
        async save() {
            await this.$services.akv.updateProductsNumbers(this.products_numbers)
        }
    },
    async mounted() {
        this.products_numbers = await this.$services.akv.getProductsNumbers();
    },
    components: { DatePicker },
}

</script>

<style lang="stylus" scoped>
    .akv-products-numbers
        padding 10px

        &__result
            margin-top 15px

    button
        height 34px
        width 60px
        font-weight bold
</style>
