<template>
    <div id="app">
        <router-view />
    </div>
</template>

<script>
    export default {
        data: () => ({
            centrifugo: null,
        }),
    }
</script>

<style lang="stylus">

*
    box-sizing border-box

body
    margin 0

#app
    font-family sans-serif

.a-active
    font-weight bold

a
    text-decoration none

.success-box
    padding 16px
    color darkgreen
    border 2px solid green
    margin-bottom 16px

.error-box
    padding 16px
    color darkred
    border 2px solid red
    margin-bottom 16px
</style>
