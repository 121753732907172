<template>
    <main-layout>
        <div class="akv">
            <div class="akv__menu">
                <router-link to="/akv/orders/" active-class="a-active">
                    Bestellungen
                </router-link>

                <router-link to="/akv/plates_count/" active-class="a-active">
                    Abr. DASS
                </router-link>

                <router-link to="/akv/templates/" active-class="a-active">
                    Templates
                </router-link>

                <router-link to="/akv/prices/" active-class="a-active">
                    Preise
                </router-link>

                <router-link to="/akv/products_numbers/" active-class="a-active">
                    Kontos
                </router-link>

                <router-link to="/akv/site_content/" active-class="a-active">
                    Inhalt der Website
                </router-link>

                <router-link to="/akv/csv/" active-class="a-active">
                    CSV
                </router-link>

            </div>
            <div class="akv__body">
                <router-view></router-view>
            </div>
        </div>
    </main-layout>
</template>

<script>
import MainLayout from '@/layouts/main-layout';

export default {
    name: "akv-layout",
    components: {MainLayout}
}
</script>

<style lang="stylus">
    .akv
        &__menu
            background-color #004494
            padding 3px 0 2px 0
            a
                color #fefefe
                margin 0 10px 0 5px
                font-size 1.1em
</style>
