<template>
    <div class="akv-plates-count">
        <div class="success-box"
             v-if="successBox.show"
             v-html="successBox.text">
        </div>
        <div class="error-box"
             v-if="errorBox.show"
             v-html="errorBox.text">
        </div>
        <date-picker v-model="month" type="month"
                     :disabled-date="beforeToday"
                     placeholder="Select month" />

        <a :href="`/admin_rpc/call_method/?service_name=akv&method_name=get_csv_for_month&month_date_string=${dateString}`">
            <button :disabled="!dateString">Download</button>
        </a>

        <p><b>Send by email:</b></p>

        <input type="email" placeholder="Target email" v-model="email">

        <button @click="sendCsvByEmail" :disabled="!email">Send CSV by email</button>
    </div>
</template>

<script>

import auth_required from "@/mixins/auth_required";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import {handle} from "@/decorators";
import success_error_states from "@/mixins/success_error_states";

export default {
    name: "csv",
    mixins: [auth_required, success_error_states],
    data: () => ({
        /** @type {Date} */ month: null,
        result: null,
        email: '',
    }),
    computed: {
        dateString() {
            if (!this.month) return;

            console.log(this.month);

            return `${this.month.getFullYear()}-${this.month.getMonth() + 1}-${this.month.getDate()}`;
        },
        monthName() {
            if (!this.month) return '';
            return {
                0: 'January',
                1: 'February',
                2: 'March',
                3: 'April',
                4: 'May',
                5: 'June',
                6: 'July',
                7: 'August',
                8: 'September',
                9: 'October',
                10: 'November',
                11: 'December'
            }[this.month.getMonth()]
        },
        year() {
            if (!this.month) return '';
            return this.month.getFullYear();
        }
    },
    methods: {
        beforeToday(date) {
            return date > new Date(new Date().setHours(0, 0, 0, 0));
        },
        @handle('CSV sent')
        async sendCsvByEmail() {
            const email = String(this.email);
            await this.$services.akv.sendCsvForMonthByEmail(this.dateString, email);
            this.email = '';
            return { email };
        },

    },
    components: { DatePicker },
}

</script>

<style lang="stylus" scoped>
    .akv-plates-count
        padding 10px

        &__result
            margin-top 15px

    input
        padding-left 10px
        height 34px
        font-weight bold

    button
        margin-left 10px
        height 34px
        font-weight bold
</style>
