<template>
    <div class="akv-order">

        <div style="margin-top: 16px">
            <div class="success-box"
                 v-if="successBox.show"
                 v-html="successBox.text">
            </div>
            <div class="error-box"
                 v-if="errorBox.show"
                 v-html="errorBox.text">
            </div>
            <div class="error-box"
                 v-if="leaved">
                You're leaved from this order because other user editing this order
            </div>
            <div class="error-box"
                 v-if="can_bump">
                This order is editing now by other user. Do you want to kick them?
                <button @click="kick">Kick other user</button>
            </div>

            <div class="akv-order__actions-panel">
                <button @click="enableEdit" v-if="!editing && !leaved && !can_bump">
                    Bearbeitungsmodus
                </button>

                <button @click="saveEdit" class="save-button" v-if="editing && !leaved && !can_bump">
                    Speichern
                </button>

                <button @click="cancelEdit" v-if="editing && !leaved && !can_bump">
                    Ansichtsmodus
                </button>

                <button @click="goToList">Bestellübersicht</button>
            </div>
        </div>

        <div class="akv-order__row">
            <div class="akv-order__col">
                <div class="akv-order__field">
                    <div class="akv-order__field-label">Bestellnummer (BID):</div>
                    <div class="akv-order__field-value"><b>{{ order.id }}</b></div>
                </div>

                <div class="akv-order__field">
                    <div class="akv-order__field-label">Datum:</div>
                    <div class="akv-order__field-value">{{ order.date_of_create }}</div>
                </div>

                <div class="akv-order__field">
                    <div class="akv-order__field-label">IP:</div>
                    <div class="akv-order__field-value">{{ order.ip }}</div>
                </div>

                <div class="akv-order__field">
                    <div class="akv-order__field-label">Verkauf durch:</div>
                    <div class="akv-order__field-value">{{ order.sale_throw }}</div>
                </div>

                <p><b>
                    Kontaktdaten
                </b></p>

                <div class="akv-order__field">
                    <div class="akv-order__field-label">E-Mail-Adresse:</div>
                    <div class="akv-order__field-value">
                        <a v-if="!editing" :href="'mailto:' + order.contact_email">
                            {{ order.contact_email }}
                        </a>
                        <input type="text" v-if="editing" v-model="editData.contact_email">
                    </div>
                </div>

                <div class="akv-order__field">
                    <div class="akv-order__field-label">Telefon:</div>
                    <div class="akv-order__field-value">
                        <template v-if="!editing">
                            {{ order.contact_phone }}
                        </template>
                        <input type="text" v-if="editing" v-model="editData.contact_phone">
                    </div>
                </div>

                <br>

                <div class="akv-order__field">
                    <div class="akv-order__field-label">Versandart:</div>
                    <div class="akv-order__field-value">
                        <span style="color: blue" v-if="deliveryType === 'Standard'">Standard</span>
                        <span style="color: red" v-else>Express</span>
                    </div>
                </div>
            </div>
            <div class="akv-order__col --products">
                <div :class="'akv-order__product ' + (product.cancelled ? '--cancelled' : '')"
                     v-for="(product, productKey) in order.products"
                     :key="`productRow${productKey}`">
                    <div class="akv-order__product-index">
                        {{ productKey }})
                    </div>

                    <div class="akv-order__product-name">
                        {{ product.name }}
                        <template v-if="product.number">
                            <br>
                            {{ product.number }}
                        </template>
                    </div>

                    <div class="akv-order__product-data"
                         v-if="product.name === 'Einzeiliges Europa-Kennzeichen'">
                        Breite: 520mm <br>
                        Schrift: mittel
                    </div>

                    <div class="akv-order__product-price">
                        {{ product.price }} EUR
                    </div>
                </div>

                <div class="akv-order__full-price">
                    <label>Gesamtpreis:</label>
                    <div>{{ order.summary_price_pretty }} EUR</div>
                </div>
            </div>
            <div class="akv-order__col">

                <div class="akv-order__middle-field">
                    <div class="akv-order__middle-field-label">Aktueller Status:</div>
                    <div class="akv-order__middle-field-value"
                         :style="`color: ${statusColor}; background: ${statusBg};`">
                        <span>
                            {{ order.status_text }}
                        </span>
                    </div>
                </div>

                <div class="akv-order__middle-field">
                    <div class="akv-order__middle-field-label">Reservierung:</div>
                    <div class="akv-order__middle-field-value">-</div>
                </div>

                <div class="akv-order__middle-field">
                    <div class="akv-order__middle-field-label">Versand durch:</div>
                    <div class="akv-order__middle-field-value">{{ order.status.delivery_method }}</div>
                </div>

                <div class="akv-order__middle-field">
                    <div class="akv-order__middle-field-label">Tracking-Nr.:</div>
                    <div class="akv-order__middle-field-value">
                        <a :href="trackingLinkWithMethod" target="_blank">{{ order.status.tracking_link }}</a>
                    </div>
                </div>

                <div class="akv-order__middle-field">
                    <div class="akv-order__middle-field-label">Payment ID:</div>
                    <div class="akv-order__middle-field-value">
                        <span v-if="order.payment">{{ order.payment.payer_id }}</span>
                    </div>
                </div>

                <div class="akv-order__middle-field">
                    <div class="akv-order__middle-field-label">Zahlungsmethode:</div>
                    <div class="akv-order__middle-field-value">
                        <img v-if="order.status.payment_method === 'sparkassen'"
                             src="../../assets/images/icon_mini_kreditkarte.png" />
                        <img v-if="order.status.payment_method === 'paypal'"
                             src="../../assets/images/icon_mini_paypal.png" />
                        <img v-if="order.status.payment_method === 'sofort'"
                             src="../../assets/images/icon_mini_sofortueberweisung.png" />

                        {{ paymentMethod }}
                    </div>
                </div>

                <div class="akv-order__middle-field">
                    <div class="akv-order__middle-field-label">Lieferung:</div>
                    <div class="akv-order__middle-field-value">Versand</div>
                </div>

                <div class="akv-order__middle-field">
                    <div class="akv-order__middle-field-label">Rechnung PDF:</div>
                    <div class="akv-order__middle-field-value">
                        <a target="_blank" :href="invoicePDFUrl">PDF anschauen</a>
                    </div>
                </div>

                <div class="akv-order__middle-field">
                    <div class="akv-order__middle-field-label">Rechnung DRUCK:</div>
                    <div class="akv-order__middle-field-value">
                        <a target="_blank" :href="invoicePDFUrl">Rechnung drucken</a>
                    </div>
                </div>

                <div class="akv-order__middle-field">
                    <div class="akv-order__middle-field-label">Gutschriften:</div>
                    <div class="akv-order__middle-field-value">
                        <table>
                            <tr v-for="(creditNote, index) in order.credit_notes" :key="`creditNote${index}`">
                                <td>
                                    <a :href="creditNotePDFUrl(creditNote.id)" target="_blank">AKV-GUT-{{ creditNote.id }}</a>
                                </td>
                                <td>{{ formatDate(creditNote.date_of_create) }}</td>
                                <td>{{ creditNote.actor_name }}</td>
                                <td>{{ creditNote.summary_price_pretty }} EUR</td>
                            </tr>
                        </table>
                        <template v-if="order.status.paid && notCancelledProducts.length">
                            <div class="akv-order__mini-field">
                                <div class="akv-order__mini-field-label">Stornieren?</div>
                                <div class="akv-order__mini-field-value">
                                    <input type="checkbox" v-model="creditNoteData.cancelOrder" checked>
                                    Bestellung stornieren? {{ creditNoteData.cancelOrder }}
                                </div>
                            </div>
                            <div class="akv-order__mini-field">
                                <div class="akv-order__mini-field-label">Gut.-Nr.:</div>
                                <div class="akv-order__mini-field-value">
                                    <input type="text" style="width: 64px">
                                    (vier Ziffern, leer=auto)
                                </div>
                            </div>
                            <div class="akv-order__mini-field">
                                <div class="akv-order__mini-field-label">Products:</div>
                            </div>
                            <div class="akv-order__mini-field" v-for="(product, productKey) in order.products"
                                 :key="`cancelProduct${productKey}`" v-if="!product.cancelled">
                                <label class="akv-order__mini-field-value">
                                    <input type="checkbox" v-model="creditNoteProducts" :value="product.id">
                                    {{ product.name }}
                                    <template v-if="product.number">{{ product.number }}</template>
                                    <b style="margin-left: 8px; font-size: 12px">{{ product.price }} €</b>
                                </label>
                            </div>
                            <div class="akv-order__mini-field">
                                <div class="akv-order__mini-field-label">Gesamtpreis:</div>
                                <div class="akv-order__mini-field-value">
                                    <b style="margin-left: 8px; font-size: 12px">{{ fullCreditNotePrice }} €</b>
                                </div>
                            </div>
                            <div class="akv-order__mini-field">
                                <div class="akv-order__mini-field-label">Notiz:</div>
                                <div class="akv-order__mini-field-value">
                                    <textarea placeholder="z.B. Grund der Stornierung"
                                              v-model="creditNoteData.note"></textarea>
                                </div>
                            </div>

                            <button @click="createCreditNote" v-if="!leaved && !can_bump">Gutschrift erstellen</button>
                        </template>
                    </div>
                </div>

                <div style="margin-top: 32px; white-space: pre-line;">
                    {{ order.additional_info }}
                </div>

            </div>
        </div>

        <div class="akv-order__row">
              <div class="akv-order__col">

              </div>

              <div class="akv-order__col">
                  <h4>Lieferadresse:</h4>

                  <template v-if="!editing">
                      {{ order.delivery_company_name }}
                  </template>
                  <label v-if="editing">
                      Firma:
                      <input type="text" v-model="editData.delivery_company_name">
                  </label>

                  <br>

                  <template v-if="!editing">
                      {{ order.delivery_name }}
                  </template>
                  <label v-if="editing">
                      Name:
                      <input type="text" v-model="editData.delivery_name">
                  </label>

                  <br>

                  <template v-if="!editing">
                      {{ order.delivery_street }}
                  </template>
                  <label v-if="editing">
                      Straße und Hausnummer:
                      <input type="text" v-model="editData.delivery_street">
                  </label>

                  <br>

                  <template v-if="!editing">
                      {{ order.delivery_postcode }}
                  </template>
                  <label v-if="editing">
                      PLZ:
                      <input type="text" v-model="editData.delivery_postcode">
                  </label>

                  <br>

                  <template v-if="!editing">
                      {{ order.delivery_state }}
                  </template>
                  <label v-if="editing">
                      Ort:
                      <input type="text" v-model="editData.delivery_state">
                  </label>

                  <br>
              </div>

              <div class="akv-order__col">
                  <h4>Rechnungsadresse:</h4>
                  <template v-if="order.payment_same_data_flag">
                      {{ order.delivery_company_name }} <br>
                      {{ order.delivery_name }} <br>
                      {{ order.delivery_street }} <br>
                      {{ order.delivery_postcode }} <br>
                      {{ order.delivery_state }} <br>
                  </template>
                  <template v-else>
                      {{ order.payment_company_name }} <br>
                      {{ order.payment_name }} <br>
                      {{ order.payment_street }} <br>
                      {{ order.payment_postcode }} <br>
                      {{ order.payment_state }} <br>
                  </template>
              </div>
          </div>

        <div class="akv-order__row" v-if="!editing" style="color: white; font-weight: bold; font-size: 1.1em">

            <div class="akv-order__col --blue">
                Bearbeitungsverlauf:
            </div>

            <div class="akv-order__col --blue">
                Kommunikation:
            </div>

            <div class="akv-order__col --blue">
                Status ändern:
            </div>
        </div>

        <div class="akv-order__row" v-if="!editing">
            <div class="akv-order__col">
                <table class="akv-order__logs" cellpadding="0" cellspacing="0">
                    <tbody>
                        <tr v-for="log in order.logs"
                            :key="log.datetime_of_create + 'log'">
                            <td>{{ log.datetime_of_create }}</td>
                            <td>{{ log.actor_name }}</td>
                            <td>{{ log.action_text }}</td>
                        </tr>
                    </tbody>
                </table>

                <div class="akv-order__notes">
                    <div class="akv-order__note"
                         v-for="note in order.notes"
                         :key="note.datetime_of_create + 'note'">
                        <div class="akv-order__note-title">
                            {{ note.datetime_of_create }}
                            <span style="float: right">{{ note.actor_name }}</span>
                        </div>

                        <div class="akv-order__note-text" v-html="note.text"></div>
                    </div>
                </div>
            </div>

            <div class="akv-order__col">
                <select @change="onSelectNoteText">
                    <option disabled selected>Select template</option>
                    <option v-for="note in notes" :value="note">{{ note }}</option>
                </select>
                <textarea style="width: 100%; display: block" rows="3" v-model="noteData.text"></textarea>
                <button @click="writeANote" v-if="!leaved && !can_bump">Notiz speichern</button>

                <div style="margin-top: 5px">
                    <button v-if="!leaved && !can_bump" @click="clearEmailFields">Standard</button>
                    <button v-if="!leaved && !can_bump" @click="selectPaymentTemplate">Zahlung <br> ausstehend</button>
                </div>

                <div class="akv-order__middle-field">
                    <div class="akv-order__middle-field-label">Template:</div>
                    <div class="akv-order__middle-field-value">
                        <select style="width: 192px" @change="selectTemplate">
                            <option value="" selected disabled></option>
                            <option v-for="template in templates"
                                    :key="template + 'option'"
                                    :value="template">
                              {{ template }}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="akv-order__middle-field">
                    <div class="akv-order__middle-field-label">E-Mail an:</div>
                    <div class="akv-order__middle-field-value">
                        <input style="width: 192px" type="text" v-model="emailData.email" />
                    </div>
                </div>

                <div class="akv-order__middle-field">
                    <div class="akv-order__middle-field-label">Betreff:</div>
                    <div class="akv-order__middle-field-value">
                        <input style="width: 192px" type="text" v-model="emailData.subject" />
                    </div>
                </div>

                <p class="little-grey-text">Sehr geehrter Kunde,</p>
                <textarea style="width: 100%" rows="20" v-model="emailData.text"></textarea>
                <p class="little-grey-text">
                    Mit freundlichen Grüßen <br>
                    Das Team von Autozeitkennzeichen-Versand.de
                </p>
                <button v-if="!leaved && !can_bump" style="color: #777" v-show="!emailData.active" @click="emailData.active = true;">
                    Senden aktivieren
                </button>
                <button v-if="!leaved && !can_bump" v-show="emailData.active" @click="sendEmail">
                    E-Mail senden
                </button>
            </div>

            <div class="akv-order__col">
                <template v-if="order.status.cancelled">
                    <button v-if="!leaved && !can_bump" @click="restoreOrder">Restorno</button>
                </template>
                <template v-else-if="!order.status.paid">
                    <button v-if="!leaved && !can_bump" @click="setOrderPaid">Als bezahlt markieren</button>
                    <button v-if="!leaved && !can_bump" @click="setOrderCancelled">Stornieren</button>
                </template>
                <template v-else-if="order.includes_plates && !order.status.data_checked">
                    <button v-if="!leaved && !can_bump" @click="setOrderDataChecked">Als geprägt markieren</button>
                    <button v-if="!leaved && !can_bump" @click="setOrderCancelled">Stornieren</button>
                </template>
                <template v-else-if="!order.status.sent">
                    <div v-if="!leaved && !can_bump" style="display: flex">
                        <div>
                            <input type="radio" v-model="deliveryMethod" name="delivery_type" value="UPS" checked>
                            UPS
                        </div>
                        <div>
                            <input type="radio" v-model="deliveryMethod" name="delivery_type" value="DHL">
                            DHL
                        </div>
                        <div>
                            <input type="radio" v-model="deliveryMethod" name="delivery_type" value="GO">
                            GO!
                        </div>
                    </div>
                    <div v-if="!leaved && !can_bump">
                        <input type="text" v-model="trackingLink">
                    </div>
                    <button v-if="!leaved && !can_bump" @click="setOrderSent">Versendet</button>
                </template>
                <template v-else>
                    Diese Bestellung muss von Ihnen nicht mehr bearbeitet werden.
                    I.d.R. bedeutet das, dass die Bestellung entweder fertig
                    bearbeitet wurde oder dem Versand zur Bearbeitung vorliegt.
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import auth_required from "@/mixins/auth_required";
import success_error_states from "@/mixins/success_error_states";
import {handle} from "@/decorators";
const Centrifuge = require("centrifuge");

const defaultEmailSubject = 'Bestellung Nr. A-{id}';
const paymentEmailSubject = 'Bestellung Nr. A-{id}, Zahlung ausstehend';
const paymentEmailTemplate = `Sie haben am {date_of_create} unter der Bestellnummer {id} bei uns Autokennzeichen bestellt und die Zahlweise Kreditkarte gewählt.
Leider ist der ausstehende Betrag noch nicht auf unserem Konto gutgeschrieben worden.
Bitte setzen Sie sich telefonisch unter der Nummer 02421 / 1219738 oder per E-Mail mit uns in Verbindung, damit wir klären können, ob Sie Ihre Autokennzeichen noch benötigen oder die Bestellung stornieren möchten. Vielen Dank!`

export default {
    name: "akv-order",
    mixins: [auth_required, success_error_states],
    data: () => ({
        order: null,
        templates: [],
        creditNoteProducts: [],
        notes: [
            'Kunde konnte telefonisch nicht erreicht werden.',
        ],
        trackingLink: '',
        deliveryMethod: 'DHL',
        emailData: {
            email: '',
            subject: '',
            text: '',
            active: false,
        },
        noteData: {
            text: '',
        },
        leaved: false,
        can_bump: false,
        editing: false,
        editData: {
            contact_email: '',
            contact_phone: '',

            delivery_company_name: '',
            delivery_name: '',
            delivery_postcode: '',
            delivery_state: '',
            delivery_street: '',
        },
        creditNoteData: {
            cancelOrder: true,
            productsAmount: 0,
            deliveryAmount: 0,
            note: '',
        },

        centrifugo: null,
        // Centrifugo subs
        kickSub: null,
    }),
    computed: {
        statusColor() {
            if (!this.order) return '#000';

            return {
                'Warte auf Zahlung': '#555',
                'Zahlung erhalten': 'green',
                'Schilder geprägt': 'green',
                'Versendet': 'white',
                'Storniert': 'red',
            }[this.order.status_text]
        },
        statusBg() {
            if (!this.order) return '#fff';

            return {
                'Warte auf Zahlung': '#fff',
                'Zahlung erhalten': 'white',
                'Schilder geprägt': '#dfdfdf',
                'Versendet': 'green',
                'Storniert': '#fff',
            }[this.order.status_text]
        },
        summaryPrice() {
            let summaryPrice = 0;
            for (const product of this.order.products) {
                console.log(product)
                summaryPrice += product.price;
            }
            return summaryPrice;
        },
        deliveryType() {
            if (this.order.express_delivery) return 'Express';
            return 'Standard';
        },
        trackingLinkWithMethod() {
            let baseUrl;
            switch (this.order.status.delivery_method) {
                case 'DHL':
                    baseUrl = 'http://nolp.dhl.de/nextt-online-public/set_identcodes.do?idc=';

                    break;
                case 'UPS':
                    baseUrl = 'https://wwwapps.ups.com/WebTracking/' +
                              'processRequest?HTMLVersion=5.0&Requester=NES&' +
                              'AgreeToTermsAndConditions=yes&loc=de_DE&tracknum=';
                    break;
                case 'GO':
                    baseUrl = 'http://tracking.general-overnight.de/go_tracking_show.php?frachtbriefnummern=';
                    break;
                default:
                    return '';
            }
            return baseUrl + this.order.status.tracking_link;
        },
        invoicePDFUrl() {
            return `/admin_rpc/call_method/?service_name=akv&method_name=get_order_invoice&order_id=${this.order.id}`;
        },
        paymentMethod() {
            return {
                sparkassen: "Kreditkarte",
                paypal: "PayPal",
                sofort: "Sofortüberweisung",
                ueberweisung: "Ueberweisung",
            }[this.order.status.payment_method];
        },
        creditNoteProductsData() {
            const result = [];
            for (const productId of this.creditNoteProducts) {
                let creditNoteProduct;
                for (const product of this.order.products)
                    if (product.id === productId)
                        creditNoteProduct = product;
                if (creditNoteProduct) result.push(creditNoteProduct);
            }
            return result;
        },
        notCancelledProducts() {
            const result = [];
            for (const product of this.order.products) {
                if (!product.cancelled) result.push(product);
            }
            return result;
        },
        fullCreditNotePrice() {
            const products = this.creditNoteProductsData;
            console.log(products);
            let price = 0;
            for (const product of products) price += product.price;
            return price;
        },
    },
    methods: {
        @handle("Order restored")
        async restoreOrder() {
            const {status, status_text} = await this.$services.akv.restoreOrder(this.order.id);
            this.order.status = status;
            this.order.status_text = status_text;
        },

        @handle("Order cancelled")
        async setOrderCancelled() {
            const {status, status_text} = await this.$services.akv.setOrderCancelled(this.order.id);
            this.order.status = status;
            this.order.status_text = status_text;
        },

        @handle("Order paid")
        async setOrderPaid() {
            const {status, status_text} = await this.$services.akv.setOrderPaid(this.order.id);
            this.order.status = status;
            this.order.status_text = status_text;
        },

        @handle("Order data checked")
        async setOrderDataChecked() {
            const {status, status_text} = await this.$services.akv.setOrderDataChecked(this.order.id);
            this.order.status = status;
            this.order.status_text = status_text;
        },

        @handle("Order sent")
        async setOrderSent() {
            const {status, status_text} = await this.$services.akv.setOrderSent(
                this.order.id, this.trackingLink, this.deliveryMethod);
            this.order.status = status;
            this.order.status_text = status_text;
        },

        async selectTemplate(event) {
            const templateName = event.target.value;
            this.emailData.text = await this.$services.akv.getEmailTemplate(templateName);
            this.emailData.subject = defaultEmailSubject.format(this.order);
        },

        @handle("Email sent")
        async sendEmail() {
            const note = await this.$services.akv.sendEmail(
                this.order.id, this.emailData.email, this.emailData.subject,
                this.emailData.text);

            this.order.notes.push(note);

            this.clearEmailFields();
        },

        clearEmailFields() {
            this.emailData.subject = defaultEmailSubject.format(this.order);
            this.emailData.text = '';
            this.emailData.active = false;
        },

        selectPaymentTemplate() {
            this.emailData.subject = paymentEmailSubject.format(this.order);
            this.emailData.text = paymentEmailTemplate.format(this.order);
        },

        async onSelectNoteText(event) {
            this.noteData.text = event.target.value;
        },

        @handle("Note created")
        async writeANote() {
            const note = await this.$services.akv.writeANote(this.order.id, this.noteData.text);

            this.order.notes.push(note);

            this.noteData.text = '';
        },

        enableEdit() {
            this.editData.contact_email = this.order.contact_email;
            this.editData.contact_phone = this.order.contact_phone;

            this.editData.delivery_company_name = this.order.delivery_company_name;
            this.editData.delivery_name = this.order.delivery_name;
            this.editData.delivery_postcode = this.order.delivery_postcode;
            this.editData.delivery_state = this.order.delivery_state;
            this.editData.delivery_street = this.order.delivery_street;

            this.editing = true;
        },

        @handle('Order edittor successfully kicked')
        async kick() {
            await this.$services.admin.kick(this.order.id);
            this.leaved = false;
            this.can_bump = false;
        },

        @handle("Order data successfully changed")
        async saveEdit() {
            await this.$services.akv.updateOrder(this.order.id, this.editData);

            this.order.contact_email = this.editData.contact_email;
            this.order.contact_phone = this.editData.contact_phone;

            this.order.delivery_company_name = this.editData.delivery_company_name;
            this.order.delivery_name = this.editData.delivery_name;
            this.order.delivery_postcode = this.editData.delivery_postcode;
            this.order.delivery_state = this.editData.delivery_state;
            this.order.delivery_street = this.editData.delivery_street;

            this.emailData.email = this.order.contact_email;

            this.editing = false;
        },

        cancelEdit() {
            this.editing = false;
        },

        goToList() {
            this.$router.push({ name: 'orders' });
        },

        formatDate(/** @type {string} */ dateString) {
            const date = new Date(dateString);
            const year = date.getFullYear();
            let month = date.getMonth() + 1;
            if (month < 10) month = '0' + month;
            const day = date.getDate();

            return `${year}-${month}-${day}`;
        },

        @handle('Credit note was created')
        async createCreditNote() {
            const { credit_note, note, status } = await this.$services.akv.createCreditNote(
                this.order.id,
                this.creditNoteData.cancelOrder,
                this.creditNoteProducts,
                this.creditNoteData.note,
            );
            this.order.credit_notes.push(credit_note);
            this.order.notes.push(note);
            this.order.status = status;

            this.creditNoteData.productsAmount = 0;
            this.creditNoteData.deliveryAmount = 0;
            this.creditNoteData.note = '';

            for (const productId of this.creditNoteProducts) {
                let product;
                for (product of this.order.products)
                    if (product.id === productId) {
                        product.cancelled = true;
                        break;
                    }
            }

            this.creditNoteProducts = [];
        },
        creditNotePDFUrl(creditNoteId) {
            return `/admin_rpc/call_method/?service_name=akv&method_name=get_order_credit_note&order_id=${this.order.id}&credit_note_id=${creditNoteId}`;
        },
    },
    async mounted() {
        const orderId = Number(this.$route.params.id);
        this.order = await this.$services.akv.getOrderById(orderId);
        this.order.

        this.emailData.email = this.order.contact_email;
        this.emailData.subject = defaultEmailSubject.format(this.order);

        this.templates = await this.$services.akv.getEmailTemplates();


        const openResult = await this.$services.admin.startToEditOrder(orderId);

        if (!openResult.success) {
            this.can_bump = true;
        }
    },
    async created() {
        const orderId = Number(this.$route.params.id);

        this.centrifuge = new Centrifuge('wss://neueadmin.gva24.de/msgs/connection/websocket');
        this.centrifuge.on('disconnect', function(ctx){
            console.log('Centrifugo disconnected: ' + ctx.reason);
        });

        this.kickSub = this.centrifuge.subscribe(`akv_order_user_kick__order=${orderId}`, (message) => {
            const data = message.data;
            const { order_id, user_leave } = data;
            const username = this.$store.state.userData.username;
            if (order_id === orderId && username === user_leave) {
                this.leaved = true;
                this.can_bump = true;
            }
        });

        this.centrifuge.connect();
    },
    destroyed() {
        this.kickSub.unsubscribe();
        this.centrifuge.disconnect();
    },
}
</script>

<style lang="stylus" scoped>
.akv-order
    padding 0 10px

    &__actions-panel
        background-color #afafaf
        display flex
        margin-top 10px
        padding 4px 15px
        justify-content flex-end
        margin-bottom 8px

    &__row
        display flex

    &__col
        max-width 33.3333%
        width 100%
        padding 3px

        &.--products
            background-color #ebebeb

        &.--blue
            background-color #004494

        select, textarea
            display block
            width 100%

    &__logs
        width 100%
        font-size 0.75em

        tr:nth-child(odd)
            background #f9f9f9

        tr:nth-child(even)
            background #ebebeb

    &__field
        display flex

        &-label
            width 180px

    &__middle-field
        display flex
        align-items center

        &-label
            min-width 144px

    &__mini-field
        display flex
        align-items center
        font-size 0.75em

        &-label
            min-width 80px

        &-value
            display flex
            align-items center

    &__product
        $border = 1px solid #555
        border-top $border
        border-bottom $border
        padding 5px
        display flex
        align-items center

        &.--cancelled
            text-decoration line-through

        & + &
          margin 10px 0

    &__middle-field-value
        table
            font-size 0.7em

    &__product-index
        color #555
        font-size 0.75em

    &__product-name
        color #111
        margin-left 10px

    &__product-data
        color #111
        font-size 0.8em
        margin-left auto

    &__product-price
        margin-left auto

    &__full-price
        display flex
        font-weight bold
        justify-content space-between
        padding-left 25px
        padding-right 5px

    &__notes
        margin-top 16px

    &__note-title
        background #aaa
        font-size 0.75em
        padding 3px 5px
        font-weight bold

    &__note-text
        background #FFFCAB
        padding 3px 5px
        white-space pre-line


.little-grey-text
    color #777
    margin 0
    font-size 0.8em

.save-button
    font-weight bold
    color green

button + button
    margin-left 5px

label
    display block
    input
        float right

</style>
