<template>
    <div class="kkv-prices">

        <div style="margin-top: 16px">
            <div class="success-box"
                 v-if="successBox.show"
                 v-html="successBox.text">
            </div>
            <div class="error-box"
                 v-if="errorBox.show"
                 v-html="errorBox.text">
            </div>
        </div>

        <table v-if="prices">
            <tr>
                <td>kurzzeitkennzeichen-versand.de</td>
                <td>
                    <input type="number" step=".01" v-model.number="prices.COMMON_PRICE" />
                    <button @click="save('COMMON_PRICE', prices.COMMON_PRICE)">Save</button>
                </td>
            </tr>
            <tr>
                <td>Additional price</td>
                <td>
                    <input type="number" step=".01" v-model.number="prices.ADDITIONAL" />
                    <button @click="save('ADDITIONAL', prices.ADDITIONAL)">Save</button>
                </td>
            </tr>
        </table>

        <div>
        </div>
    </div>
</template>

<script>

import auth_required from "@/mixins/auth_required";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import success_error_states from "@/mixins/success_error_states";
import {handle} from "@/decorators";

export default {
    name: "prices",
    mixins: [auth_required, success_error_states],
    data: () => ({
        prices: null,
    }),
    computed: {
    },
    methods: {
        @handle("KKV prices are saved")
        async save(key, value) {
            await this.$services.kkv.setPrice(key, value);
        }
    },
    async mounted() {
        this.prices = await this.$services.kkv.getSitePrices();

    },
    components: { DatePicker },
}

</script>

<style lang="stylus" scoped>
    .kkv-prices
        padding 10px

        &__result
            margin-top 15px

    button
        height 34px
        width 60px
        font-weight bold
</style>
