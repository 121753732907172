<template>
    <div class="users-page">
        <div class="success-box"
             v-if="successBox.show"
             v-html="successBox.text">
        </div>
        <div class="error-box"
             v-if="errorBox.show"
             v-html="errorBox.text">
        </div>
        <table cellpadding="0" cellspacing="0">
            <thead>
                <tr>
                    <th>Benutzername</th>
                    <th>Voller Name</th>
                    <th>Status</th>
                    <th>Neues Passwort</th>
                    <th>Aktion</th>
                </tr>
            </thead>
            <tbody>
                <user-data-row
                    v-for="(user, userKey) in users"
                    :key="`user${userKey}`"

                    :account-name="user.username"
                    :full-name="user.full_name"
                    :status="user.status"

                    @new-password="resetUserPassword"
                    @activate="activateUser"
                    @deactivate="deactivateUser"
                    @remove="deleteUser" />

                <user-data-add-line
                    @create-user="createUser" />
            </tbody>
        </table>
    </div>
</template>

<script>
import UserDataRow from '@/components/users/user-data-row'
import UserDataAddLine from '@/components/users/user-data-add-line'
import auth_required from "@/mixins/auth_required";
import success_error_states from "@/mixins/success_error_states";
import {handle} from "@/decorators";


export default {
    name: "users",
    mixins: [auth_required, success_error_states],
    data: () => ({
        users: [],
    }),
    async created() {
        this.users = await this.$services.admin.getUsers();
    },
    methods: {
        @handle('Benutzer <b>{username}</b> erfolgreich erstellt')
        async createUser({ username, full_name, password }) {
            const user = await this.$services.admin.createUser(username, full_name, password);
            this.users = [...this.users, user];
            return { username };
        },
        @handle('Benutzername <b>{username}</b> Passwort wurde erfolgreich geändert')
        async resetUserPassword({ username, new_password }) {
            await this.$services.admin.resetUserPassword(username, new_password);
            return { username };
        },
        @handle('Benutzer <b>{username}</b> erfolgreich aktiviert')
        async activateUser({ username }) {
            await this.$services.admin.activateUser(username);
            return { username };
        },
        @handle('Benutzer <b>{username}</b> wurde erfolgreich deaktiviert')
        async deactivateUser({ username }) {
            await this.$services.admin.deativateUser(username);
            return { username };
        },
        @handle('Benutzer <b>{username}</b> wurde erfolgreich gelöscht')
        async deleteUser({ username }) {
            await this.$services.admin.deleteUser(username);
            this.users.splice(
                this.users.findIndex(obj => obj.username === username),
              1
            );
            return { username };
        },
    },
    components: { UserDataRow, UserDataAddLine },
}
</script>

<style lang="stylus" scoped>
    .users-page
        padding 8px
    table
        width 100%
        th
            text-align left
</style>
