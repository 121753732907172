<template>
    <div class="modules-page">
        <table cellpadding="0" cellspacing="0">
            <thead>
                <tr>
                    <th>Shortname</th>
                    <th>Longname</th>
                    <th>bgcolor</th>
                    <th>color</th>
                    <th>Sortierung</th>
                    <th>Aktion</th>
                </tr>
            </thead>
            <tbody>
                <module-data-row
                    v-for="(module, moduleKey) in modules"
                    :key="`module${moduleKey}`"
                    :short_name="moduleKey"
                    :long_name="module.long_name"
                    :color="module.color"
                    :bg_color="module.bg_color" />
            </tbody>
        </table>
    </div>
</template>

<script>
import auth_required from "@/mixins/auth_required";
import ModuleDataRow from "@/components/modules/module-data-row";

export default {
    name: "modules",
    mixins: [auth_required],
    data: () => ({
        modules: null,
    }),
    async created() {
        this.modules = await this.$services.admin.getModules();
        console.log(this.modules);
    },
    components: {
        ModuleDataRow,
    },
}
</script>

<style lang="stylus" scoped>
    .modules-page
        padding 16px

        table
            width 100%
        th
            text-align left
</style>
