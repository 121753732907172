export default {
    created: async function () {
        const auth_token = localStorage.getItem('auth_token');
        if (!auth_token) return;

        const user = await this.$services.admin.authenticate(auth_token);

        this.$store.commit('userData/SET_USER_DATA', user);

        await this.$router.push({name: 'home'})
    },
}
